import ShareService from '../ShareService'
import React, { Component } from 'react'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import './Camera.css'

var debug = true;

class CameraPage extends Component
{
	clickedTakePhoto (e) {
		console.log("clickedTakePhoto()");
		this.takepicture();
		//e.preventDefault();
	}

	render() { return (
		<div id="camera" className="app-page" >
			<div>
				<div>
					<div>
						<video
							id="video"
							className="picture-preview"
						>Camera stream not available.</video>
						<div style={{display:'grid'}} >
							<button
								id="startbutton"
								className="button long"
								onClick={(e) => this.clickedTakePhoto(e)}
							>
								Take photo
							</button>
						</div>
					</div>

					<Spacer height={5000} />

					<canvas id="canvas"></canvas>

					<div className="output">
						<img
							id="photo"
							className="photo-preview"
						/>
					</div>

				</div>
			</div>
		</div>
	)}

	componentDidMount() {
		if (debug) console.log("Camera Page mount");

		app.showLoadingSpinner();

		this.picWidth = app.getScreenWidth();
		// Height will be computed based on the input stream

		this.video = util.$('video');
		this.canvas = util.$('canvas');
		this.photo = util.$('photo');
		this.startbutton = util.$('startbutton');

		const video = this.video;
		// const that = this;

		navigator.mediaDevices.getUserMedia({
			video: true,
			audio: false
		})
		.then((stream) => {
			this.stream = stream;
			video.srcObject = stream;
			video.play();
		})
		.catch((err) => {
				console.log("An error occurred: " + err);
		});

		video.addEventListener('canplay', this._videoListener, false);

		this.clearphoto();
	}

	_videoListener = () => {
		if (!this.streaming) {
			const v = this.video;
			this.picHeight = ( v.videoHeight / (v.videoWidth / this.picWidth) )
			this.streaming = true;
		}
		app.hideLoadingSpinner();
	};

	takepicture () {
		console.log("takepicture() " + this.picWidth );
		var context = this.canvas.getContext('2d');
		if (this.picWidth && this.picHeight) {
			// take the picture
			this.canvas.width = this.picWidth;
			this.canvas.height = this.picHeight;
			context.drawImage(this.video, 0, 0, this.picWidth, this.picHeight);

			var data = this.canvas.toDataURL('image/png');
			this.photo.setAttribute('src', data);

			this.video.pause();
			var track = this.stream.getTracks()[0];  // if only one media track
			track.stop();

			console.warn("fix this wait")
			uu.wait(333)
			.then( () => {
				console.log("waited");
				//console.dir(data)
				cameraPage.onPhoto( data, this.photo );
			})
			// load the next page
			//if (debug) console.log(" do callback now");
			
		} else {
			this.clearphoto();
		}
	}

	clearphoto () {
		console.log("clearphoto()");
		var context = this.canvas.getContext('2d');
		context.fillStyle = "#000000";
		context.fillRect(0, 0, this.canvas.width, this.canvas.height);
		var data = this.canvas.toDataURL('image/png');
		this.photo.setAttribute('src', data);
	}
}

var cameraPage = new AppPage({
	name: "camera",
	component: (<CameraPage />)
});

cameraPage.initialize = function(data) {
	if (debug) console.log("cameraPage initialize( {...} )");
	if (debug) console.dir( data );
	this.callBackFunction = function(){};
	if (data && data.callBack) {
		this.callBackFunction = data.callBack;
	}
	return Promise.resolve();
}

cameraPage.onPhoto = function(src, element) {
	console.log("onPhoto");
	var data = {src, element}
	if (this.callBackFunction) {
		console.log("do callback");
		this.callBackFunction( data );
	} else {
		console.error("no callback function after photo")
	}
}

cameraPage.cleanUp = function() {
	console.log("cleanUp");
	const video = util.$('video');
	if (!video) return;
	video.removeEventListener('canplay', this._videoListener, false);
}

export default cameraPage;



