import ProfileService from './ProfileService'
import config from './AppConfig'
import Service from './Service'
import Profile from './Profile'
import util from './AppUtil'

var debug = true;

export default class PictureService
{
	// get a picture from the picture service
	static imgUrl (s) {
		if (debug) console.log("imgUrl( "+s+" )");
		if (!s) s = PictureService.defaultImage;
		if (s.indexOf('/') === 0) s = s.substring(1);
		if (debug) console.log(s);
		return config.service.picture.host + s;
	}
	static img(s) {
		return PictureService.imgUrl(s);
	}
	static url(s) {
		return PictureService.imgUrl(s);
	}

	// adds 'url()' for css values
	static imgUrlCss (s) {
		var r = 'url('+config.service.picture.host;
		if (s) { return (r + s) }
		else { return (r + '/img/missing.svg)') }
	}
	static imgCss (s) {
		return PictureService.imgUrlCss(s);
	}
	static css (s) {
		return PictureService.imgUrlCss(s);
	}

	static get defaultImage() { return "/img/ss/default0.png" }
	static get nobody() { return "/img/pp/nobody.svg" }
	static get nobodyCss() { return "url(/img/pp/nobody.svg)" }

	static saveShareImage (share) {
		if (debug) console.log("saveShareImage( {...} )");
		if (debug) console.dir(share);
		if (share.mediaFile) {
			return PictureService.saveImage(
				'picture/save/share/',
				share.mediaFile
			)
		} else if (share.imageElement) {
			return PictureService.makeBlob( share.imageElement )
			.then( (blob) => {
				return PictureService.saveImage(
					'picture/save/share/',
					blob
				)
			});
		}
		console.error(" no image to save in share")
	}

	static savePortrait (image) {
		if (debug) console.log("savePortrait( {} )");
		return PictureService.makeBlob( image )
		.then( (blob) => {
			console.log(blob);
			return PictureService.saveImage(
				'picture/save/portrait/' + ProfileService.me.pid,
				blob
			)
		});
	}

	static makeBlob( image ) {
		if (debug) console.log("makeBlob( {...} )");
		if (debug) console.dir( image );
		if (image instanceof Blob) {
			if (debug) console.log("it's already a a blob");
			return Promise.resolve(image);
		}
		return createImageBitmap( image )
		.then( (img) => {
			if (debug) console.log("createImageBitmap result:");
			if (debug) console.dir(img);
			return new Promise( (res) => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				let ctx = canvas.getContext('bitmaprenderer');
				if (ctx) {
					ctx.transferFromImageBitmap(img)
				} else {
					canvas.getContext('2d').drawImage(img,0,0)
				}
				return canvas.toBlob(res);
			});
		})
	}

	static saveImage (path, blob) {
		if (debug) console.log("saveImage( ) ");
		//if (debug) console.dir(blob);
		// TODO: get an upload auth token
		var formData = new FormData();
		formData.append('file', blob);
		formData.pid = ProfileService.me.pid;

		const furl = config.service.picture.host + path;
		return fetch( furl, {
			method: 'post',
			body: formData,
		})
		.then( (res) => {
			if (res.status === 500) {
				console.log("got a 500. " + res.statusText);
				//return Promise.resolve({error: res.statusText})
				throw new Error(res.statusText)
			} else {
				try {
					return res.json();
				} catch (e) {
					console.error("res not json");
					throw new Error("Problem saving image");
				}
			}
		})
	}
}

