import { GoogleLogin } from 'react-google-login'
import AwesomeButton from './AwesomeButton'
import FormCheckBox from './FormCheckBox'
import AwesomeInput from './AwesomeInput'
import UserService from '../UserService'
import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import language from '../Language'
import { Spacer } from './Common'
import config from '../AppConfig'
import AppPage from '../AppPage'
import util from '../AppUtil'
import Agree from './Agree'
import User from '../User'
import app from '../app'
import uu from '../uu'
import './JoinPassword.css'

const googleClientId = '961308379775-aq9d75g572039vtbq3ovvlbojsum4hg1.apps.googleusercontent.com';

var debug = true;

class JoinGoogleScreen extends Component
{
	state = {
		loggedIn: false,
		email: '',
		tokenId: '',
		errorMessage: ''
	}

	clickSubmit = () => {
		console.log('clickSubmit()');
		this.showError(null);
		const { loggedIn, email, tokenId } = this.state;

		console.log("loggedIn? "+ loggedIn);
	
		if (!loggedIn) {
			return this.showError("We really need you to press that google button up there.")
		};
		if (!email) {
			return this.showError("no email address provided by google")
		}
		if (!this.checkAgree()) return false;

		const data = {
			username: email,
			email: email,
			password: tokenId,
			remember: true,
			type: 'goog'
		}
		
		this.create(data);

		return true;
	}

	create (data) {
		UserService.create(data).then( (res) => {
			this.createSuccess(res);
		}).catch( (err) => {
			this.createFail(err);
		})
	}

	onLoginSuccess = (res) => {
		console.log("login success. res: ", res);
		const { profileObj, accessToken, tokenId } = res;
		const { familyName, givenName, email, googleId } = profileObj;
		this.profileObj = profileObj;
		this.setState({ loggedIn: true, email, accessToken, givenName, familyName, tokenId, googleId });
		this.showError(null);
	}

	onLoginFailure = (res) => {
		console.warn("login fail");
		console.dir(res);
		this.showError(res.error);
	};

	createSuccess (res) {
		console.log("createSuccess()");
		uu.wait(20).then(() => app.goPage('googleImport', this.profileObj));
	}

	createFail (error) {
		console.warn("createFail( {...} )");
		console.dir(error);
		util.dispatch('awesomeButtonReset');
		this.showError(language.join.googleFail);
	}

	checkAgree() {
		const input = util.$("agree-input");
		if (!input) return;
		if (input.checked) {
			util.rmClass(util.$('agree-container'), 'error');
			return true;
		}
		this.showError(language.login.youMustAgree, util.$('agree-container') );
	}

	showError (errorMessage) {
		this.setState({ errorMessage });
		if (errorMessage) {
			util.addClass( this.goButton, 'disabled' );
		}
	}

	render () {
		const { loggedIn, email, errorMessage } = this.state;
		const hello = email ? `Hello, ${email}!` : '';

		//console.log("render!  loggedIn: "+loggedIn);

		const username = thisPage.prePopName ? thisPage.prePopName : "";
		thisPage.prePopName = "";
		return (
			<div id={thisPage.name} className="app-page">
				<div className='page-banner join-google' />
				<div id="page-title">To Be or Not to Be Evil?</div>
				<div className="content-container" >
					<div className='form-container'>

						<div className='googleRow'>
							<FormCheckBox
								name='signIn'
								checked={ loggedIn }
								notInteractive={ true }
							/>

							<GoogleLogin
								clientId={ googleClientId }
								butonText="Login"
								onSuccess={ this.onLoginSuccess }
								onFailure={ this.onLoginFailure }
								cookiePolicy={ 'single_host_origin' }
								isSgnedIn={ loggedIn }
							>
							</GoogleLogin>
						</div>

						<div
							id='emailWelcome'
							className='info-display'
						>{ hello }</div>

						<FormCheckBox
							name='remember'
							text={ language.login.rememberMe }
							checked={true}
						/>

						<Agree />

						<div
							id='errorMessage'
							className='error-string'
						>{ errorMessage }</div>

						<AwesomeButton
							name='join'
							text='Join!'
							clickAction={ this.clickSubmit }
						/>

					</div>
				</div>
				<Spacer height={42} />
			</div>
		);
	}
}

var thisPage = new AppPage({
	name: "joinGoogle",
	component: (<JoinGoogleScreen />)
});

thisPage.initialize = function(data) {
	//this.myComp = null;
	return Promise.resolve();
}


export default thisPage;

