import ProfileService from '../ProfileService'
import React, { Component } from 'react'
import Profile from '../Profile'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import './Common.css'

var debug = true;

export class Spacer extends Component {

	render() {
		this.height = 16;
		if (this.props) {
			if (this.props.height) {
				this.height = this.props.height
			} else if (this.props.lines) {
				this.height = this.props.lines * 16;
			}
		}
		const style = {
			width: '100%',
			height: this.height+'px'
		}
		return (<div style={style}></div>);
	} 
}

// test: window.dispatchEvent(new CustomEvent('showProgressIndicator'))
// test: window.dispatchEvent(new CustomEvent('hideProgressIndicator'))
export class ProgressIndicator extends Component {

	render() { return(<div />) }

	componentDidMount() {
		// subscribe to event to animate.
		window.addEventListener('showProgressIndicator', (e) => {
			if (debug) console.log('showProgressIndicator event captured');
			const elm = util.$('progress-indicator');
			elm.style.opacity = 0.66;
			elm.style.zIndex = 10;
		});
		window.addEventListener('hideProgressIndicator', (e) => {
			if (debug) console.log('hideProgressIndicator event captured');
			const elm = util.$('progress-indicator');
			if (elm) {
				elm.style.opacity = 0;
				elm.style.zIndex = -10;
			}
		});
	}
}

// export class ProgressIndicatorButton extends ProgressIndicator
// {
// 	render() {
// 		return (
// 			<div id="progress-indicator" className="progress-indicator-button">
// 				<div id="progress-indicator-bouncer" >
// 				</div>
// 			</div>
// 		);
// 	}
// }

export class ProgressIndicatorCircle extends ProgressIndicator {
	render() {
		return (<div
			id='progress-indicator'
			className='spinner'
		></div>)
	}
}


export class PageFooter extends Component {
	render() {return (
		<div id="page-footer" >
			<div className="copyright" >
				&copy; 2021, MyGonzo Media&trade;
			</div>
			<Spacer height={84} />
		</div>
	)}
}

export class ShareDetails extends Component {
	render() {
		let details = this.props.share.age;
		let icnClass = 'background-icon detail-icon tint-main-color';
		if (this.props.share.group) {
			icnClass += ' icon-group'
		} else {
			icnClass += ' icon-earth'
		}
		return(
			<div className='share-details'>
				<div className={icnClass}></div>
				{details}
			</div>
		)
	}
}





