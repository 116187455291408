import ProfileService from './ProfileService';
import UserService from './UserService';
import LocalStore from './LocalStore';
import Service from './Service';
import Profile from './Profile';
import Message from './Message';
import config from './AppConfig';
import util from './AppUtil';
import User from './User';
import app from './app';
import uu from './uu';

//import { io } from 'socket.io-client';

import conversationPage from './components/Conversation'

var debug = false;


class ChatService {

	static sendMessage(message){
		if (debug) console.log('sendMessage( {...} )', message);
		if (!(message instanceof Message)) {
			return Promise.reject("sendMessage input not message");
		}
		message.wasSent();
		return Service.fpa( 'chat/send', message.postData() )
	}

	static getInbox() {
		if (!UserService.loginSuccess) return Promise.reject("not logged in");
		var data = { pid: ProfileService.me.pid };
		return Service.fpa( 'chat/inbox', data )
		.then( (inbox) => {
			if (debug) console.log('got inbox data:');
			if (debug) console.dir(inbox);
			return inbox;
		})
	}

	static getConversations() {
		if (debug) console.log('getConversations( )');
		return Service.fpa('chat/conversation', {
			me: ProfileService.me.pid
		})
		.then( (convos) => {
			if (debug) console.log("convos:");
			if (debug) console.dir(convos);
			return Promise.resolve(convos);
		})
	}

	static getConversationWith(pid) {
		if (debug) console.log("getConversationWith( "+pid+" )");
		return Service.fpa('chat/conversation', {
			me: ProfileService.me.pid,
			you: pid
		})
		.then( (convos) => {
			if (debug) console.log("convos:");
			if (debug) console.dir(convos);
			return Promise.resolve(convos);
		})
	}

	static getMessagesWith({pid, id}) {

		var useLocalData = false;

		if (!id) id = 0;
		if (debug) console.log('getMessagesWith('+pid+', '+id+')');
		var mids = LocalStore.getChatHistoryWith(pid);
		if (!useLocalData) mids = null;
		if (!mids) return ChatService.getServerMessagesWith({pid, id});
		console.log("found chat history locally:");
		console.dir(mids);
		var ra = [];
		mids.forEach( (mid) => {
			var msg = LocalStore.getMessage(mid);
			if (msg) {
				ra.push(msg);
			} else {
				console.error("missing messages in localstore!");
				return ChatService.getServerMessagesWith({pid, id}); 
			}
		})
		return Promise.resolve(ra)
	}

	static getServerMessagesWith({pid, id}) {
		console.log("getServerMessagesWith( {} )");
		return Service.fpa('chat/messages', {
			to: ProfileService.userPid,
			from: pid,
			id
		})
		.then( (messages) => {
			messages = uu.parseArray(messages);
			if (debug) console.log("messages from server:");
			if (debug) console.dir(messages);
			LocalStore.saveMessages(messages, pid);
			return Promise.resolve(messages);
		})
	}

	static readConversation( id, pid, msgId ) {
		if (debug) console.log('readConversation( '+id+', '+pid+', '+msgId+' )');
		return Service.fpa('chat/read', {
			me: ProfileService.userPid,
			you: pid,
			id,
			msgId
		})
		// .then( () => {
		// 	util.dispatch('markItRead');
		// })
	}

	static openSocket() {
		console.log('openSocket()');
		if (ChatService.socketConnected) {
			console.log("socket already connected");
			return;
		}
		ChatService.socketConnected = true;
		const data = {
			query: 'pid='+ProfileService.pid
		}
		data.rejectUnauthorized = false;
		const socket = io(config.service.chat.host, data);
		//socket.emit('listenToInbox', {pid: ProfileService.pid})
		socket.on('message', message => {
			console.log("SERVER MESSAGE: " +message)
		})
		socket.on('inboxUpdate', inbox => {
			//console.log("socket.on inboxUpdate. inbox:");console.dir(inbox)
			util.dispatch('inboxUpdate', inbox);
		})
		socket.on('convoUpdate', convo => {
			console.log("*** socket.on convoUpdate. convo:", convo);
			LocalStore.saveConvos([convo]);
			util.dispatch('convoUpdate', convo);

			const pageConvo = conversationPage.convo;
			if (pageConvo) {
				
				console.log("pageConvo.id: "+pageConvo.id);
				console.log("    convo.id: "+convo.id);
				if (pageConvo.id === convo.id) {
					return;// console.log("don't notifify, convo page is open");
				}
			}

			//todo: move this code
			ProfileService.getProfile({ pid: convo.you })
			.then((profile) => {
				//console.log("*** should I notify? convo:");console.dir(convo);
				// don't notify on empty (newly created) convos
				if (convo.newest) {
					app.notifyNewMessage(profile, convo);
				}
			})

		})
		socket.on('messageSaved', message => {
			//console.dir(convo)
			util.dispatch('messageSaved', message);
		})
	}
}

window.addEventListener('myProfileUpdate', ChatService.openSocket);


export default ChatService;