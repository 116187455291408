var language =
{
	join: {
		title: "Join",
		info: "It's easy",
		titlePassword: "New User",
		infoPassword: "the oldschool way",
		googleFail: "Authentication Error"
	},
	import: {
		name: "Import my Name",
		photo: "Import my Photo"
	},
	login: {
		loginButton: "Login",
		joinButton: "Register",
		forgot: "Forgot Password?",
		rememberMe: "Remember this device",
		failedLogin: "Invalid user or password.",
		importName: "Import My Name",
		youMustAgree: "You must agree to the terms of service and privacy policy.",
	},
	favorites: {
		title: "Favorites",
		info: "I like!",
	},
	error: {
		server: "Server Error",
	},
	email: {
		invalid: "Invalid Emaill Address",
	},
	errorDisplay: {
		title: "Uh oh.",
		info: "Something went wrong.",
	}
}

export default language;