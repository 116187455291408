import AwesomeInput from './AwesomeInput'
import UserService from '../UserService'
import React, { Component } from 'react'
import language from '../Language'
import { Spacer } from './Common'
import config from '../AppConfig'
import AppPage from '../AppPage'
import util from '../AppUtil'
import Agree from './Agree'
import User from '../User'
import app from '../app'
import uu from '../uu'

import './JoinEmail.css'


class JoinEmailScreen extends Component
{
	change(e) {
		console.log("change()");
		this.clearError();
	}

	clickSubmit() {
		console.log('clickSubmit()');
		this.goButton = util.$('buttonSubmit');

		if (!validateEmail()) return;
		if (!checkAgree()) return;

		const data = {
			email: this.inputs.email.value
		}

		LocalStore.setItem('username', this.inputs.email.value);
		
		UserService.create( data )
		.then((res) => {
			this.createSuccess(res);
		}).catch( (err) => {
			this.createFail(err);
		})
	}

	createSuccess(res) {
		console.log("createSuccess()");
		uu.wait(2000)
		.then( () => {
			app.goPage('welcome');
		})
	}

	createFail(res) {
		console.log("createFail( "+res+" )");
		if (typeof res == 'string') res = JSON.parse(res);
		console.log(res);
		if (res.error_code && res.error_code == "exists") {
			this.showError(
				"" + this.inputs.name.value + " is already in use.",
				[util.$('email-input-container')]
			);
		} else {
			this.showError(res.error);
		}
	}

	validateEmail() {
		const v = util.validateEmail( this.emailInput );
		if (v.error) {
			this.showError( v.error, [this.emailInputContainer] );
			return false;
		} else {
			console.log("email address validated");
			return true;
		}
	}

	checkAgree() {
		if (util.$("AgreeCheckBox").checked) return true;
		const s = 'You must agree to the terms of service and privacy policy.'
		this.showError( s, util.$('agree-check') );
	}

	showError(s, elements) {
		this.clearError();
		console.log("showError( "+s+" )");
		document.getElementById("errorMessage").innerHTML = s;
		if (elements){
			elements.forEach(function(e) {
				e.classList.add('error');
			});		
		}
		util.addClass( this.goButton, 'disabled' );
	}

	clearError() {
		var em = util.$("errorMessage");
		if (em) em.innerHTML = "\u00a0";
		for (var prop in this.inputs) {
			util.rmClass( this.inputs[prop], 'error' )
		}
		util.rmClass( this.goButton, 'disabled' )
	}

	render() {
		return (
			<div id={thisPage.name} className="app-page">
				<div className='page-banner join-email' />
				<div id="page-title">Email Verification</div>
				<div className="content-container" >
					<div className='form-container'>

						<AwesomeInput
							type='email'
							name='email'
							text='email address'
						/>

						<Agree />

						<div
							id="errorMessage"
							className="error-string"
						></div>

						<button
							onClick={(e) => this.clickSubmit(e)}
							type="button"
							className="button long"
							id="buttonSubmit"
						>
							Send Secret Code
						</button>

					</div>
				</div>

			</div>
		);
	}

	componentDidMount() {
		this.emailInput = util.$('email-input');
		this.emailInputContainer = util.$('email-input-container');
	}
}

var thisPage = new AppPage({
	name: "joinEmail",
	component: (<JoinEmailScreen />)
});

export default thisPage;

