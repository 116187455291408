import PictureService from './PictureService'
import ProfileService from './ProfileService'
import UserService from './UserService'
import LocalStore from './LocalStore'
import config from './AppConfig'
import Service from './Service'
import Profile from './Profile'
import util from './AppUtil'
import Share from './Share'
import User from './User'
import app from './app'

var debug = false;

class ShareService
{
	static getLatest({ limit, offset, author, group }) {
		if (debug) console.log("getLatest( limit:"+limit+", offset:"+offset
			+", author:"+author+", group:"+group+" )");
		if (debug) console.log(" from " + config.service.share.host);

		if (!offset) offset = 0;
		if (!limit) limit = 1;
		if (!author) author = 0;
		if (!group) group = 0;

		return Service.fg(config.service.share,
			('latest/'+limit+'/'+offset+'/'+author+'/'+group))
		.then( (res) => {
			if (debug) console.log("ShareService getLatest response:");
			if (debug) console.dir(res);
			return res.json()
			.then( (obj) => { return obj })
		})
		.catch( (e) => {
			console.error("Problem loading latest share data from server.");
			return Promise.reject(e);
		})
	}

	static getShares(shares) {
		if (debug)console.log("getShares( "+shares+" )");
		if (!Array.isArray(shares)) return Promise.reject('not array');

		return Service.fg(config.service.share,
			('shares/' + shares.toString() )
		)
		.then( (res) => {
			if (debug) console.log("ShareService shares response:");
			if (debug) console.dir(res);
			return res.json()
			.then( (obj) => {
				if (debug) console.dir(obj);
				return obj;
			})
		})
		.catch( (e) => {
			console.error("Problem loading shares from server.");
			return Promise.reject(e);
		})
	}

	static getShare(id) {
		return Service.fg(config.service.share,
			('share/' + id )
		)
		.then( (res) => {
			if (debug) console.log("ShareService get single share response:");
			if (debug) console.dir(res);
			return res.json()
			.then( (obj) => {
				if (debug) console.dir(obj);
				if (obj.error) {
					return Promise.reject(obj);
				} else {
					return Promise.resolve( new Share(obj) );
				}
			})
		})
		.catch( (e) => {
			console.error("Problem loading single share data from server.");
			console.dir(e);
			return Promise.reject(e);
		})
	}

	static getComments({ sid, limit, offset }) {
		if (debug) {
			console.log("getComments( "+sid+", "+limit+", "+offset+" )");
		}
		if (!offset) offset = 0;
		if (!limit) {
			console.warn("limit default value")
			limit = 100;
		}

		return Service.fetchGet(config.service.share,
			('comments/' + sid + '/' + limit + '/' + offset)
		)
		.then( (res) => {
			if (debug) console.log("ShareService comments response:");
			if (debug) console.dir(res);
			return res.json()
			.then( (obj) => {
				if (debug) console.log("here it is:");
				if (debug) console.dir(obj);
				return obj;
			})
		})
		.catch( (e) => {
			console.error("Problem loading comments from server.");
			app.showError(e);
		})
	}

	static new(share) {
		if (debug) console.log("Share new( {...} ) ");
		if (debug) console.dir(share);

		// Is there an image to upload?
		if (!share.stockImage
				&& (share.mediaFile || share.imageElement)
		){
			if (debug) {
				var then, now;
				if (debug) console.log("first upload image");
				then = Date.now();
			}
			return PictureService.saveShareImage(share)
			.then ( (res) => {
				if (res.error) {
					console.error(res.error)
					return Promise.reject(res.error)
				}
				if (debug) {
					now = Date.now();
					var wait = now - then;
					if (debug) console.log("image uploaded in " + wait+"ms. res:");
					if (debug) console.dir(res);
				}
				share.image = res.image;
				share.imageInfo = res.info;
				return ShareService.saveShare(share);
			})
		} else {
			if (debug) console.log("no new image to upload");
			return ShareService.saveShare(share);
		}
	}

	static saveShare(share) {
		if (debug) console.log("saveShare( {} )");
		if (debug) console.dir(share.postData)
		share.pid = ProfileService.me.pid;
		return Service.fpa('share/new', share.postData)
	}

	static edit(share) {
		if (debug) console.log("edit( {...} ) ");
		if (debug) console.dir(share);
		return Service.fpa('share/edit', share.postData)
	}

	static delete(share) {
		if (debug) console.log("delete( {...} ) ");
		if (debug) console.dir(share);
		var data = share.postData;
		return Service.fpa('share/delete', share.postData)
	}

	static addComment(sid, comment) {
		if (debug) console.log("addComment( "+sid+", '...' ) ");
		if (debug) console.dir(comment);
		var data = {sid, comment, pid: ProfileService.me.pid};
		return Service.fpa('share/comment', data)
	}

}

export default ShareService;