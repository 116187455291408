import config from './AppConfig'
import Service from './Service'

var debug = false;
const logg = function(s, o) { debug && console.log(s, o) }

var obj = {
	getReadableLocation (position) {
		logg("getReadableLocation( {...} )", position);
		const data = {
			latitude: position.coords.latitude,
			longitude: position.coords.longitude
		}
		return Service.fpa('geocode', data )
		.then( (res) => {
			logg("google says: ", res);
			//return Promise.reject("test")
			return Promise.resolve(res);
		})
	}
}

export default obj;
