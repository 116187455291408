import React, { Component } from 'react'
import util from '../AppUtil'

var debug = false;

export default class AwesomeInput extends Component
{
	constructor(props) {
		super(props);
		this.myInput = React.createRef();
		this.myFloater = React.createRef();
		this.myContainer = React.createRef();
		this.type = this.props.type ? this.props.type : 'text';
		this.addClass = '';
		if (this.type === 'password') {
			this.addClass = ' awesome-pw'
		}
		// TODO max length
	}

	focus(e) {
		if (debug) console.log('focus');
		this.adjustLayout(true);
		if (this.props.focusAction) {
			this.props.focusAction();
		}
	}

	blur(e) {
		if (debug) console.log('blur');
		this.adjustLayout(false);
		if (this.props.blurAction) {
			this.props.blurAction();
		}
	}

	adjustLayout(expand=false) {
		if (debug) console.log('adjustLayout '+expand);
		if (expand || this.myInput.current.value.length > 0) {
			util.addClass(this.myFloater.current, 'shifted')
			var w = util.gcs(this.myContainer.current, 'width');
			//var w = this.myContainer.current.offsetWidth;
			w -= util.gcs(this.myFloater.current, 'width');
			w -= 22; // for the margin and border
			this.myInput.current.style.width = w+'px';
		} else {
			this.myInput.current.style.width = '8px'
			util.rmClass(this.myFloater.current, 'shifted')
		}
	}

	change(e) {
		if (!this.myInput.current) return;
		this.data.value = this.myInput.current.value;
		util.dispatch('formInputChanged', this.data);
		this.adjustLayout();
		if (this.props.changeAction) {
			this.props.changeAction();
		}
	}

	click() {
		this.myInput.current.focus();
	}

	render() { return(
		<div
			className='fill-avail'
			onClick={(e) => {this.click(e)}}
		>
			<div
				ref={this.myContainer}
				id={this.props.name+'-input-container'}
				className='text-input-container'
				onClick={(e) => {this.click(e)}}
			>
				<input
					ref={this.myInput}
					type={this.type}
					className={'text-input awesome '+this.addClass}
					id={this.props.name+'-input'}
					// placeholder={this.props.placeholder}
					onChange={(e) => {this.change(e)}}
					onFocus={(e) => {this.focus(e)}}
					onBlur={(e) => {this.blur(e)}}
				></input>
				<div
					ref={this.myFloater}
					className='input-floater'
					onClick={(e) => {this.click(e)}}
				>
					{this.props.text}
				</div>
			</div>
		</div>
	)}

	componentDidMount() {
		this.data = {
			name: this.props.name,
			input: this.myInput.current,
			container: this.myContainer.current,
			type: this.type
		}
		if (this.props.value) {
			this.myInput.current.value = this.props.value;
			this.change();
		}
		// window.addEventListener('resize', this.forceDraw);
	}

	componentWillUnmount() {
		// window.removeEventListener('resize', this.forceDraw);
	}

}