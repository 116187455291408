import ProfileService from '../ProfileService'
import UserPortrait from './UserPortrait'
import ChatService from '../ChatService'
import React, { Component } from 'react'
import UserService from '../UserService'
import ReactDOM from 'react-dom'
import Profile from '../Profile'
import config from '../AppConfig'
import User from '../User'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import './ButtonBar.css'

var debug = false;


class NewButton extends Component
{	
	click() {
		if (debug) console.log("share button clicked()");
		app.goPage('shareEdit');
	}

	render() {
		return (<div
			id="NewButton"
			className='bar-button tint-ui icon-add bar-icon'
			onClick={(e) => this.click(e)}
		></div>);		
	}
}

class GroupButton extends Component
{
	click() {
		if (debug) console.log("GroupButton click()");
		app.go('groups');
	}

	render() {
		return (
			<div id='group-button'
				className='bar-button'
				onClick={(e) => this.click(e)}
			>
				<div
					id='chat-icon'
					className='background-icon bar-icon tint-ui icon-group'
				></div>
				<div
					id='group-alert'
					className='group-alert invisible'
				>
					{'!'}
				</div>
			</div>
		);		
	}

	componentDidMount() {
		window.addEventListener('showChatButton', (evt) => {
			if (debug) console.log("responding to showChatButton");
			var e = util.$('chat-button');
			if (e) e.style.top = '0px';
		});
	}
}

class HomeButton extends Component
{
	click() {
		if (debug) console.log("HomeButton click()");
		app.goPage('feed');
	}

	render() {
		return (<div
			id='HomeButton'
			className='bar-button tint-ui icon-home bar-icon'
			onClick={(e) => this.click(e)}
		></div>);
	}
}

class SearchButton extends Component
{
	click() {
		if (debug) console.log("SearchButton click()");
		app.goPage('search');
	}

	render() {
		return (<div
			id='SearchButton'
			className='bar-button tint-ui icon-search bar-icon'
			onClick={(e) => this.click(e)}
		></div>);		
	}

	componentDidMount() {
		buttonbar.doAfterMount(); // hack
	}
}


class ProfileButton extends Component
{
	click() {
		if (debug) console.log("ProfileButton click()");
		app.loadMyProfilePage();
	}

	setPortrait({ profile }) {
		var button = util.$('buttonbarPortrait');
		if (!button) return;
		button.style.backgroundImage = profile.portrait.css;
		util.rmClass( button, 'tint-ui')
		if (profile.portrait.svg) {
			util.addClass( button, 'tint-ui');
		}
	}

//<div className="profile-button-portrait tint-ui icon-circle"></div>
	render() {
		var pictureClassName = 'profile-button-portrait'
		if (ProfileService.userPortrait.svg) {
			pictureClassName += ' tint-ui';
		}
		return (
			<div
				id="ProfileButton"
				className='bar-button bar-icon'
				onClick={(e) => this.click(e)}
			>
				<UserPortrait
					addClass="buttonbar"
					isMe={true}
				/>
			</div>
		);
	}

	componentDidMount() {
		window.addEventListener('myProfileUpdate', (e) => {
			if (debug) console.log("ProfileButton responding to myProfileUpdate");
			if (debug) console.dir(e.detail);
			this.setPortrait({ profile: e.detail });
		});
	}
}

class ChatButton extends Component
{
	click() {
		if (debug) console.log("ChatButton click()");
		app.goPage('inbox');
	}

	render() {
		return (
			<div id='chat-button'
				className='bar-button'
				onClick={(e) => this.click(e)}
			>
				<div
					id='chat-icon'
					className='background-icon bar-icon tint-ui icon-chat'
				></div>
				<div
					id='chat-alert'
					className='chat-alert invisible'
				>
					{'!'}
				</div>
			</div>
		);		
	}

	componentDidMount() {
		window.addEventListener('showChatButton', (evt) => {
			if (debug) console.log("got showChatButton");
			let e = util.$('chat-button');
			if (e) e.style.top = '0px';
		});
	}
}

////////// button bar (tm) ////////////

var buttonbar =
{
	hidden: true,
	previousUnread: 0,
	
	hide: function() {
		if (debug) console.log("hide()");
		if (app.wideMode() <= 1) {
			util.$('buttonbar').style.bottom = '-99px';
			var p = util.$('ProfileButton');
			if (p) p.style.display = 'none';
			buttonbar.hidden = true;
		}
		app.sizePage();
	},

	show: function() {
		if (debug) console.log("show()");
		if (buttonbar.hidden && app.wideMode() === 0) {
			util.$('buttonbar').style.bottom = '0px';
			var p = util.$('ProfileButton');
			if (p) p.style.display = 'block';
			buttonbar.hidden = false;
		}
		app.sizePage();
	},

	isHidden: function() {
		return buttonbar.hidden;
	},

	hilightButton: function(elm, temp) {
		var no = true;
		if (no) return;
		if (debug) console.log("hilightButton( {...}, "+temp+" )");
		const butts = util.$('.bar-icon');
		
		for (var i = 0; i < butts.length; i++) {
			butts[i].classList.remove('selected');
		}
		if (typeof elm === 'string') elm = util.$(elm);
		if (elm) {
			util.addClass(elm, 'selected');
			if (temp) {
				uu.wait(666).then( () => {
					util.rmClass(elm, 'selected');
				})
			}
		}
	},

	transform(size) {
		if (debug) console.log("transform( "+size+" )");
		const wide = (size >= 2);
		util.classy( wide, util.$('buttonbar'), 'wide2' );
		util.classy( wide, util.$(".bar-button"), 'wide2' );
	},

	doAfterMount() {
		this.transform( app.wideMode() );
	},

	selectFromInput (e) {
		if (debug) console.log("selectFromInput( {} )");
		const files = Array.from(e.target.files);
		const file = files[0]; // multiple selected? use first
		var fr = new FileReader();
		fr.onload = function () {
			if (debug) console.log("file has loaded");
			app.goPage('shareEdit', {src: fr.result, file: file});
		}
		try {
			fr.readAsDataURL(file);
		} catch (e) {
			console.warn("problem reading file")
			console.warn(e);
		}
	},

	screenShow: function(s) {

		var userLevel = UserService.userLevel;

		var buttonDivElements = [];
		var i = 0;
		
		// buttonDivElements.push(<HomeButton key={i++} />);
		buttonDivElements.push(<SearchButton key={i++} />);
		buttonDivElements.push(<GroupButton key={i++} />);
		buttonDivElements.push(<NewButton key={i++} />);
		buttonDivElements.push(<ChatButton key={i++} />);
		buttonDivElements.push(<ProfileButton key={i++} />);

		const element = (
			<div id="buttonbar">
				{buttonDivElements}
			</div>
		);

		ReactDOM.render(element, util.$(s));
		window.addEventListener('pageChange', this.pageChange);
	},

	pageChange: function (e) {
		if (debug) console.log('buttonbar detects pageChange: ');
		if (debug) console.dir(e.detail);
		var newPage = e.detail;
		if (e.detail.name) newPage = e.detail.name;
		if (debug) console.log(newPage);
		var hilightElm;
		var action = buttonbar.show;
		if (newPage) {
			switch (newPage) {
				case 'profile':
				case 'profileEdit':
				case 'settings':
					hilightElm = util.$('ProfileButton');
					break;
				case 'search':
					hilightElm = util.$('SearchButton');
					break;
				case 'feed':
				case 'feedfocus':
					hilightElm = util.$('HomeButton');
					break;
				case 'shareEdit':
					hilightElm = util.$('upload-icon');
					action = buttonbar.hide;
					break;
				case 'inbox':
					hilightElm = util.$('chat-icon');
					break;
				case 'conversation':
					hilightElm = util.$('chat-icon');
				case 'login':
				case 'camera':
				case 'join':
				case 'joinPassword':
				case 'joinEmail':
				case 'joinTelephone':
				case 'joinGoogle':
					action = buttonbar.hide;
					break;
			}
		}
		buttonbar.hilightButton( hilightElm );
		action();
	},

	setUnread (i) {
		if (debug) console.log('setUnread( '+i+' )');
		var elm = util.$('chat-alert');
		if (!elm) return;
		if (i === 0) {
			util.addClass(elm, 'invisible');
			return;
		}
		if (i > 0) {
			util.rmClass(elm, 'invisible');
			if (i > 99) i = '+99';
		}
		if (i > this.previousUnread) {

			if (debug) console.log("ding! more unreads");

			util.addClass(elm, 'ding');
			uu.wait(1000)
			.then( () => {
				util.rmClass(elm, 'ding');
			})
		}
		elm.innerText = i;
		this.previousUnread = i;
	},

	getInbox() {
		if (debug) console.log('getInbox()');
		ChatService.getInbox()
		.then( (res) => {
			if ( res.unread ) {
				buttonbar.setUnread( res.unread.length );
			}
		})
		.catch( (err) => {
			console.error("problem getting inbox");
			console.dir(err);
		})
	},

	gotInbox(inbox) {
		//if (inbox.detail)
		if (debug) console.log('gotInbox( {...} )');
		if (debug) console.dir(inbox)
		if ( inbox.unread ) {
			if (debug) console.dir(buttonbar)
			buttonbar.setUnread( inbox.unread.length );
		} else {
			console.error("no unread in inbox data");
			buttonbar.setUnread( 0 );
		}
	}
};

window.addEventListener('myProfileUpdate', () => {
	if (debug) console.log('responding to myProfileUpdate');
	buttonbar.getInbox()
});

window.addEventListener('inboxUpdate', (e) => {
	if (debug) console.log('responding to inboxUpdate');
	buttonbar.gotInbox(e.detail)
});

export default buttonbar;
