import util from './AppUtil'
import uu from './uu'

var debug = false;

class LocalStore
{
	static printAll() {
		var i = 0;
		var keyName = window.localStorage.key(i++);
		while (keyName) {
			console.log("ls.  " + keyName+": "+window.localStorage.getItem(keyName) );
			keyName = window.localStorage.key(i++);
		}
	}

	static getAllAsString() {
		var i = 0;
		var s = "";
		var keyName = window.localStorage.key(i++);
		while (keyName) {
			
			s +=  "<pre>"+keyName+":";
			var o = window.localStorage.getItem(keyName);
			o = uu.parse(o);
			if (typeof o !== 'object') {
				s += " "+o;
				console.log(keyName+": "+o);
			}
			
			if (typeof o === 'object') {
				console.log(keyName+": ");
				s +="<br />"
			
				var pretty = JSON.stringify(o, null, 2);
				console.log(pretty);
				s += pretty;

				s += "</pre><br />"
			}

			keyName = window.localStorage.key(i++);
		}
		const regex = /,/gi;
		s = s.replace(regex, ", ");
		return s;
	}

	static getItem (key) { return window.localStorage.getItem(key) }
	static get (key) { return window.localStorage.getItem(key) }

	static setItem (key, val) { window.localStorage.setItem(key, val) }
	static set (key, val) { window.localStorage.setItem(key, val) }

	static removeItem(key) { window.localStorage.removeItem(key) }
	static rm(key) { window.localStorage.removeItem(key) }

	static getUser (uid) {
		return window.localStorage.getItem(LocalStore.userKey(uid));
	}

	static setUser (data) {
		data = uu.parse(data);
		const key = LocalStore.userKey(data.uid);
		window.localStorage.setItem(key, JSON.stringify(data));
		return key;
	}

	// static saveUserData (data) {
	// 	const key = LocalStore.userKey(data.uid);
	// 	var user = uu.parse(window.localStorage.getItem(key));
	// 	for (var prop in data) {
	// 		user[prop] = data[prop]
	// 	}
	// }

	static getProfile (pid) {
		if (debug) console.log("getProfile( "+LocalStore.profileKey(pid)+" )");
		return window.localStorage.getItem(LocalStore.profileKey(pid));
	}

	static userKey (uid) { return "user_" + uid; }
	static profileKey (pid) { return "profile_" + pid; }

	static saveLoginInfo (uid, data) {
		console.warn("saveLoginInfo( "+uid+", "+data+")");
		const key = 'login_'+uid;
		var login = uu.parse(window.localStorage.getItem(key));
		for (var prop in data) {
			login[prop] = data[prop]
		}
		login.updated = Date.now();
		window.localStorage.setItem(key, JSON.stringify(login));
	}

	// static getAllUsers() {
	// 	const keys = Object.keys(localStorage);
	// 	var users = [];
	// 	console.dir(keys)
	// 	keys.forEach(key => {
	// 		if (key.indexOf('user_') >= 0) {
	// 			users.push(uu.parse(LocalStore.getItem(key)))
	// 		}
	// 	})
	// 	return users;
	// }

	static getAllLogins() {
		const keys = Object.keys(localStorage);
		var users = [];
		console.dir(keys)
		keys.forEach(key => {
			if (key.indexOf('login_') >= 0) {
				users.push(uu.parse(LocalStore.getItem(key)))
			}
		})
		return users;
	}

	static deleteEverything() {
		console.warn("clearing all local storage");
		window.localStorage.clear();
	}

	static deleteProfiles() {
		const keys = Object.keys(localStorage);
		keys.forEach(key => {
			if (key.indexOf('profile_') >= 0) {
				window.localStorage.removeItem(key)
			}
		})
	}

	static deleteMessages() {
		const keys = Object.keys(localStorage);
		keys.forEach(key => {
			if (key.indexOf('msg_') >= 0) {
				window.localStorage.removeItem(key)
			}
		})
	}

	static deleteUser() {
		var uid = window.localStorage.getItem('uid');
		window.localStorage.removeItem('login_'+uid);
		window.localStorage.removeItem('user_'+uid);
		window.localStorage.removeItem('uid');
		window.localStorage.removeItem('username');
		window.localStorage.removeItem('theme');
	}

	static getChatHistoryWith(pid) {
		const key = 'chats-with_'+pid;
		var h = window.localStorage.getItem(key);
		if (h) return uu.parseArray(h);
		return null;
	}

	static saveMessages(arr, theirPid) {
		console.log("saveMessages( [], "+theirPid+" )");
		var mids = [];
		arr.forEach( msg => {
			mids.push(msg.id);
			window.localStorage.setItem(
				'msg_'+msg.id, uu.stringify(msg));
		})
		var h = LocalStore.getChatHistoryWith( theirPid );
		if (!h) h = [];
		mids.forEach( mid => {
			if (!h.includes(mid)) h.push(mid);
		})
		window.localStorage.setItem(
			'chats-with_'+theirPid,
			uu.stringify(h)
		);
	}

	static getMessage(id) {
		return uu.parse(
			window.localStorage.getItem('msg_'+id));
	}

	static saveConvos(convos) {
		console.log("saveConvos( [...] )");
		console.dir(convos);
		convos.forEach( convo => {
			window.localStorage.setItem(
				'con_'+convo.id, uu.stringify(convo));
		})
	}
}

export default LocalStore;


