import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import { EndlessList } from './EndlessList'
import ShareService from '../ShareService'
import UserPortrait from './UserPortrait'
import React, { Component } from 'react'
import Profile from '../Profile'
import util from '../AppUtil'
import app from '../app'

import './Comment.css'

var debug = false;

export class CommentListItem extends React.Component
{
	constructor(props) {
		super(props);
		this.myName = React.createRef();
		this.age = util.ageString(this.props.data);
		var pid = this.props.data.pid;
		ProfileService.getProfile({pid})
		.then( (p) => {
			if (!p) return;
			this.authorName = p.name;
			if (this.rendered) {
				this.myName.current.innerHTML = p.name;
			}
		})
	}

	messageClicked() {
		console.log("TODO: Message click()");
	}

	portraitClicked() {
		console.log("portrait click() "+this.pid);
		app.loadProfilePagePID(this.pid);
	}

	render() {
		console.log("this.authorName: " + this.authorName);
		this.rendered = true;

		this.comment = this.props.data.comment;
		this.pid = this.props.data.pid;
		var side = 'left';
		if (this.pid === ProfileService.me.pid) {
			side = 'right'
		} else if (this.pid === -1) {
			side = 'center'
		}
		var nub1 = (<div />);
		var nub2 = nub1;
		if (side === 'right') {
			nub2 = (<div className={'comment-arrow '+side} />);
		} else if (side === 'left') {
			nub1 = (<div className={'comment-arrow '+side} />);
		}
		

		const portrait = (
			<div className={'comment-portrait-container '+side}>
				<UserPortrait
					pid={this.pid}
					addClass='comment-portrait'
				/>
			</div>
		)

		var rightSide = (<div />);
		var leftSide = rightSide;
		if (side === 'right') {
			rightSide = portrait;
		} else if (side === 'left') {
			leftSide = portrait;
		}

		return (
			<div className={'comment-container '+side}>
				{leftSide}
				{nub1}
				<div className={'comment-bubble '+side}>
					<div
						className={'comment '+side}
						onClick={(e) => this.messageClicked(e)}
					>
						<div
							ref={this.myName}
							className={'comment-name '+side}
						></div>
						{this.comment}
						<div className='comment-age'>{this.age}</div>
					</div>
				</div>
				{nub2}
				{rightSide}
			</div>
		)
	}
}


export class CommentList extends React.Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new CommentList. props:");
		if (debug) console.dir(props);

		this.endlessList = null;
		this.initialData = props.data;

		this.querryOptions = {
			limit: 10,
			sid: props.sid
		}
		if (debug) console.log("querryOptions:");
		if (debug) console.dir(this.querryOptions);
	}

	getData(options) {
		if (debug) console.log("getData( {...} )");
		if (debug) console.dir(options);
		return ShareService.getComments( options )
		.then( (data) => {
			return data;
		})
	}
	
	endlessListMounted(comp) {
		if (debug) console.log("endlessListMounted( {...} )");
		if (debug) console.dir(comp);
		this.endlessList = comp;
		if (this.props.hostPage) {
			this.props.hostPage.setCommentList(this)
		}
	}

	checkForNew() {
		console.log("checkForNew()");
		if (this.endlessList) this.endlessList.reset(true);
	}

	cleanUp() {
		console.log("cleanUp()");
		this.endlessList.stopTicking();
	}

	render() {
		return (
			<div id="comment-list">
					<div id="no-comments-yet">
						{"No Comments Yet"}
					</div>
					<EndlessList
						hostPage={this}
						data={this.initialData}
						dataFetcher={this.getData}
						componentType={CommentListItem}
						options={this.querryOptions}
					/>
				</div>
		);
	}

	componentDidMount() {
		util.$("no-comments-yet").style.display = 'none';
		if (this.noShares) {
			util.$("no-comments-yet").style.display = 'block';
		}
	}
}
