import UserPortrait, { PortraitArray } from './UserPortrait'
import { ProfileListItem } from './ProfileList'
import ProfileService from '../ProfileService'
import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import { Spacer } from './Common'
import ReactDOM from 'react-dom'
import Profile from '../Profile'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'

import './Group.css'

var debug = true;

class GroupListItem extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new GroupListItem. props:");
		if (debug) console.dir(props);
		this.name = props.data.name;
		this.gid = props.data.id;
		// let members = props.data.members;
	}

	click () {
		console.log("click "+this.gid);
		ProfileService.setGroup(this.gid)
		app.goPage('group', this.props.data);
	}

	optionClick(e) {
		console.log("optionClick "+this.gid);
		e.stopPropagation();
	}

	render() {return (
		<div
			className='group-list-item fill-avail content-shadow'
			onClick={(e) => this.click(e)}
		>
			<div className='gli-name dotdotdot'>{this.name}</div>
			<PortraitArray
				pids={this.props.data.members}
				gid={this.gid}
			/>
		</div>)
	}
}

class GroupsPage extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new GroupsPage.");

		this.groups = [];
		const numGroups = thisPage.groups.length;
		if (numGroups === 0) {
			console.log("TODO: no groups display?")
		} else {
			thisPage.groups.forEach(group => {
					this.groups.push( <GroupListItem
						key={'gli-'+group.id} data={group} />)
			})
		}
	}

	clickCreate () {
		app.goPage('groupEdit')
	}

	render() {
		console.log("InboxPage render. TODO: sort");
		return (
			<div id={thisPage.name} className='app-page' >
				<div className='content grid'>
					<button
						className='button long new-group'
						onClick={(e) => this.clickCreate(e)}
					>
						New Group
					</button>
				</div>
				<div id='page-title'>MyGroups</div>
				<div id='group-list'>{this.groups} </div>
				<Spacer height={100} />
			</div>
		)
	}
}

var thisPage = new AppPage({
	name: 'groups',
	component: (<GroupsPage />)
});

thisPage.initialize = function() {
	if (debug) console.log("groups page initialize() ");
	this.groups = [];

	return ProfileService.groups()
	.then( (groups) => {
		this.groups = groups;
		console.dir(groups)
	})

	return Promise.resolve();
}

export default thisPage;

