import React, { Component } from 'react';
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

var debug = false;

export class TagsContained extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("tags props:")
		if (debug) console.dir(props);
		this.tags = uu.parseArray(this.props.tags);
	}

	render() {
		if (this.tags.length === 0) {
			return (<div />);
		}
		var tags = [];
		var i = 0;
		this.tags.forEach(tag => {
			tags.push(<Tag tag={tag} key={i++} />)
		});
		return (
			<div id='tag-container-scroll-x' >
				{tags}
			</div>
		);
	}
}

export class TagsFull extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("tags props:")
		if (debug) console.dir(props);
		this.tags = uu.parseArray(this.props.tags);
	}

	render() {
		var tags = [];
		var i = 0;
		this.tags.forEach(tag => {
			tags.push(<Tag tag={tag} key={i++} />)
		});
		return (
			<div className='tag-container-full' >
				{tags}
			</div>
		);
	}
}

export class Tag extends Component
{
	clicked() {
		if (debug) console.log("Tag clicked() todo");
		app.goPage('search', {search: this.props.tag})
	}

	render() {
		return (
			<div
				onClick={(e) => this.clicked(e)}
				className='tag'
			>
				{this.props.tag}
			</div>
		)
	}
}