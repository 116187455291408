import { Spacer, ProgressIndicatorButton } from './Common'
import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import AwesomeButton from './AwesomeButton'
import ShareService from '../ShareService'
import React, { Component } from 'react'
import GroupSelect from './GroupSelect'
import datepicker from 'js-datepicker'
import LocalStore from '../LocalStore'
import ShareImage from './ShareImage'
import config from '../AppConfig'
import AppPage from '../AppPage'
import Profile from '../Profile'
import util from '../AppUtil'
import Share from '../Share'
import Tag from '../Tag'
import geo from '../geo'
import app from '../app'
import uu from '../uu'
import './ShareEdit.css'

var debug = true;
const logg = function(s, o) { debug && console.log(s, o) }

class DeleteButton extends Component {
	clicked() {
		if (debug) console.log('DeleteButton clicked()');
		util.dispatch('showConfirmation', {
			title: "Remove Post?",
			message: "Permanently delete this post?",
			textYes: "It Sucks",
			icon: 'trash',
			actionYes: ( () => { this.doIt() } ),
			actionNo: ( () => { this.doNot() } )
		})
	}

	doNot() { }

	doIt() {
		if (debug) console.log('do delete');
		thisPage.disableControls();
		ShareService.delete( thisPage.share )
		.then( () => {
			app.loadMyProfilePage();
		})
		.catch ( (e) => {
			console.error('problem deleteing');
			console.error(e);
			thisPage.showError();
		})
	}

	render() {
		return( <div id='delete-button-container'>
			<button
				id='delete-button'
				type='button'
				className='button '
				onClick={(e) => this.clicked(e)}
			>
				Delete
			</button>
		</div>)
	}
}

class AddImageThumb extends Component {

	constructor(props) {
		super(props);
		this.myDiv = React.createRef();
	}

	clicked() {
		if (debug) console.log("click stock photo "+this.props.image);
		thisPage.share.mediaFile = null;
		var elms = util.$('.add-image-thumb');
		for (var i = 0; i < elms.length; i++) {
			util.rmClass( elms[i], 'selected' );
		}
		util.addClass( this.myDiv.current, 'selected' )
		thisPage.stockImage = this.props.image;
		thisPage.updateImagePreview(
			PictureService.imgUrl( this.props.image )
		);
		thisPage.showStockSelector( false );
		thisPage.share.setStockPhoto( this.props.image );
	}

	render() {
		return( <div
			id={'sampleImage-'+this.props.image}
			ref={this.myDiv}
			className='add-image-thumb'
			onClick={(e) => this.clicked(e)}
			style={{
				backgroundImage: PictureService.imgUrlCss(this.props.image),
			}}
		/> )
	}
}

class StockImageSelector extends Component {

	render() {
		const numButtons = thisPage.stockImages.length;
		const buttonWidth = app.getScreenWidth() / numButtons;
		var thumbs = [];
		for (var i = 0; i < numButtons; i++) {
			thumbs.push( <AddImageThumb
				key={i}
				image={thisPage.stockImages[i]}
				buttonSize={buttonWidth}
			/>)
		}

		return(<div
			id='stock-image-selector'
			className='stock-image-selector'
		>{thumbs}</div>)
	}
}

class ImagePreview extends Component {
	render() {
		return( <img
			id='image-preview'
			className='image-preview invisible'
			src={this.props.imgSrc}
			onLoad={this.handleImageLoaded.bind(this)}
		/> )
	}

	handleImageLoaded() {
		var img = util.$('image-preview');
		var elm = util.$('.feed-list-item-pic-container');
		elm.style.backgroundImage = 'url('+img.src+')';
		util.rmClass(elm, 'tint-main-color');
		if (img.src.indexOf('.svg') > 0) {
			util.addClass(elm, 'tint-main-color');
		}
	}
}

class AddLocation extends Component {

	constructor(props) {
		super(props);
		this.myInput = React.createRef();
	}
	
	onLocation (loc) {
		logg("onLocation( {...} )", loc);
		geo.getReadableLocation(loc)
		.then( (data) => {
			this.locating = false;
			this.located = true;
			this.myInput.current.value = data.location;
			thisPage.share.location = data.location;
			thisPage.checkSaveStatus();
		})
		.catch( (err) => {
			this.locating = false;
			return this.onLocationError(err);
		})
	}

	onLocationError (err) {
		console.error("onLocationError( {...} )");
		console.dir(err);
		this.showError('Problem connecting to server');
		this.locating = false;
	}

	showError(s) {
		this.myInput.current.value = s;
		util.addClass(this.myInput.current, 'error');
	}

  clickInput(e) {
  	logg("clickInput() " +this.located)
  	if (this.located) return;
  }

  locate() {
  	logg("locate() " + this.locating)
  	//if(!navigator.geolocation) return;
  	if (this.locating) return;
  	util.rmClass(this.myInput.current, 'error');
  	this.locating = true;
  	
    this.myInput.current.value = 'Locating';
    this.locatingDots();

    navigator.geolocation.getCurrentPosition(
    	this.onLocation.bind(this), this.onLocationError.bind(this)
    );
  }

  locatingDots() {
  	logg("locatingDots() ")
  	const s = this.myInput.current.value;
  	logg(s);
  	if (s.indexOf('Locating') === 0) {
  		this.myInput.current.value = this.myInput.current.value+" .";
  		uu.wait(500).then( () => { this.locatingDots() })
  	}
  }

  clickIcon(e) {
  	logg("clickIcon() ")
  	if(!navigator.geolocation) {
  		this.showError('no device geolocation');
  		return;
  	};
  	this.locate();
  }

	change(e) {
		thisPage.share.location = this.myInput.current.value;
		thisPage.checkSaveStatus();
	}

	render() {
		return(
			<div
				id='location-container'
				className='input-container'
				// onClick={(e) => {this.clickIcon(e)}}
			>
				<div
					className='icon-location button-icon tint-main-color'
					onClick={(e) => {this.clickIcon(e)}}
				/>
				<input
					ref={this.myInput}
					id='location-input'
					className='text-input post-edit'
					type='text'
					onChange={(e) => {this.change(e)}}
					onClick={(e) => {this.clickInput(e)}}
					placeholder="add location"
				/>
			</div>
		)
	}

	componentDidMount() {
		if (thisPage.share.location) {
			this.myInput.current.value = thisPage.share.location;
			this.located = true;
		}
	}
}

class AddAnImage extends Component
{
	clickCamera() {
		console.warn("clickCamera()");
		thisPage.showStockSelector( false );

		app.takePhoto( (data) => {
			console.log("got photo data:");
			console.dir(data);
			app.goPage( 'shareEdit', {
				src: data.src,
				share: thisPage.share
			})
		});
	}

	clickStockImage() {
		if (debug) console.log("clickStockImage()");
		thisPage.toggleStockSelector();
	}

	clickFile(e) {
		if (debug) console.log("clickFile()");
		thisPage.promptUpload();
	}

	render() {
		if (thisPage.editing) return (<div />);
		const hideSelfie = (app.ios) ? ' hidden' : '';
		return(
			<div
				id='add-image-container'
				className='add-image-buttons-container'
			>
				<div
					id='add-image-camera-button'
					className={'icon-shutter button-icon distributed tint-ui '+hideSelfie}
					onClick={(e) => this.clickCamera(e)}
				/>
				<div
					id='add-image-upload-button'
					className='icon-image button-icon distributed tint-ui'
					onClick={(e) => this.clickFile(e)}
				/>
				<div
					id='add-image-stock-button'
					className='icon-mg button-icon distributed tint-ui'
					onClick={(e) => this.clickStockImage(e)}
				/>
			</div>
		)
	}
}


class InputCaption extends Component
{
	constructor(props) {
		super(props);
		this.myInput = React.createRef();
		console.log("InputCaption message: " + props.message);
	}

	change(e) {
		if (debug) console.log("change( {} )");
		const element = util.$("caption-input");
		if (!element) return;

		if (debug) console.log(" length: " + element.value.length);
		util.dispatch('postTextChanged', element.value)

		// create an image preiew where none yet exists
		if (thisPage.share && !thisPage.share.hasImage) {
			const pic = PictureService.defaultImage;
			if (debug) console.log("use default image: " + pic);
			thisPage.updateImagePreview( PictureService.url(pic) );
			thisPage.share.setStockPhoto( pic );
		}
		
		thisPage.share.text = element.value;
		thisPage.showError(false);
		thisPage.checkSaveStatus();
	}

	render() {
		return(
			<div
				id='caption-container'
				className='input-container'
			>
				<div className='icon-t button-icon tint-main-color' />
				<input
					type='text'
					ref={this.myInput}
					id='caption-input'
					className='text-input post-edit'
					onChange={(e) => {this.change(e)}}
					type='text'
					placeholder="overlay caption"
					maxLength={config.postOverlayText.maxchr}
				/>
			</div>
		)
	}

	componentDidMount() {
		if (this.props.message) {
			this.myInput.current.value = this.props.message;
		}
		console.log(" this.props.message: " + this.props.message);
	}
}

class AddTitle extends Component
{
	render() {
		return(
			<div
				id="title-container"
				className="input-container"
			>
				<div
					className="button-icon icon-flag tint-main-color"
				/>
				<input
					id="title-input"
					className="text-input post-edit"
					type="text"
					placeholder="Event Title"
				/>
			</div>
		)
	}
}

class AddTags extends Component
{
	constructor(props) {
		super(props);
		this.myInput = React.createRef();
	}

	change(e) {
		if (debug) console.log("AddTags change ");
		thisPage.share.tags = this.myInput.current.value;
		thisPage.checkSaveStatus();
	}

	blur() {
		console.log("AddTags blur");
		this.clean();
	}

	clean() {
		console.log("clean");
		var input = this.myInput.current;
		if (!input) return;
		if (debug) console.log("input.value: "+input.value);
		const clean = Tag.makeSaveable( input.value );
		input.value = Tag.readable( clean );
		thisPage.share.tags = input.value;
		thisPage.checkSaveStatus();
	}

	render() {
		return(
			<div
				id="tags-container"
				className="input-container"
			>
				<div className="icon-tag button-icon tint-main-color" />
				<input
					ref={this.myInput}
					id="tags-input"
					className="text-input post-edit"
					type="text"
					onChange={(e) => {this.change(e)}}
					placeholder="add tags"
					onBlur={(e) => {this.blur(e)}}
				/>
			</div>
		)
	}

	componentDidMount()  {
		this.myInput.current.value = Tag.readable(this.props.tags)
	}
}

class SelectDate extends Component
{
	constructor(props) {
		super(props);
		this.daySelect = React.createRef();
		this.monthSelect = React.createRef();
		this.yearSelect = React.createRef();
		this.decadeSelect = React.createRef();
	}

	clickIcon() {
		const d = new Date();
		var s = d.getFullYear() + '-'
					+ d.getMonth() + '-'
					+ d.getDay();

		if (s === this.dateString) {
			this.setDate( '' );
		} else {
			this.setDate( s );
		}
	}

	setDate(s) {
		const elm = util.$('dateDisplay');
		if (!elm) return;
		this.dateString = s;
		elm.innerText = this.dateString;
		thisPage.share.date = this.dateString;

		if (s === '') {
			elm.innerText = 'add date'
			util.addClass(elm, 'ph');
		} else {
			util.rmClass(elm, 'ph');
		}
	}

	change() {
		var s = '';
		if (this.decadeSelect.current.value) {
			s = this.decadeSelect.current.value;
			if (this.yearSelect.current.value) {
				s = s.substring(0, s.length - 1);
				s += this.yearSelect.current.value;
			} else {
				s += 's';
			}
			if (this.monthSelect.current.value) {
				s += '-' + this.monthSelect.current.value;
			}
			if (this.daySelect.current.value) {
				s += '-' + this.daySelect.current.value;
			}
		}
		this.setDate(s);
	}

	render() {
		return(
			<div
				id="date-select"
				className="input-container"
			>
				<div
					className='icon-clock button-icon tint-main-color'
					onClick={(e) => {this.clickIcon(e)}}
				/>
				<div className='date-input'>
					<div
						id='dateDisplay'
						className='fill-avail ph'
					>
						add date
					</div>
					<select
						ref={this.decadeSelect}
						id='yearSelect'
						className='share-edit-select'
						onChange={(e) => {this.change(e)}}
					>
						<option value=''>decade</option>
						<option value='1900'>1900s</option>
						<option value='1910'>1910s</option>
						<option value='1920'>1920s</option>
						<option value='1930'>1930s</option>
						<option value='1940'>1940s</option>
						<option value='1950'>1950s</option>
						<option value='1960'>1960s</option>
						<option value='1970'>1970s</option>
						<option value='1980'>1980s</option>
						<option value='1990'>1990s</option>
						<option value='2000'>2000s</option>
						<option value='2010'>2010s</option>
					</select>

					<select
						ref={this.yearSelect}
						id='yearSelect'
						className='share-edit-select'
						onChange={(e) => {this.change(e)}}
					>
						<option value=''>year</option>
						<option value='0'>0</option>
						<option value='1'>1</option>
						<option value='2'>2</option>
						<option value='3'>3</option>
						<option value='4'>4</option>
						<option value='5'>5</option>
						<option value='6'>6</option>
						<option value='7'>7</option>
						<option value='8'>8</option>
						<option value='9'>9</option>
					</select>

					<select
						ref={this.monthSelect}
						id='monthSelect'
						className='share-edit-select'
						onChange={(e) => {this.change(e)}}
					>
						<option value=''>month</option>
						<option value='1'>1</option>
						<option value='2'>2</option>
						<option value='3'>3</option>
						<option value='4'>4</option>
						<option value='5'>5</option>
						<option value='6'>6</option>
						<option value='7'>7</option>
						<option value='8'>8</option>
						<option value='9'>9</option>
						<option value='10'>10</option>
						<option value='11'>11</option>
						<option value='12'>12</option>
					</select>

					<select
						ref={this.daySelect}
						id='daySelect'
						className='share-edit-select'
						onChange={(e) => {this.change(e)}}
					>
						<option value=''>day</option>
						<option value='1'>1</option>
						<option value='2'>2</option>
						<option value='3'>3</option>
						<option value='4'>4</option>
						<option value='5'>5</option>
						<option value='6'>6</option>
						<option value='7'>7</option>
						<option value='8'>8</option>
						<option value='9'>9</option>
						<option value='10'>10</option>
						<option value='11'>11</option>
						<option value='12'>12</option>
						<option value='13'>13</option>
						<option value='14'>14</option>
						<option value='15'>15</option>
						<option value='16'>16</option>
						<option value='17'>17</option>
						<option value='18'>18</option>
						<option value='19'>19</option>
						<option value='20'>20</option>
						<option value='21'>21</option>
						<option value='22'>22</option>
						<option value='23'>23</option>
						<option value='24'>24</option>
						<option value='25'>25</option>
						<option value='26'>26</option>
						<option value='27'>27</option>
						<option value='28'>28</option>
						<option value='29'>29</option>
						<option value='30'>30</option>
						<option value='31'>31</option>
					</select>

				</div>
			</div>
		)
	}

	componentDidMount() {}
}

class BottomButtons extends Component {

	render() {
		let delButton = (<div></div>);
		if (thisPage.editing) {
			delButton = (<DeleteButton key='se-delete' />);
		}
		return(<div>
			<div
				id='se-error-display'
				className='bb-error'
			/>
			<div
				id='se-bottom-buttons'
				className='content'
			>
				{delButton}
				<AwesomeButton
					key={'share-edit-button-save'} 
					name='save'
					text={thisPage.editing ? "Save Changes" : "Save"}
					textActive='Saving'
					clickAction={ thisPage.submit.bind(thisPage) }
				/>
			</div>
		</div>);
	}
}

class ShareEditPage extends Component
{
	selectFromInput (e) {
		console.log("selectFromInput( {...} )");

		thisPage.stockImage = null;
		const files = Array.from(e.target.files);
		const file = files[0]; // if multiple, use first.
		thisPage.share.mediaFile = file;

		console.log("display image file in the preview");

		var fr = new FileReader();
		fr.onload = function () {
			console.log("file has loaded");
			thisPage.updateImagePreview(fr.result);
			thisPage.checkSaveStatus();
		}
		try {
			fr.readAsDataURL(file);
		} catch (e) {
			console.warn("problem reading file")
			console.warn(e);
		}
	}

	render() {
		let top = (<div />);
		if (thisPage.editing) {
			top =(<div id="page-title">Lemme Fix That...</div>);
		}
		console.log("render ShareEditPage");
		return (
			<div
				id={thisPage.name}
				className='app-page'
			>
				{top}
				<GroupSelect
					groups={thisPage.groups}
					changeAction={thisPage.setGroup}
					addClass={thisPage.editing ? 'hidden' : 'share-edit'}
				/>
				<ShareImage
					share={thisPage.share}
					clickAction={thisPage.promptUpload}
				/>
				<AddAnImage />
				<StockImageSelector />
				<InputCaption message={thisPage.share.text} />
				<AddTags tags={thisPage.share.tags} />
				<AddLocation />
				<SelectDate />
				<input
					type='file'
					id='file-input'
					className='post-edit-file-input'
					onChange={(e) => this.selectFromInput(e)}
				/>
				<BottomButtons />
				<Spacer height={80} />
				<ImagePreview imgSrc={thisPage.share.imgSrc} />
			</div>
		);
	}

	componentDidMount() {
		console.log("ShareEditPage componentDidMount");
		thisPage.checkSaveStatus();
		if (thisPage.share.uploaded && thisPage.share.hasImage) {
			if (debug) console.log("has image: "+thisPage.share.image);
			thisPage.updateImagePreview(
				PictureService.url( thisPage.share.image )
			);
		}
	}
}

var thisPage = new AppPage({
	name: 'shareEdit',
	component: (<ShareEditPage />)
});

thisPage.stockImages = [
	'img/ss/default0.png',
	'img/ss/default1.png',
	'img/ss/default2.png',
	'img/ss/default3.png',
	'img/ss/default4.png']

thisPage.stockImage;
thisPage.saving;
thisPage.finishTime = 2500 * 1;

thisPage.initialize = function(data) {
	console.log("TODO: move comps out of this huge file")
	if (debug) console.log("initialize( {...} )");
	if (debug) console.dir( data );
	if (!data) data = {};
	let share = data.share;
	let src = data.src;
	let file = data.file;
	this.editing = false;
	this.stockImage = null;
	this.saving = false;
	this.beforeEditData = {};
	this.stockPhotoSelectorShowing = false;

	if (share instanceof Share) {
		this.share = share;
		if (share.uploaded) this.editing = true;
	}
	else {
		this.share = Share.makeNew();
		this.share.group = ProfileService.currentGroup();
		this.editing = false;
	}
	if (this.editing) {
		this.beforeEditData = this.share.postData;
	}
	if (src) {
		this.share.imgSrc = src;
		this.share.image = null;
	}
	if (file) this.share.mediaFile = file;
	window.addEventListener("postTextChanged", thisPage.changeCaption);

	return ProfileService.groups()
	.then( groups => {
		this.groups = groups;
		return Promise.resolve();
	})
}

thisPage.setGroup = function(gid) {
	if (debug) console.log("setGroup( "+gid+" )");
	thisPage.share.group = gid;
	if (debug) console.dir(thisPage.share);
	console.log("-")
}

thisPage.changeCaption = function (e) {
	if (debug) console.log("changeCaption()");
	const a = util.$('.text-over-picture');
	if (a) a.innerText = e.detail;
}

thisPage.showError = function( s ) {
	let elm = util.$('se-error-display');
	if (!elm) return;
	if ( s === false) {
		util.rmClass(elm, 'display-block');
		elm.innerText = ""; return;
	}
	util.addClass(elm, 'display-block');
	elm.innerText = s;
}

thisPage.setPhotoSrc = function( src ) {
	if (debug) console.log("setPhotoSrc( {} )");
	if (src) {
		this.share.imgSrc = src;
	}
}

thisPage.showAddImage = function( b )	{
	let elm = util.$('add-image-container');
	elm.style.display = b ? 'block' : 'none';
}

thisPage.showStockSelector = function( b = true ) {
	if (debug) console.log("showStockSelector( "+b+" )");
	this.stockPhotoSelectorShowing = b;
	let sel = util.$('stock-image-selector');
	if (sel) sel.style.height = b ? '64px' : '0';
}

thisPage.toggleStockSelector = function( ) {
	thisPage.showStockSelector(!this.stockPhotoSelectorShowing);
}

thisPage.disableControls = function( submitting = false ) {
	const elms = [
		util.$('caption-input'),
		util.$('tags-input'),
		util.$('delete-button')
	]
	if (!submitting) elms.push( util.$('save-button') );
	elms.forEach( (elm) => {
		try { elm.disabled = true } catch (e) {}
		util.addClass( elm, 'disabled' );
	})
}

thisPage.submit = function() {
	if (debug) console.log("submit()  this.saving: " + this.saving);
	if (this.saving) return false;
	const status = thisPage.checkSaveStatus();
	if (!status.saveOk) {
		console.warn("save is not ok:"); console.dir( status );
		thisPage.showError( status.message );
		return false;
	}
	let group = util.$('groupSelect').value
	if (group == 0) {
		const detail = {
			message: "Share With Everybody?",
			textYes: "Yea", textNo: "No",
			icon: 'earth',
			actionYes: ( () => { this.doSubmit() } ),
			actionNo: (() => {util.dispatch('awesomeButtonReset')})
		}
		util.dispatch('showConfirmation', detail)
	} else { this.doSubmit() }
	return true;
}

thisPage.doSubmit = function() {
	if (debug) console.log("doSubmit()");
	this.saving = true;
	this.disableControls(true);
	
	window.dispatchEvent(new Event("showProgressIndicator"));
	this.prepareShareForUpload();

	if (this.share.uploaded) {
		if (debug) console.log("submit edit to  " + this.share.id);
		ShareService.edit(this.share)
		.then( (res) => {
			if (res.error) {
				thisPage.showError("problem saving "+res.error);
			} else { this.onShareResult(res) }
		})
		.catch( (err) => {
			console.error(err);
			thisPage.showError("problem saving "+res.error);
		})
	} else {
		if (debug) console.log("submit new post");
		ShareService.new(this.share)
		.then( (res) => {
			if (res.error) {
				thisPage.showError("problem saving "+res.error);
			} else { this.onShareResult(res) }
		})
		.catch( (err) => {
			console.error(err);
			thisPage.showError("problem creating new post");
		})
	}

	return true;
}

thisPage.promptUpload = function() {
	if (debug) console.log("promptUpload()");
	if (!thisPage.editing) {
		thisPage.showStockSelector( false );
		util.$('file-input').click();
	}
}		

thisPage.prepareShareForUpload = function () {
	console.log("prepareShareForUpload() this.share:");
	console.dir(this.share);

	//this.share.text = util.$('caption-input').value;
	//this.share.tags = util.$('tags-input').value;
	//this.share.location = util.$('location-input').value;

	if (this.share.pid !== ProfileService.me.pid) {
		console.warn("Moderator Edit. TODO: set the mod id");
		return;
	}

	if (thisPage.stockImage){
		console.log("stock image used");
		this.share.image = thisPage.stockImage;
	} else {
		if (this.share.imgSrc) {
			this.share.imageElement = util.$("image-preview");
		}
	}
}

thisPage.onShareResult = function (res) {
	console.warn("onShareResult()");
	uu.wait( thisPage.finishTime )
	.then( () => { thisPage.goToSharePage() })
}

thisPage.goToSharePage = function() {
	console.warn("goToSharePage()")
	if (this.editing) {
		app.go('feedfocus', this.share.id);
	} else {
		ShareService.getLatest({
			author: ProfileService.pid,
			group: thisPage.share.group
		})
		.then( res => {
			if (Array.isArray(res) && res[0]) {
				app.go('feedfocus', res[0].id);
			}
		})
	}
}

thisPage.updateImagePreview = function (s) {
	if (!s) return;
	if (debug) console.log("updateImagePreview( {} )");
	var img = util.$('image-preview');
	img.src = s;
	if (s.substring(0, 4) !== 'http') {
		this.share.imgSrc = s;
	}
}

thisPage.checkSaveStatus = function () {
	console.log("checkSaveStatus()");

	var saveOk = false;
	var message = "?";

	if (thisPage.editing) {
		var prev = this.beforeEditData;
		var curr = this.share.postData;
		console.dir(prev);
		console.dir(curr);
		saveOk = (prev.text  !== curr.text
			|| prev.image !== curr.image
			|| uu.stringify(prev.tags) !== uu.stringify(curr.tags)
			|| prev.location !== curr.location
			|| prev.date !== curr.date
		)
		if (!saveOk) message = "no changes";
	} else { // new post 
		saveOk = this.share.suitibleForUpload();
		if (!saveOk)  message = "no content";
	}

	var btn = util.$('save-button');
	if (btn) {
		if (saveOk) {
			util.rmClass(btn, 'disabled');
		} else {
			util.addClass(btn, 'disabled');
		}
	}

	return { saveOk, message }
}

thisPage.cleanUp = function() {
	window.removeEventListener(
		'postTextChanged', thisPage.changeCaption );
}


export default thisPage;
