import ProfileService from '../ProfileService'
import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import ReactDOM from 'react-dom'
import util from '../AppUtil'

import './Group.css'

var debug = false;

export default class GroupSelect extends Component
{
	constructor(props) {
		super(props);
		this.input = React.createRef();
		this.icon = React.createRef();
		this.options = [(<option value={0} key='sg-p'>Public</option>)];
		let gid = ProfileService.currentGroup();
		console.log("gid: "+gid)
		if (gid || gid === 0) {
			console.log("set previous share group "+gid);
		} else {
			console.log("no previous share group");
			gid = 0;
		}
		this.iconClass = (gid == 0) ? 'icon-earth' : 'icon-group';
		this.defaultValue = gid;
		ProfileService.groups().then( groups => {
			this.gotGroups(groups)
		})
	}

	gotGroups(groups) {
		console.log("gotGroups")
		console.dir(groups);
		groups.forEach( group => {
			this.options.push(<option value={group.id}
				key={'grp-slct-'+group.id}>{group.name}</option>)
		})
		if (this.rendered) {
			let select = (
				<select
					ref={this.input}
					id='groupSelect'
					className='group-select fill-avail'
					onChange={(e) => {this.change(e)}}
					defaultValue={this.defaultValue}
				>
					{this.options}
				</select>
			);
			ReactDOM.render(select, util.$('select-container'))
		}
	}

	change() {
		let gid = parseInt(this.input.current.value);
		if (this.props.changeAction) {
			this.props.changeAction(gid);
		}
		ProfileService.setGroup(gid);
		console.log("--> "+gid);
		console.dir(this.icon.current);
		if (gid === 0) { // change icon to earth for public
			console.log("go earth")
			util.rmClass(this.icon.current, 'icon-group');
			util.addClass(this.icon.current, 'icon-earth');
		} else {
			util.rmClass(this.icon.current, 'icon-earth');
			util.addClass(this.icon.current, 'icon-group');
		}
	}

	render() {
		this.rendered = true;
		let gid = ProfileService.currentGroup();
		return (
			<div
				id='group-select-container'
				className={this.props.addClass}
			>
				<div
					ref={this.icon}
					className={this.iconClass+' button-icon tint-light'}
				/>
				<div id='select-container'>
					<select
						ref={this.input}
						id='groupSelect'
						className='group-select fill-avail'
						onChange={(e) => {this.change(e)}}
						defaultValue={this.defaultValue}
					>
						{this.options}
					</select>
				</div>
				<div id='debug-gs-group-id' >{gid}</div>
			</div>
		)
	}

	componentDidMount() {
		if (debug){
			util.$('debug-gs-group-id').style.display = 'block';
		}
	}
}

