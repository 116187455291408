import config from './AppConfig'

var debug = true;

var util =
{
	wait: ms => new Promise((r, j) => setTimeout(r, ms)),

	$: id => {
		if (id.charAt(0) === ".") {
			const c = document.getElementsByClassName(id.substring(1))
			if (c.length === 1) return c[0];
			if (c.length === 0) return null;
			return c;
		} else {
			return document.getElementById( id )
		}
	},

	// todo: array of classes
	rmClass( elm, c ) {
		if (!elm) return;
		if (elm instanceof HTMLCollection) {
			for (var e of elm) util.rmClass( e, c );
			return;
		}
		if (elm.classList) {
			if (elm.classList.contains(c)) {
				elm.classList.remove(c);
			}
		} else {
			console.warn('no classlist');
			console.dir(elm);
		}
	},

	// todo: array of classes
	addClass( elm, c ) {
		if (!elm) return;
		if (elm instanceof HTMLCollection) {
			for (var e of elm) util.addClass( e, c );
			return;
		}
		if (elm.classList) {
			if (!elm.classList.contains(c)) {
				elm.classList.add(c);
			}
		}
		else {
			console.warn('no classlist');
			console.dir(elm);
		}
	},

	classy( b, elm, c ) {
		if (!elm) return;
		if (elm instanceof HTMLCollection) {
			for (var e of elm) {
				util.classy( b, e, c );
			}
			return;
		}
		if (b) util.addClass( elm, c );
		if (!b) util.rmClass( elm, c );
	},

	computedProp( elm, s ) {
		return parseFloat(window.getComputedStyle(elm, null).getPropertyValue(s));
	},

	gcs( elm, s ) {
		return parseFloat(window.getComputedStyle(elm, null).getPropertyValue(s));
	},

	validateUserName: function(s) {
		const success = (s.length >= config.username.minLength &&
			s.length <= config.username.maxLength);
		if (success) {
			return {success: true}
		}
		return ({success: false, error:
			(
				"User name must be between " +
				config.username.minLength + " and " +
				config.username.maxLength + " characters"
			)
		})
	},

	validatePassword: function(s) {
		if (!s) s = "";
		if (debug) console.log("validatePassword("+s+")");
		var success = true;
		const min = config.password.minLength;
		const max = config.password.maxLength;
		var errorStack = [];
		//errorStack.push("Passwords must");
		if (s.length < min) {
			if (s.length == 0) {
				return {
					success: false,
					error: "Password is blank"
				}
			}
			errorStack.push("be at least "+min+" characters");
			success = false;
		} else if (s.length > max) {
			errorStack.push("be less than "+max+" characters");
			success = false;
		}

		const lowerCaseLetters = /[a-z]/g;
		if(!s.match(lowerCaseLetters)) {
			errorStack.push("contain a lower case letter");
			success = false;
		} else {
			if (debug) console.log("it has a lower case");
		}

		const upperCaseLetters = /[A-Z]/g;
		if(!s.match(upperCaseLetters)) {
			errorStack.push("contain an upper case letter");
			success = false;
		} else {
			if (debug) console.log("it has a capital");
		}

		const numbers = /[0-9]/g;
		if(!s.match(numbers)) {
			errorStack.push("contain a number");
			success = false;
		} else {
			if (debug) console.log("it has a number");
		}

		if (success) return {success: true}

		// build an error message that describes everything wrong
		const stackSize = errorStack.length - 1;
		var errorMessage = "Passwords must";
		var numConditions = 0;
		for (var i = 0; i <= stackSize; i++)
		{
			var seperator = " ";
			numConditions++;
			if (numConditions >= 2) {
				if (numConditions == stackSize + 1) {
					seperator = " and "
				} else {
					seperator = ", ";
				}
			}
			errorMessage += seperator + errorStack[i];
		}
		errorMessage += ".";

		return {
			success: false,
			error: errorMessage
		}
	},
	// first and last name
	validateName: function(s, rules) {
		if (debug) console.log("validateName( "+s+", "+rules+" )");

		if (s.length < rules.minLength) {
			return {success: false, error: "too short"};
		}
		if (s.length > rules.maxLength) {
			return {success: false, error: "too long"};
		}
		return {success: true};
	},

	validateFirstName: function(s) {
		return util.validateName(s, config.names.first);
	},

	validateLastName: function(s) {
		return util.validateName(s, config.names.last);
	},

	validateYes: function(s) {
		return {success: true};
	},

	validateEmail: function(s) {
		if (debug) console.log("validateEmail( "+s+" )");
		const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		
		if( s.match(mailformat) ) {
			return {success: true};
		} else {
			console.log("email validation failure");
			return {success: false, error: "!"};
		}
	},

	validatePhone: function(s) {
		if (debug) console.log("validatePhone( "+s+" )");
		const telformat =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
		if( s.match(telformat) ) {
			return {success: true};
		}
		else {
			console.warn(s+ "is not a valid phone number");
			return {success: false, error: "!"};
		}
	},

	// marginContainerStyle: function(width) {
	// 	return ({
	// 		width : width + '%',
	// 		left : (((100 - width) / 2) + '%')
	// 	})
	// },
	
	// // parse if a string, always returns an object.
	// // objects are simply returned
	// parse(s) {
	// 	console.error("parse moving")
	// 	if (!s) return {};
	// 	try { if (typeof s === 'string') s = JSON.parse(s) }
	// 	catch (e) { /* it's fine */ }
	// 	return s;
	// },

	// // aways returns an object
	// parseObj(s) {
	// 	console.error("parseObj moving")
	// 	if (!s) return {};
	// 	try {
	// 		if (typeof s === 'string') s = JSON.parse(s);
	// 	} catch (e) {
	// 		console.error("util cannot parse as object.");
	// 		console.dir(s);
	// 		return {};
	// 	}
	// 	return s;
	// },

	// // always returns an array
	// parseArray(s) {
	// 	console.error("parseArray moving")
	// 	if (!s) return [];
	// 	if (Array.isArray(s)) return s;
	// 	try {
	// 		if (typeof s === 'string') s = JSON.parse(s);
	// 	} catch (e) {
	// 		console.error("I can't parse as array:");
	// 		console.dir(s);
	// 		s = [];
	// 	}
	// 	return s;
	// },

	dispatch(s, d) {
		window.dispatchEvent(new CustomEvent(s, {detail: d}));
	},

	setVisability(elm, b) {
		if (!elm || !elm.style) {
			console.error("bad element in setVisability:");
			console.dir(elm);
			return;
		}
		if (b) {
			console.log("show " + elm);
			elm.style.display = "block"
		} else {
			console.log("hide " + elm);
			elm.style.display = "none"
		}
	},

	toggleVisability(elm) {
		if (!elm.style.display || selector.style.display === "none") {
			selector.style.display = "block"
		} else {
			selector.style.display = "none"
		}
	},

	zpad(l, i) {
		var str = i+"";
		//var strlength = str.length();
		while (str.length < l) {
			str = "0"+str;
		}
		return str;
	},

	sdate(i) {
		if (debug) console.log("sdate( "+i+" )");
		if (isNaN(i)) return '?';
		var s = (i+"").slice(-1);
		var suffix = "th";
		switch (s) {
			case "1": suffix = "st"; break;
			case "2": suffix = "nd"; break;
			case "3": suffix = "rd"; break;
		}
		return i + suffix;
	},

	shortNumber(i) {
		if (isNaN(i)) return '';
		i = Math.round(i);
		if (i < 999) {
			return i;
		}
		if (i < 9999) {
			var r = Math.floor(i / 1000);
			r += "." + Math.round((i % 1000)/100);
			return r+"k";
		}
		if (i < 999999) {
			return Math.floor(i % 1000) + "k";
		}
		if (i < 9999999) {
			var r = Math.floor(i / 1000000);
			r += "." + Math.round((i % 1000000)/100000);
			return r+"m";
		}
		return (i % 1000000)+"m";
	},

	displayTime(d) {
		var min = d.getMinutes();
		if (min < 10) min = '0'+min;
		return d.getHours()+":"+min;
	},

	months : ["January", "February", "March",
		"April", "May", "June", "July", "August",
		"September", "October", "November", "December"
	],

	cap(s) {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	},

	ageString(input) {
		if (debug) console.log("ageString( )")
		if (debug) console.dir(input);
		if (!input) return "unkown age";
		if (input.created_at) {
			input = input.created_at;
		} else {
			if (typeof input === 'object' && !input.created_at) {
				console.warn('cannot age this object');
				return '';
			}
		}
		if (debug) console.log("ageString( "+input+" )");
		const d = Date.parse(input);
		const createDate = new Date(d);
		const current = new Date();

		var age = Date.now() - d;
		if (debug) console.log("age: " + age);
		const days = age/24/60/60/1000;
		const hours = Math.round(age/60/60/1000);
		const minutes = Math.round(age/60/1000);
		var age = {days, hours, minutes}
		if (debug) console.log(" age:");
		if (debug) console.dir(age);

		var rs = ""; // return string
		const dayd = current.getDate() - createDate.getDate();
		const monthd = current.getMonth() - createDate.getMonth();
		const yeard = current.getFullYear() - createDate.getFullYear();
		const dif = dayd + monthd + yeard;
		if (dif === 0) rs = "Today";
		if (dayd === 1 && dif === 1) {
			rs = "Yesterday";
		} else {
			rs += this.months[createDate.getMonth()];
			rs += " " + this.sdate(createDate.getDate())
		}
		rs += " " + createDate.getHours();
		rs += ":" + this.zpad(2, createDate.getMinutes());
		// special cases
		if (minutes < 1) {
			rs = "Seconds ago"
		} else if (minutes < 60) {
			rs = minutes + " min"
		} else if (hours < 12) {
			rs = hours + " hr"
		}
		// todo: add year if over 1 year old
		return rs;
	}

}

export default util;
