import { ProfileListItem } from './ProfileList'
import ShareService from '../ShareService'
import React, { Component } from 'react'
import UserService from '../UserService'
import LocalStore from '../LocalStore'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import Profile from '../Profile'
import app from '../app'

import './Search.css'

var debug = true;

class UsersPage extends Component
{
	clicked(uid) {
		console.log("clicked( "+uid+" )");
		UserService.switchUser( uid );
	}

	render() {
		console.log("Render UsersPage");
		console.dir(thisPage.users)
		var userList = [];
		thisPage.users.forEach(user => {
			console.log("list "+user.uid);
			var profile = new Profile(user);
			userList.push(
				<ProfileListItem
					key={'users-page-user-'+profile.pid}
					data={profile}
					text2={user.username}
					clickAction={() =>{this.clicked(user.uid)}}
				/>
			);
		})
		//ProfileListItem
		
		// thisPage.screenDidRender = true;
		return (
			<div id={thisPage.name} className='app-page'>
				<div id='page-title'>Not Feeling Yourself?</div>

					{userList}
					<button
						onClick={(e) => app.goPage('join')}
						type="button"
						className="button long"
						id="buttonTheme"
					>
						Create New Account
					</button>

				<Spacer height={92} />
			</div>
		);
	}
}

var thisPage = new AppPage({
	name: "users",
	component: (<UsersPage />)
});


thisPage.initialize = function() {
	if (debug) console.log("initialize( {...} )");

	this.users = LocalStore.getAllLogins();

	console.log("got all known users from LocalStore:");
	console.dir(this.users);

	var pids = [];
	this.users.forEach( user => {
		pids.push(user.pid);
	})

	return Promise.resolve();

}

export default thisPage;

