import ProfileService from './ProfileService'
import config from './AppConfig'
import util from './AppUtil'
import Tag from './Tag'
import uu from './uu'

var debug = false;

const minTagStringLength = 2;
//const minTextOnlyPostLength = config.postOverlayText.minchr;
const missingImage = '/img/missing.svg';

class Share
{
	constructor({
		id,
		sid,
		pid,
		text,
		image,
		image_info,
		tags,
		created_at,
		comments,
		location,
		date,
		group,
		newShare
	})
	{
		this._id = id;
		if (sid) this._id = sid;
		if (pid) {
			this._pid = pid;
		} else if (newShare) {
			this._pid = ProfileService.userPid
		}
		this._text = text;
		this._image = image;
		this._imageInfo = uu.parse(image_info);
		this._location = location;
		this._date = date;
		this._tags = Tag.parseTags(tags);
		this._created = created_at;
		this._comments = comments;
		this._group = group;
		this._uploaded = (newShare) ? false : true;
	}

	get id() { return this._id }
	//get sid() { return this._sid }
	get pid() { return this._pid }
	set pid(s) { this._pid = s }
	get name() { return this._name }
	set name(s) { this._name = s }
	get date() { return this._date }
	set date(s) { this._date = s }
	get text() { return this._text }
	set text(s) { this._text = s.trim()}
	get imageElmement() { return this._imageElement }
	set imageElmement(s) { this._imageElement = s }
	get image() {
		if (!this._image) return missingImage;
		return this._image;
	}
	set image(s) { this._image = s }
	get tags() { return this._tags }
	set tags(s) { this._tags = s }
	get imageInfo() { return this._imageInfo }
	set imageInfo(s) { this._imageInfo = s }
	set uploaded(b) { this._uploaded = b }
	get uploaded() { return this._uploaded }
	get created() { return this._created }
	set imgSrc(s) {
		// console.trace('set imgSrc( {...} )');
		// console.log(s.substring(0, 99)+".......");
		this._imgSrc = s;
		this._useStockImage = false;
	}
	get imgSrc() { return this._imgSrc }
	set mediaFile(s) { this._mediaFile = s }
	get mediaFile() { return this._mediaFile }
	get age() {
		return util.ageString(this._created);
	}
	get location() { return this._location }
	set location(l) { this._location = l }
	get group() { return this._group }
	set group(i) { this._group = i }
	get postData() {
		return({
			id: this._id,
			text: this._text,
			pid: this._pid,
			image: this._image,
			tags: Tag.makeSaveable(this._tags),
			image_info: this._imageInfo,
			location: this._location,
			group: this._group,
			date: this._date
		})
	}

	static makeNew() {
		return new Share({newShare:true});
	}

	suitibleForUpload() {
		if (debug) console.log("suitibleForUpload()");
		if (debug) console.dir(this)
		if (this._image) return true;
		if (this._imgSrc) return true;
		if (this._mediaFile) return true;
		if (this._text) {
			return (this._text.length >= config.postOverlayText.minchr)
		}
		return false;
	}

	get numCommentsShort() {
		return util.shortNumber(this._comments);
	}

	setStockPhoto( stockPhoto ) {
		this._image = stockPhoto;
		this._useStockImage = true;
	}

	get stockImage() { return this._useStockImage }

	get hasImage() {
		if (debug) console.log("hasImage()");
		if (debug) console.dir(this)
		if (this._image) return true;
		if (this._mediaFile) return true;
		if (this._imgSrc) return true;
		return false;
	}

}

export default Share;