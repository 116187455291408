
var debug = false;

module.exports =
{
	wait: ms => new Promise((r, j) => setTimeout(r, ms)),
	// parse if a string, always returns an object.
	// objects just pass through
	parse(s) {
		if (!s) return {};
		try { if (typeof s === 'string') s = JSON.parse(s) }
		catch (e) { /* it's fine */ }
		return s;
	},

	// aways returns an object
	parseObj(s) {
		if (!s) return {};
		try {
			if (typeof s === 'string') s = JSON.parse(s);
		} catch (e) {
			console.error("cannot parse object:");
			console.dir(s);
			return {};
		}
		return s;
	},

	// always returns an array
	parseArray(s) {
		if (Array.isArray(s)) return s;
		try {
			if (typeof s === 'string') return JSON.parse(s);
		} catch (e) {
			console.error("cannot parse array:");
			console.dir(s);
		}
		return [];
	},

	stringify(o) {
		var s = "{}";
		if (typeof o === 'string') {
			try { JSON.parse(o); s = o;
			} catch (e) {
				logError("String is not valid JSON: " + o);
			}
		}
		if (typeof o === 'object') {
			try { s = JSON.stringify(o);
			} catch (e) {
				logError("Cannot strifify object");
				console.log(e);
			}
		}
		return s;
	},

	sfy(o) { return this.stringify(o) },

	cap(s) {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	},

	rmDupes(a) {
		return [...new Set(a)];
	},

	deepCp(o) {
		return JSON.parse(JSON.stringify(o));
	},

	rmFromArray(arr) {
		let what, a = arguments, L = a.length, ax;
		while (L > 1 && arr.length) {
			what = a[--L];
			while ((ax= arr.indexOf(what)) !== -1) {
				arr.splice(ax, 1);
			}
		}
		return arr;
	}
}

// export default uu;

