import React, { Component } from 'react'
import util from '../AppUtil'

import './FormCheckBox.css'

export default class FormCheckBox extends Component
{
	constructor (props) {
		super(props);
		this.myInput = React.createRef();
		this.myContainer  = React.createRef();
	}

	change (e) {
		if (!this.myInput) return;
		if (this.props.notInteractive) {
			this.myInput.current.checked = this.props.checked;
		} else {
			this.data.value = this.myInput.current.checked;
			this.data.checked = this.myInput.current.checked;
			util.dispatch('formInputChanged', this.data);
		}
	}

	updateCheckStatus () {
		if (this.props.checked && this.myInput.current) {
			this.myInput.current.checked = this.props.checked;
		}
	}

	render () {
		var textClass = 'large-checkbox-text';
		if (typeof this.props.text === 'string') {
			if (this.props.text.length > 32) {
				textClass += ' long';
			}
		} else {
			textClass += ' long';
		}
		
		let textDiv = null;
		let containerClass = 'large-checkbox-container';
		if (this.props.text) {
			containerClass = 'large-checkbox-with-label-container';
			textDiv =(<div className='large-checkbox-text'>
					{this.props.text}</div>);
		}

		this.updateCheckStatus();

		return (
			<div className={ containerClass }>
				<label
					ref={this.myContainer}
					className="checkbox-container"
					id={this.props.name+'-container'}
				>
					<input
						ref={this.myInput}
						id={this.props.name+'-input'}
						type='checkbox'
						onChange={ (e) => {this.change(e)} }
					/>
					<span className='checkmark'></span>
				</label>
				{ textDiv }
			</div>
		)
	}

	componentDidMount() {
		console.log("componentDidMount form check box");
		this.updateCheckStatus();
		this.data = {
			name: this.props.name,
			input: this.myInput.current,
			container: this.myContainer.current,
			type: 'checkbox'
		}
	}
}
