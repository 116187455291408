
var debug = false;

class AppConfig 
{
	constructor() {
		this.loadInfo = {
			host: "https://mygonzo.net",
			file: "config-production.json"
		};


		// PRE-LOADED CONFIG DATA 
		// these are here so things can work
		// before we've loaded the gateway config
		this.defaultTheme = "light";
//		this.login = { previousUid: "most_recent_successful_login_uid" };
		// this.session = {
		// 	expireTime: 999,
		// 	expireMessage: "session has expired" // comes from server
		// };
	}

	getLocation() {
		var r = this.loadInfo.host;
		if (this.loadInfo.port) r += ":" + this.loadInfo.port;
		return r;
	}

	setLocal( b ) {
		if (!b) return;
		this.loadInfo.host = "http://localhost";
		this.loadInfo.port = 2082;
		this.loadInfo.file = "config-local.json";
		this.timeOfNextLoad = null;
	}

	load() {
		this.configUrl = this.loadInfo.host;
		if (this.loadInfo.port) {
			this.configUrl += ':'+this.loadInfo.port;
		}
		this.configUrl += '/' + this.loadInfo.file;

		this.configUrl = '/' + this.loadInfo.file;

		if (debug) console.log("load() " +this.configUrl);

		return fetch(this.configUrl)
		.then( (res) => {
			return res.json()
			.then( (data) => {
				this.gatewayDown = false;
				this.populate(data);
				return true;
			})			
		})
		.catch( (res) => {
			console.error("Problem loading config " + this.configUrl);
			console.error(res);
			this.gatewayDown = true;
			return false;
		})
	};

	populate(data) {
		if (debug) console.log("config populate( {} )");
		if (debug) console.dir(data);
		for (var prop in data) {
			this[prop] = data[prop];
		}
		if (debug) console.dir(this);
		//return true;
	};
};

var config = new AppConfig();
// config.load();
 
export default config;
