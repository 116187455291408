import {Spacer, PageFooter} from './Common'
import React, { Component } from 'react'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import "./Home.css"


class HomePage extends Component
{
	render() { 
		return (
			<div
				id={thisPage.name}
				className="app-page"
				style={{backgroundColor: "var(--background-color)"}}
			>

				<div
					className="logo-container"
					id="logo-container"
				>
					<img
						id="logo"
						className="main-logo fade-in"
						src="img/logo_rev.svg"
					/>
				</div>


				<div className="dark-light-sep"></div>
				<div
					className="under-logo-text"
				>
					"The original place to share and talk about photos."
				</div>

				<div className="light-dark-sep"></div>

				<div>
					<div className="home-page-photo-container photo-really" >
						<div className="text-over-home-image" >
							<br />
							<br />
							<br />
							<br />
							Really?
							<br />
						</div>
					</div>
					<div className="under-home-image dark">
						Yes!<br />
						<br />
						Yes! MyGonzo&trade; is back, baby!<br />
						<br />
						<br />
						<button
							onClick={(e) => thisPage.login(e)}
							className='button home alt'
							>
								Join Now
						</button>
					</div>
				</div>


				<div className="home-page-photo-container photo-fuzz1" >
					<div className="text-over-home-image why-gonzo">
						<br />
						<br />
						<br />
						<br />
						Another social media site?
						<br />
					</div>
				</div>
				<div className="under-home-image" >
					Social media has changed a lot since 2001.<br />
					<br />
					At MyGonzo&trade; we ask the question:<br />
					<br />
					<b>"Has it improved?"</b>
					<br />
				</div>



				<div className="home-page-photo-container photo-share" >
					<div className="text-over-home-image">
						<br />
						<br />
						Share pictures.
						<br />
						<br />
					</div>
				</div>
				<div className="under-home-image dark" >
					Some people say a picture is worth a thousand words.<br />
					<br />
					Others belive the act of taking a photo can steal a 
					part of your soul.<br />
					<br />
					<button
						onClick={(e) => thisPage.login(e)}
						className='button home alt'
						>
							That's MyGonzo
					</button>
				</div>




				<div className="home-page-photo-container photo-suprise" >
					<div className="text-over-home-image">
						<br /><br /><br /><br />
						Serriously?
						<br /><br /><br />
					</div>
				</div>
				<div className="under-home-image" >
					The internet is serrious buisness.<br />
					<br />
					However, at MyGonzo,&trade; we know that you still want to
					goof around with your friends sometimes
					without getting fired.<br />
					<br />
					Or worse.
				</div>




				<div className="home-page-photo-container photo-privacy" >
					<div className="text-over-home-image">
						<br /><br /><br />
						<br />
						Privacy first.<br />
					</div>
				</div>
				<div className="under-home-image dark" >
					In two decades, MyGonzo&trade; has had no data breaches and
					complied with zero ESI subpeonas!<br />
					<br />
						<button
							onClick={(e) => thisPage.login(e)}
							className='button home alt'
						>
							Security via Obscurity
						</button>
				</div>





				<div className="home-page-photo-container photo-spy" >
					<div className="text-over-home-image">
						<br />
						<br />
						<br />
						No spying.<br />
						<br />
						<br />
					</div>
				</div>
				<div className="under-home-image " >
					MyGonzo&trade; will never harvest your
					personal information or
					browsing habbits for any reason.<br />
					<br />
					Additionaly, all MyGonzo&trade; public data
					is guarenteed to be 100% ephemeral.<br />
					<br />
					Just Like You.
				</div>



				
				<div className="home-page-photo-container photo-anon" >
					<div className="text-over-home-image">
						<br />
						<br />
						<br />
					
						<br />
						<br />
						<br />
					</div>
				</div>
				<div className="under-home-image dark" >
					Not feeling yourself?<br />
					<br />
					Envelope your conciousness
					in the deep soothing folds of 
					MyGonzo&trade; anonymity.<br />
					<br />
					Or, go beyond and create multiple profiles,
					each an encapsulation of your ever shifting 
					moods.
					<br />
					<br />
					<button
						onClick={(e) => thisPage.login(e)}
						className='button home alt'
					>
						Who Am I?
					</button>
				</div>



				<div className="home-page-photo-container photo-hunter" >
					<div className="text-over-home-image">
						<br />
						<br />
						What does the name mean?
						<br />
						<br />
					</div>
				</div>
				<div className="under-home-image " >
					<b>gonzo</b> /gon'zoh/ adj. 
					[from Hunter S. Thompson]<br />
					<br />
					1. With total commitment, total concentration, 
					and a mad sort of panache. (Thompson's
					original sense.)<br />
					<br />
					2. More loosely: Overwhelming; outrageous; over the
					top; very large, esp. used of collections of source 
					code, source files, or individual functions.
					<br />
					<br />
				</div>




				<div className="home-page-photo-container photo-jeffnick" >
					<div className="text-over-home-image">
						<br />
						<br />
						Tell me more...<br />
						<br />
					</div>
				</div>
				<div className="under-home-image dark" >
					MyGonzo&trade; was started in September 1999 by
					Jeff Reese with assistance from Nick Scheiblauer.<br />
					<br />
					MyGonzo was invented with the intention of creating a
					community setting where friends
					across the country could stay in touch and
					share photos.<br />
					<br />
					Over the years, interactions on MyGonzo&trade;
					would drastically alter the lives of 
					everyone involved.<br />
					<br />
					Sometimes, for the better.<br />
					<br />
					The new MyGonzo&trade; is currently in development by one 
					of it's most prolific posters and champions, 
					Russ Duckworth.<br />
					<br />
						<button
							onClick={(e) => thisPage.login(e)}
							className='button home alt'
						>
							Be Born Again
						</button>
				</div>




				<div className="home-page-photo-container photo-2guys" >
					<div className="text-over-home-image">
						<br />
						How do you profit?<br />
						<br />
					</div>
				</div>
				<div className="under-home-image " >
					We do not.
					<br /><br />
					MyGonzo&trade; is a home-spun project that simply grew out of a need to have fun with friends using great technology.<br />
					<br />
					Russ, like Jeff before him, currently spends several hours a week maintaining MyGonzo&trade; and ensuring that everything runs smoothly 24/7.
					<br />
					<br />
					<br />
					<button onClick={(e) => thisPage.login(e)} className='button home'>Join For Free</button>
				</div>





				<div className="home-page-photo-container photo-val" >
					<div className="text-over-home-image">
						<br />
						<br />
						<br />
						But I hate everything!
						<br />
						<br />
					</div>
				</div>
				<div className="under-home-image dark" >
					Today, we live in a world where almost everything is 
					completley terrible.<br />
					<br />
					At MyGonzo,&trade; we take this idea and run with it.
					<br />
					<br />
					<button
						onClick={(e) => thisPage.login(e)}
						className='button home alt'
					>
						Haters Welcome
					</button>
				</div>


				<div id='dark-mode-below-this'></div>



				<div className="home-page-photo-container photo-dark" >
					<div className="text-over-home-image">
						<br />
						<br /><br />
						Dark Mode?
						<br /><br />
					</div>
				</div>
				<div className="under-home-image " >
					We have that and more.<br />
					<br />
					MyGonzo,&trade; features several display 
					themes to fit any mood, style, demographic, 
					relgious sect, or battery capacity.
					<br /><br />
				</div>




				<div className="home-page-photo-container photo-drunk" >
					<div className="text-over-home-image">
						<br />
						Surround Yourself With Interesting People.<br />
						<br /><br />
					</div>
				</div>
				<div className="under-home-image dark" >
					It will make you more interesting.<br />
					<br />
					<button
						onClick={(e) => thisPage.login(e)} 
						className='button home alt'
					>
						Gain Popularity
					</button>
				</div>

				



				<div className="home-page-photo-container photo-trevor" >
					<div className="text-over-home-image">
						<br />
						<br /><br />
						But I'm pretty cool already.
						<br /><br />
					</div>
				</div>
				<div className="under-home-image " >
					Statsically speaking, you're probably around average.<br />
					<br />
					Other people will like you more when you thrill them with
					quick and cleaver wit gleamed from MyGonzo.&trade;<br />
					<br />
					<button onClick={(e) => thisPage.login(e)} className='button home'>Improve Now</button>
				</div>





				<div className="home-page-photo-container photo-children" >
					<div className="text-over-home-image">
						<br />
						<br /><br />
						What about the children!?
						<br /><br />
					</div>
				</div>
				<div className="under-home-image dark" >
					MyGonzo&trade; is not intended for children or other undeveloped minds.<br />
					<br />
					If you lack self-awarness, please close this screen
					immediatley and seek the guidance of a parent or spirtualist.<br />
					<br />
					<button
						onClick={(e) => thisPage.login(e)}
						className='button home alt'
					>
						Adults Only
					</button>
				</div>






				<div className="home-page-photo-container photo-nothing" >
					<div className="text-over-home-image">
						<br />
						<br />
						What Would MyGonzo Do?<br />
						<br />
					</div>
				</div>
				<div className="under-home-image " >
					Exahusted from leading the successful campaign to leagalize marijuana
					and gay marriage, MyGonzo&trade; currently belives in nothing.<br />
					<br /><br /><br /><br /><br /><br /><br />
					<button onClick={(e) => thisPage.login(e)} className='button home'>&nbsp;</button>
				</div>


				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				<button onClick={(e) => thisPage.login(e)} className='button home'>&nbsp;</button>
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

				<div className="home-page-photo-container photo-weird" >
					<div className="text-over-home-image">
						Okay, it's time to get werid.<br />
						<br />
						<br />
						<br />
					</div>
				</div>

				<PageFooter />
				<Spacer height={12} />
				
			</div>
		)
	}
	
	componentDidMount() {
		// var menuItems = {};
		// menuItems.edit = {
		// 	label: "Join / Login",
		// 	icon: "person",
		// 	action: () => { app.goPage('join') }
		// }

		// uu.wait(420)
		// .then( () => {
		// 	window.dispatchEvent(
		// 		new CustomEvent('setCustomMenuItems',
		// 			{ detail: menuItems })
		// 	);
		// })
	}
}

var thisPage = new AppPage({
	name: "home",
	component: (<HomePage />)
});

thisPage.login = function() {
	app.goPage('join')
}
export default thisPage;

