import { LoadingDisplay } from './EndlessList'
import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import MessageDisplay from './MessageDisplay'
import { FeedListItemFull } from './FeedList'
import AwesomeButton from './AwesomeButton'
import AwesomeInput from './AwesomeInput'
import FormCheckBox from './FormCheckBox'
import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import { Spacer } from './Common'
import config from '../AppConfig'
import Message from '../Message'
import AppPage from '../AppPage'
import Profile from '../Profile'
import util from '../AppUtil'
import Share from '../Share'
import app from '../app'

import './Theme.css'

var debug = true;

class ThemePage extends Component
{
	click(theme) {
		this.changeTheme(theme)
	}

	changeTheme(theme) {
		console.log('click( '+theme+' )');
		app.useTheme( theme );
		try{
			util.$('page-title').innerText = this.pageTitle(theme);
		} catch (e) {}
	}

	pageTitle(s) {
		switch (s) {
			case 'light':	return "Don't Be Evil"
			case 'dark':	return "Save Energy & Eyeballs"
			case 'mygonzo':	return "MyGonzo is Back"
			case 'decepticon': return "PEACE THROUGH TYRANNY"
			case 'autobot': return "Roll Out"
			case 'tron': return "Greetings Programs"
			case 'fitfund': return "Welcome to Web App"
			case '2000': return "Pop the Bubble"
			case '2004': return "Web 2.0"
			case 'starwars': return "Never Tell me the Odds"
			case 'skull1': return "It's Never Been a Game"
			case 'ace': return "Micronian Devil"
			case 'hunter': return "Invitation Number 201"
			case 'atxt': return '10 PRINT "HELLO"'
			case 'ucsc': return "Amber till Dawn"
		}
		return 'We Have the Themes';
	}

	showConfirm() {
		if (debug) console.log('DeleteButton clicked()');
		const detail = {
			message: 'Are you sure?',
			textYes: "Fuck Yea",
			textNo:'Hell No',
			icon: 'mg',
			actionYes: (() => { util.rmClass(util.$('errorMessage'), 'hidden') } ),
			actionNo: (() => { util.addClass(util.$('errorMessage'), 'hidden') } )
		}
		// const evt = new CustomEvent('showConfirmation', {detail});
		// window.dispatchEvent( evt );
		util.dispatch('showConfirmation', detail);
	}

	changed (e) {
		console.log("changed()");
		//this.submit();
		this.changeTheme(util.$('select-theme').value);
	}

	render() {
		var testShare = new Share({
			text: "Ask me anything...",
			image: "img/ss/default0.png",
			author: 1,
			pid: 14,
			comments: 88
		})
		var msg = [];
		msg[0] = new Message({
			text: "hi.",
			from: ProfileService.userPid
		})
		msg[1] = new Message({
			text: "Hello.",
			from: 14
		})
		msg[2] = new Message({
			text: "To die, than flesh is a bare bodkin? Who would by oppresolence oppressor's deather with when heir the undiscorns, whethis a life, the arrows of regards of the with the undiscorns that that unwortal cowards of that pith their thance things off thus may cowardelay, the and that pation: whose in than fly to say we know not off time, the rub?",
			from: ProfileService.userPid
		})
		msg[3] = new Message({
			text: "That is not logical.",
			from: 14
		})
		return (
			<div id='theme' className='app-page'>
				<div className='page-banner settings' />
				<div id='page-title'>
					Select a Theme
				</div>
				<div className='content pad12px'>

					<select
						className='button long'
						id='select-theme'
						onChange={(e) => {this.changed(e)}}
					>
						<option value='simple'>Default</option>
						<option value='light'>Light</option>
						<option value='dark'>Dark</option>
						<option value='mygonzo'>MyGonzo 2020</option>
						<option value='2000'>MyGonzo 2000</option>
						<option value='2004'>MyGonzo 2004</option>
						<option value='decepticon'>Decepticon</option>
						<option value='autobot'>Autobot</option>
						<option value='tron'>Tron</option>
						<option value='starwars'>Space Wars</option>
						<option value='atxt'>AT&T XT</option>
						<option value='ucsc'>UCSC</option>
						<option value='hunter'>Hunter</option>
						<option value='ace'>Ace</option>
						<option value='skull1'>Skull 1</option>
						<option value='fitfund'>FitFund.net</option>

					</select>

				</div>
				<Spacer />
				<FeedListItemFull share={testShare} />
				<MessageDisplay message={msg[0]} />
				<MessageDisplay message={msg[1]} />
				<MessageDisplay message={msg[2]} />
				<MessageDisplay message={msg[3]} />
				<Spacer height={100} />
				<div className='content-container'>
					<div className='form-container' >
						<div>Sample Form</div>
						<AwesomeInput
							name='tes1'
							text='name'
						/>
						<AwesomeInput
							name='test2'
							text='favorite ice cream'
						/>
						<FormCheckBox
							name='remember'
							text="I promise to be good."
						/>
						<div
							id='errorMessage'
							className='error-string hidden'
						>
							WTF did I tell you?!
						</div>

						<AwesomeButton />

						<button
							onClick={(e) => this.showConfirm(e)}
							type="button"
							className="button long"
							id="buttonSubmit"
						>
							Don't Press
						</button>

						<button

							type="button"
							className="button long disabled"
							id="buttonSubmit"
						>
							I'm Disabled
						</button>
					</div>
				</div>
				<Spacer />
				<LoadingDisplay />
				<Spacer height={100} />
			</div>
		)
	}
}

var thisPage = new AppPage({
	name: "theme",
	component: (<ThemePage />)
});


export default thisPage;
