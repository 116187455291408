import PictureService from '../PictureService'
import React, { Component } from 'react'
import util from '../AppUtil'
import app from '../app'

import './FeedList.css'

var debug = false;

export default class ShareImage extends React.Component
{
	constructor(props) {
		super(props);
		this.share = this.props.share;
		this.picDiv = React.createRef();
	}

	click() {
		if (this.props.clickAction) {
			this.props.clickAction();
		} else {
			this.gotoFocus();
		}
	}

	gotoFocus() {
		const pageNames = ['feedfocus', 'shareEdit'];
		if (!pageNames.includes(app.currentPage.name)) {
			app.goPage( 'feedfocus', this.share );
		}
	}

	render() {
		if (debug) console.log("ShareImage rendering");
		return (
			<div
				className='feed-list-item-pic-container tint-photo'
				id={'share-image-'+this.share.id}
				ref={this.picDiv}
				onClick={(e) => this.click(e)}
				style={{
					backgroundImage: PictureService.css(this.share.image)
				}}
			>
				<div className='text-over-picture-container'>
					<div className='text-over-picture'>{this.share.text}</div>
				</div>
			</div>
		)
	}

	componentDidMount() {
		if (!this.share.image) return;
		if (this.share.image.indexOf('.svg') > 0) {
			util.rmClass(this.picDiv.current, 'tint-photo');
			util.addClass(this.picDiv.current, 'tint-main-color');
		}
	}
}
