import React, { Component } from 'react'
import FormCheckBox from './FormCheckBox'
import app from '../app'

import './Agree.css'

export default class Agree extends Component
{
	render() {
		return (
			<FormCheckBox
				name='agree'
				text={(<div>I agree to the MyGonzo <a onClick={()=>{app.goPage('terms')}}>Terms of Service</a> and <a onClick={()=>{app.goPage('pp')}}>Privacy Policy</a></div>)}
			/>
		)
	}
}

