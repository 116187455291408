import ProfileService from '../ProfileService'
import UserPortrait from './UserPortrait'
import React, { Component } from 'react'
import Profile from '../Profile'
import util from '../AppUtil'
import app from '../app'


import './ProfileList.css'
import './Avatar.css'

var debug = false;

export class ProfileListItemMe extends Component
{
	clicked() { console.log("King") }

	render() { return( <ProfileListItem
		icon='crown'
		data={ProfileService.me}
		clickAction={(e) => this.clicked()}
	/>)}
}

export class ProfileListItemAdd extends Component
{
	clicked() {
		console.log("ProfileListItemAdd click");
		util.dispatch('profileListItemAddClick', this.props.data)
	}
	render() { return( <ProfileListItem
		icon='add'
		data={this.props.data}
		clickAction={(e) => this.clicked()}
	/>)}
}

export class ProfileListItemRm extends Component
{
	clicked() {
		console.log("ProfileListItemRm click");
		util.dispatch('ProfileListItemRmClick', this.props.data)
	}
	render() { return( <ProfileListItem
		icon='trash'
		data={this.props.data}
		clickAction={(e) => this.clicked()}
	/>)}
}


export class ProfileListItem extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new ProfileListItem. props:");
		if (debug) console.dir(props);
		this.profile = {};
		if (this.props.data) {
			if (this.props.data instanceof Profile) {
				this.profile = this.props.data;
				this.text1 = this.profile.displayName;
				this.text2 = '';
			}
		}
		if (props.text1) this.text1 = props.text1;
		if (props.text2) this.text2 = props.text2;
		this.iconClass = 'button-icon tint-light';
		if (props.icon) {
			this.iconClass += ' icon-'+props.icon;
		} else {
			//'icon-chat'
		}
	}

	clicked() {
		if (debug) console.log("clicked()");
		if (this.props.clickAction) {
			this.props.clickAction()
		} else {
			app.goPage( 'profile', this.profile );
		}
	}

	iconClick() {
		if (debug) console.log("iconClick()");
		if (this.props.iconClickAction) {
			this.props.iconClickAction()
		}
	}

	render() { return (
		<div
			className='profile-list-item content-shadow'
			style={this.divStyle}
			onClick={(e) => this.clicked()}
		>
			<UserPortrait
				profile={this.profile}
				addClass='profile-list'
			/>
			<div
				className='profile-names-container dotdotdot'
			>
				<div className='profile-list-name'>
					{this.text1}
				</div>
				<div className='profile-list-name alt-text'>
					{this.text2}
				</div>
			</div>
			<div
				className={this.iconClass}
				onClick={(e) => {this.iconClick()}}
			></div>
		</div>)
	}
}

