import uu from './uu'
import {
	ProfileInfoField,
	FirstName,
	LastName,
	AboutText,
	City,
	State,
	ProfilePortrait,
	ProfileFollowing
} from './ProfileInfoField'

var debug = false;
const cacheTime = 60 * 15 * 1000;

class Profile
{
	constructor(data) {
		data = uu.parse(data);
		if (!data) {
			console.error("cannot construct a profile with:")
			console.dir(data);
			return null;
		}

		if (debug) console.log("new profile( {...} ):");
		if (debug) console.dir(data);

		this.data = data;
		this.id = data.id;
		this._version = data.version;

		var key;
		// make all the data field objects
		key = 'first_name';
		this.firstName = new FirstName( data[key] );
		key = 'last_name';
		this.lastName = new LastName( data[key] );
		key = 'city';
		this.city = new City( data[key] );
		key = 'state';
		this.state = new State( data[key] );
		key = 'about';
		this.aboutText = new AboutText( data[key] );
		key = 'portrait';
		var ppic = data[key] ? data[key] : 'img/pp/nobody.svg';
		this.portrait = new ProfilePortrait( ppic );
		key = 'following';
		this.following = new ProfileFollowing( uu.parseArray( data[key] ) );
		key = 'avatar';
		this.avatar = data.avatar;
		key = 'avatar_color';
		this.avatarColor = data.avatar_color;
	}

	setMyPortrait(s) {
		this.portrait.value = s;
	}

	update (data) {
		if (debug) console.log("update( {...} )");
		if (debug)console.dir(data);
		for (var prop in data) {
			this.data[prop] = data[prop];
		}
	}

	get pid() { return this.id }
	get version() { return this._version ? this._version : 0 }
	get userName() { return this.data.username }

	get displayName() {
		var displayName = "Anonymous Person";
		var first, last;
		first = this.firstName.value;
		last = this.lastName.value;
		if (first || last) {
			displayName = "";
			if (first) displayName += first;
			if (first && last) displayName += " ";
			if (last) displayName += last;
		}
		return displayName;
	}

	get name() { return this.displayName }

	get stale() {
		if (debug) console.log("stale() ? " + this.version)
		const age = Date.now() - this.version;
		return (age > cacheTime);
	}

	freshNow () {
		if (debug) console.log("freshNow()");
		this._version = Date.now();
		this.data.version = this._version;
	}

	follows(pid) {
		return (this.following.isFollowing(pid));
	}
}

export default Profile;