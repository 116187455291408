import { ProfileListItem } from './ProfileList'
import ProfileService from '../ProfileService'
import { EndlessList } from './EndlessList'
import ShareService from '../ShareService'
import { FeedListItem } from './FeedList'
import React, { Component } from 'react'
import TagService from '../TagService'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import Profile from '../Profile'
import ReactDOM from 'react-dom'
import Share from '../Share'
import util from '../AppUtil'
import app from '../app'

import './Search.css'

var debug = true;


class SearchTab extends Component
{
	render() { return (<div
		className='search-tab'
		id={this.props.name+'-tab'}
		onClick={()=>{
			thisPage.changeSearch(this.props.name) }}
	>
		{this.props.display}
	</div>)}
}

class SearchTabs extends Component
{
	render() { return (
		<div id='search-tabs'
			className='content-shadow'
		>
			<SearchTab name='recent' display='Recent' />
			<SearchTab name='tag' display='Tags' />
			<SearchTab name='profile' display='People'/>
		</div>)
	}

	componentDidMount() {
		thisPage.hilightTab();
	}
}

class SearchBar extends Component
{
	render() { return (
		<div
			id='search-bar'
			className='content-shadow'
		>
			<div
				id='search-page-search-button'
				className='button-icon icon-search tint-light'
			/>
			<SearchQuerryInput />
		</div>)
	}
}

class SearchQuerryInput extends Component
{
	clicked() {
		thisPage.showTabs();
	}

	changed() {
		this.submit();
	}

	keydown (e) {
		var keyCode = ('which' in e) ? e.which : e.keyCode;
		if (keyCode == 13) this.submit(e); // Return Key
	}

	submit() {
		if (debug) console.log("submit");
		thisPage.changeQuery(
			util.$('search-input').value.trim())
	}

	render() { return (
		<div
			id='search-query'
			onClick={(e) => this.clicked(e)}
		>
			<input
				id='search-input'
				className='text-input search fill-avail'
				type='search'
				placeholder='Search'
				onChange={(e) => {this.changed(e)}}
				onKeyDown={(e) => {this.keydown(e)}}
			/>
		</div>)
	}

	componentDidMount() {
		util.$('search-input').value = thisPage.query;
		thisPage.setPlaceHolder();
	}
}

class SearchPage extends Component
{
	render() {
		if (debug) console.log("Render SearchPage");
		thisPage.screenDidRender = true;
		return (
			<div id={thisPage.name} className='app-page'>
				<SearchTabs />
				<SearchBar />
				<EndlessList
					hostPage={thisPage}
					data={thisPage.initialData}
					dataFetcher={thisPage.getData}
					options={{}}
					componentType={thisPage.RenderComponent}
				/>
			</div>
		);
	}
}

var thisPage = new AppPage({
	name: "search",
	component: (<SearchPage />)
});


thisPage.initialize = function(data) {
	if (debug) console.log("initialize( {...} )");
	if (debug) console.dir(data);
	if (!data) data = {};
	this.searchType = 'recent';
	this.sortType = 'search';
	this.initialData = null;
	this.query = '';
	this.RenderComponent = FeedListItem;
	if (data.search) { // assume tag search
		thisPage.searchType = 'tag'
		this.query = data.search;
	}
	return Promise.resolve();
}

thisPage.endlessListMounted = function(comp) {
	if (debug) console.log("endlessListMounted( {...} )");
	this.endlessList = comp;
}

thisPage.getData = function(options) {
	if (debug) console.log("getData( {...} )");
	if (debug) console.dir(options);

	if (thisPage.searchType === 'profile') {
		return thisPage.getProfileData(options)
	} else if (thisPage.searchType === 'tag') {
		return thisPage.getTaggedShared(options)
	} else if (thisPage.searchType === 'recent') {
		return thisPage.getRecentShares(options)
	} else {
		console.error("!!! unkonwn search type "+thisPage.searchType);
		return {};
	}
}

thisPage.getProfileData = function(options) {
	return ProfileService.getProfiles({
		sort: thisPage.sortType,
		offset: options.offset,
		amount: 4,
		search: thisPage.query
	})
}

thisPage.getRecentShares = function(options) {
	return ShareService.getLatest(options);
}

thisPage.getTaggedShared = function(options) {
	return TagService.getShares({
		sort: thisPage.sortType,
		offset: options.offset,
		amount: 8,
		tag: thisPage.query
	})
	.then( (tagData) => {
		if (debug) console.log("tagData:");
		if (debug) console.dir(tagData);
		if (!tagData.length) {
			console.log("no results for " + thisPage.query);
			return Promise.resolve([]);
		}
		var arr = [];
		tagData.forEach(entry => {
			arr.push(entry.sid)
		})
		return ShareService.getShares( arr )
		.then( (shareData) => {
			if (debug) console.log("shareData:");
			if (debug) console.dir(shareData);
			return Promise.resolve(shareData);
		})
	})
}

thisPage.showTabs = function() {
	if (debug) console.log("showTabs()");
	const elm = util.$('search-tabs');
	if (elm) elm.style.display = 'grid';
}

// thisPage.changeSortType = function(s) {
// 	if (debug) console.log("changeSortType( "+s+" )");
// 	this.sortType = s;
// 	if (this.endlessList) {
// 		this.endlessList.reset(true);
// 	}
// }

thisPage.changeSearch = function(s) {
	if (debug) console.log("changeSearch( "+s+" )");
	this.hilightTab(s);
	if (s === 'recent') {
		this.hideSearchBar();
	} else {
		this.showSearchBar();
	}
	this.searchType = s;
	thisPage.setPlaceHolder();
	this.resetEndlessList(s);
}

thisPage.changeQuery = function(q) {
	this.query = q;
	this.resetEndlessList();
}

thisPage.resetEndlessList = function(s) {
	if (this.endlessList) {
		if (s) {
			this.endlessList.useComponent(searchComps[s]);
		}
		this.endlessList.reset(true);
	}
}

var searchComps = {};
searchComps['profile'] = ProfileListItem;
searchComps['tag'] = FeedListItem;
searchComps['recent'] = FeedListItem;

const placeholders = {
	'profile':'Search People',
	'tag':'Search Tags',
	'recent':''
}

thisPage.setPlaceHolder = function() {
	var ph = util.$('search-input');
	if (!ph) return;
	ph.placeholder = placeholders[this.searchType];
}

thisPage.showSearchBar = function() {
	util.$('search-bar').style.display = 'grid';
}
thisPage.hideSearchBar= function() {
	util.$('search-bar').style.display = 'none';
}

thisPage.hilightTab = function(tab) {
	if (typeof tab === 'string') {
		tab = util.$(tab+'-tab');
	}
	if (!tab) {
		console.log("hilightTab " + thisPage.searchType);
		tab = util.$(thisPage.searchType+'-tab');
	}
	if (!tab) return;

	var tabs = util.$('.search-tab');
	for (var i = 0; i < tabs.length; i++) {
		tabs[i].classList.remove('selected');
	}
	tab.classList.add('selected');
}

export default thisPage;

