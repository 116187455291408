import util from './AppUtil'
import app from './app'
import uu from './uu'

class AppPage
{
	//static active;

	name = "";
	renderElement = "";

	constructor({name, component, initFunction}) {
		this.name = name;
		this.renderElement = component;
		this.register();
		if (initFunction) {
			this.initialize = initFunction;
		}
	}

	register() {
		//console.log("AppPage register " + this.name);
		if (!app) {
			return uu.wait(100)
			.then( () => {
				//console.log("try again to register " + this.name);
				this.register()
			})
		}
		app.registerAppPage( this );
	}

	initialize(data) {
		//console.warn(this.name+" generic do nothing initializing...");
		return Promise.resolve();
	}

	getPathName() {
		return this.name;
	}

	cleanUp() {
		//console.warn("No cleanUp() for " + this.name);
		return Promise.resolve();
	}

	reStart() {
		//console.warn("No reStart() for " + this.name);
		return Promise.resolve();
	}
}

export default AppPage;

