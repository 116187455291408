import uu from './uu'

var debug = true;

class User
{
	constructor(data) {
		if (debug) console.log("New User! data:");
		data = uu.parse(data);
		if (debug) console.dir(data);

		this._uid = data.uid;
		this._username = data.username;
		this._session = data.session; 
		this._session_id = data.session_id;
		this._auto_login_token = data.auto_login_token;
		this._user_level = data.user_level;
		
	}

	get uid() { return this._uid }
	get session() { return this._session }
	get sessionId() { return this._session_id }
	get userName() { return this._username }
	get userLevel() { return parseInt(this._user_level, 10) }
	get autoLoginToken() { return this._auto_login_token }
	get sessionToken() { return this._session }
	// get isAdmin() { return (this._user_level > 2) }

	get localData() {
		return {
			uid: this._uid,
			username: this._username,
			session: this._session,
			session_id: this._session_id,
			auto_login_token: this._auto_login_token
		}
	}

	login(data) {
		this._session = data.session;
		this._session_id = data.session_id;
		this._uid = data.uid;
	}

}

export default User;
