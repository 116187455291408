import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import config from '../AppConfig'
import ReactDOM from 'react-dom'
import util from '../AppUtil'
import app from '../app'

import './Menu.css'

var debug = false;

class MenuItem extends Component
{
	constructor(props) {
		// accepts one data object or a list of props
		super(props);
		if (props.data) {
			this.action = props.data.action;
			this.icon = props.data.icon;
			this.label = props.data.label;
		} else {
			this.action = props.action;
			this.icon = props.icon;
			this.label = props.label;
		}
	}
	render() {
		return (
			<div
				id={'menu-item-'+this.props.name}
				className='menu-item'
				onClick={(e) => {this.action()}}
			>
				<div className={"menu-icon tint-ui icon-"+this.icon} ></div>
				{this.label}
			</div>
		)
	}
}

export default class Menu extends React.Component
{
	render() {
		console.log("render menu");
		return(
			<div
				id='main-menu'
				className='main-menu hidden'
			>

				<div id="customButtons" ></div>

				<MenuItem
					label="MyProfile"
					icon='user'
					name='profile'
					action={(e) => {app.loadMyProfilePage()}}
				/>
				<MenuItem
					label="Settings"
					icon='gear'
					name='settings'
					action={(e) => {app.goPage('settings')}}
				/>
				<MenuItem
					label="Privacy Policy"
					icon='file'
					name='pp'
					action={(e) => {app.goPage('pp')}}
				/>
				<MenuItem
					label="Join / Login"
					icon='user'
					name='join'
					action={(e) => {app.goPage('join')}}
				/>

			</div>
		)
	}

	showMenu( b = true ) {
		if (debug) console.log("showMenu( "+b+" )");
		this.menuOpen = b;
		if (this.menuOpen) {
			util.rmClass( util.$('main-menu'), 'hidden');
		} else {
			util.addClass( util.$('main-menu'), 'hidden');
		}

		var hasAccount = LocalStore.getItem('uid');
		const elm = util.$('menu-item-join');
		if (elm) 
			elm.style.display = hasAccount ? 'none' : 'flex';
	}

	componentDidMount() {
		window.addEventListener("showMenu", (e) => {
			if (debug) console.log("responding to showMenu");
			this.showMenu();
		});
		window.addEventListener("hideMenu", (e) => {
			if (debug) console.log("responding to hideMenu");
			this.showMenu(false);
		});
		window.addEventListener("toggleMenu", (e) => {
			if (debug) console.log("responding to toggleMenu");
			this.showMenu(!this.menuOpen);
		});
		window.addEventListener("pageChange", (e) => {
			if (debug) console.log("responding to pageChange");
			this.showMenu(false);
			this.page = e.detail.page;
			// clear custom buttons
			window.dispatchEvent(
				new CustomEvent('setCustomMenuItems', { detail: {} })
			);
		});

		window.addEventListener('clickMain', (e) => {
			if (debug) console.log('respond to clickMain');
			this.showMenu(false);
		});

		window.addEventListener('setCustomMenuItems', (e) => {
			if (debug) console.log("responding to setCustomMenuItems");
			if (debug) console.dir(e.detail);
			var butts = [];
			for (const b in e.detail) {
				butts.push(<MenuItem data={e.detail[b]} />);
			}
			ReactDOM.render( butts, util.$('customButtons') );
		});
	}
}
