import Confirmation from './Confirmation'
import React, { Component } from 'react'
import ChatService from '../ChatService'
import config from '../AppConfig'
import Profile from '../Profile'
import util from '../AppUtil'
import User from '../User'
import Menu from './Menu'
import app from '../app'

import './Header.css'

var debug = false;

const portraitSize = 76;

class BackButton extends React.Component
{
	click() {
		if (debug) console.log("BackButton click()");
		app.goBack();
		if (this.props.onBack) {
			if (debug) console.log("do the onBack");
			this.props.onBack();
		}
	}

	render() {
		return (
			<div
				id="BackButton"
				className="button-icon back tint-ui icon-back"
				onClick={(e) => this.click(e)}
			></div>
		)
	}
}

class HeaderPortrait extends React.Component
{
	clicked() {
		if (debug) console.log("HeaderPortrait handleClick()");
		app.loadMyProfilePage();
		//app.loadProfilePagePID( feedfocus.share.pid );
		//util.$('header-portrait').style.backgroundImage = ProfileService.me.portrait.urlCss;
	}

	render() {
		if (debug) console.log("HeaderPortrait render()");
		return (<div
			id="header-portrait"
			// style={{ backgroundImage: ProfileService.me.portrait.urlCss }}
			onClick={(e) => this.clicked(e)}
		></div>);		
	}

	setPortrait({ profile }) {
		if (debug) console.log("HeaderPortrait setPortrait( {...} )")
		if (debug) console.dir(profile);
		var header = util.$('header-portrait');
		if (!header) {
			console.warn("No header portrait to set");
			return;
		}
		header.style.backgroundImage = profile.portrait.urlCss;
		this.setPortraitSize( portraitSize );
	}

	setPortraitSize(x, y) {
		if (!y) y = x;
		const p = util.$('header-portrait');
		if (!p) return;
		p.style.height = x+"px";
		p.style.width = y+"px";
	}

	componentDidMount() {
		window.addEventListener("myProfileUpdate", (e) => {
			if (debug) console.log("responding to myProfileUpdate");
			console.dir(e.detail);
			this.setPortrait({ profile: e.detail });
		});
	}
}


class MenuButton extends React.Component
{
	handleClick() {
		if (debug) console.log("MenuButton handleClick()");
		util.dispatch('toggleMenu');
	}

	render() {
		return (<div
			id="menuButtonHeader"
			className="button-icon menu tint-ui icon-menu"
			onClick={(e) => this.handleClick(e)}
		></div>);		
	}
}

export class PageHeader extends React.Component
{
	clickLogo() {
		app.goPage('feed');
	}

	render() {
		var backButton;
		var myStyle;
		this.title = this.props.title || "";
		this.info = this.props.info || "";
		if (this.props.back) {
			backButton = (
				<BackButton onBack={this.props.onBack} />);
		}

		return (
			<div>
				<Menu />
				<Confirmation />
				<div
					className="page-header"
					style={myStyle}
				>
					<div />
					{backButton}
					<div
						className="header-logo tint-ui"
						onClick={(e) => {this.clickLogo()}}
					></div>
					<MenuButton />
					<div />
				</div>
			</div>
		);
	}
}

