import config from './AppConfig'
import Service from './Service'
import util from './AppUtil'
import app from './app'

var debug = true;

//TagService.getShares({ limit, offset, tag });

class TagService
{
	static getShares({ limit, offset, tag }) {
		if (debug) {
			console.log("getShares( "+limit+", "+offset+", "+tag+" )");
		}
		if (!offset) offset = 0;
		if (!limit) limit = 10;
		//if (!tag) tag = '';
		if (!tag) tag = 'mygonzo';

		return Service.fpa('tag/sg', {
			limit, offset, tag
		})
		.then( (res) => {
			if (debug) console.log("getShares server response:");
			if (debug) console.dir(res);
			return res;
		})
		.catch( (e) => {
			console.error("Problem loading tag data from server.");
			return Promise.reject(e);
		})
	}

	static getSharesOld({ limit, offset, tag }) {
		if (debug) {
			console.log("getSharesOld( "+limit+", "+offset+", "+tag+" )");
			console.log(" from " + config.service.tag.host);
		}
		if (!offset) offset = 0;
		if (!limit) limit = 10;
		//if (!tag) tag = '';
		if (!tag) tag = 'mygonzo';

		return Service.fetchGet(config.service.tag,
			('s/' + limit + '/' + offset +'/' + tag)
		)
		.then( (res) => {
			if (debug) console.log("getShares server response:");
			if (debug) console.dir(res);
			return res.json()
			.then( (obj) => {
				if (debug) console.dir(obj);
				return obj;
			})
		})
		.catch( (e) => {
			console.error("Problem loading tag data from server.");
			return Promise.reject(e);
		})
	}
}

export default TagService;

