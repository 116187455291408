import ProfileService from './ProfileService'
import LocalStore from './LocalStore'
import Service from './Service'
import util from './AppUtil'
import User from './User'
import app from './app'
import uu from './uu'

var debug = true;

class UserService
{
	static addAuth(obj) {
		if (!obj) obj = {};
		if (UserService.user) {
			obj.auth = {
				uid: UserService.user.uid,
				session_id: UserService.user.sessionId,
				session: UserService.user.session,
				device_id: UserService.deviceIdentifier
			}
		}
		return obj;
	}

	static autoLogin () {
		if (debug) console.log("autoLogin( )");
		//if (!UserService.user) UserService.user = new User();
		//var user = UserService.user;
		const uid = LocalStore.getItem('uid');
		console.log("uid: "+uid);
		var data = LocalStore.getUser( uid );
		UserService.user = new User( data );
		var user = UserService.user;

		if (user.autoLoginToken) {
			const auto_login_token = user.autoLoginToken;
			const session = user.sessionToken;
			const session_id = user.sessionId;
			if (auto_login_token && session_id && session) {
				const data = {
					uid,
					auto_login_token,
					session_id,
					session,
				}
				return UserService.login(data)
			}
		} else {
			console.warn(" insufficient local data for auto login.");
			console.dir(user)
			return Promise.reject("insufficient local data");
		}
	}

	static login(data) {
		if (debug) console.log("login( {...} )");
		if (debug) console.dir(data);
		data.device_id = UserService.deviceIdentifier;
		return Service.fpa("user/login", data)
		.then( (res) => {
			if (res.error) {
				if (debug) console.log("login error");
				if (debug) console.dir(res.error);
				return Promise.reject(res.error);
			}
			UserService.onSuccessfulLogin(res);
			return Promise.resolve(res);
		})
	}

	static showServiceDownError() {
		app.showError("User service is unreachable.");
	}

	static load3( user ) {
		var data = { uid: user.uid };
		return Service.fpa('user/info', data)
		.then( (res) => {
			if (debug) console.log("got user data from server:");
			if (debug) console.log();
			return Promise.resolve(res);
		})
	}

	static create (data) {
		if (debug) console.log("create( {...} )");
		if (debug) console.dir(data);
		data.device_id = UserService.deviceIdentifier;
		return Service.fp('user/create', data)
		.then( (res) => {
			console.log("got response for user/create:");
			console.dir(res);
			if (res.error) {
				console.log("error in create:");
				console.dir(res);
				return Promise.reject(res.error);
			} else {
				UserService.user = null;
				console.log("wait for server to do stuff...");
				util.wait(250)
				.then( () => {
					ProfileService.loadMine({ uid: res.uid })
					.then( () => {
						LocalStore.setItem('username', data.username);
						UserService.onSuccessfulLogin(res);
						return Promise.resolve(res);
					})
					.catch( (err) => {
						console.error("load profile fail for new account");
						console.log(err);
						return Promise.reject(err);
					})
				})
			}
		})
	}

	static onSuccessfulLogin(res) {
		if (debug) console.log("onSuccessfulLogin( {...} )");
		if (debug) console.dir(res);

		LocalStore.setItem('uid', res.uid);
		
		if (UserService.user) {
			console.log('update user with server response');
			UserService.user.login(res);
		} else {
			console.log('create a new user object');
			UserService.user = new User(res);
		}

		LocalStore.saveLoginInfo(res.uid, res);
		UserService.loginSuccess = true;
		//UserService.user.onLoginSuccess(res);
		const saveData = UserService.user.localData;
		console.log("saving local user data:");
		console.dir(saveData);
		UserService.saveLocal(UserService.user.localData);

		util.dispatch('loginSuccess', res);
	}

	static saveLocal (data) {
		if (!data) return;
		data = uu.parse(data);
		if (debug) console.log("saveLocal( {...} )")
		if (debug) console.dir(data);

		// remove any server messages
		if (data.message) delete data.message;
		if (data.success) delete data.success;
		if (data.error) delete data.error;
		if (data.auth) delete data.auth;

		LocalStore.setUser(data);
	}

	static userLevel() {
		if (UserService.user) return UserService.user.userLevel;
		return 0;
	}

	static get uid() {
		var uid = LocalStore.getItem('uid');
		if (!uid) uid = -1;
		return uid;
	}

	static get username() {
		console.log("get username");
		var name = LocalStore.getItem('username');
		if (name) return name;

		const uid = LocalStore.getItem('uid');
		var login = LocalStore.getItem('login_'+uid);
		if (!login) return "??";
		login = uu.parse(login);
		if (login.username) return login.username;
		return "?";
	}

	static switchUser(uid) {
		console.warn("switchUser( "+uid+" )");
		const loginInfo = uu.parse(
			LocalStore.getItem('login_'+uid)
		);
		if (!loginInfo) {
			console.error("no login info for "+uid);
			return;
		} else {
			console.dir(loginInfo)
		}
		LocalStore.setItem('uid', uid);
		LocalStore.setItem('theme', loginInfo.theme);
		LocalStore.removeItem('username');
		UserService.user = null;
		ProfileService.switchUser();
		app.reloadApp();
	}
}

export default UserService;