import { generate } from 'build-number-generator'
import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import UserService from '../UserService'
import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import config from '../AppConfig'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import Service from '../Service'
import util from '../AppUtil'
import User from '../User'
import app from '../app'

import './Debug.css'

class DebugPage extends Component
{

	clickSwitchUser() {
		console.log('clickSwitchUser()');
		alert('switch user coming soon')
	}

	clickCreate() {
		console.log('clickCreate()');
		app.goPage("join");
	}

	clickClear() {
		console.log('clickClear()');
		const detail = {
			textYes: "It's fine",
			textNo:"No, I don't",
			message: "You do not want to do this.",
			icon: 'trash',
			actionYes: ( () => { LocalStore.deleteEverything() } ),
			/* actionNo: ( () => { this.doNot() } ) */
		}
		const evt = new CustomEvent('showConfirmation', {detail});
		window.dispatchEvent( evt );
	}

	clickLogin() {
		console.log('clickLogin()');
		app.goPage('login');
	}

	clickTheme() {
		console.log('clickTheme()');
		app.toggleTheme();
	}

	clickFullScreen() {
		//document.body.requestFullscreen();
		app.toggleFullScreen();
	}

	clickHomePage() {
		//document.body.requestFullscreen();
		app.goPage("home");
	}

	clickedLSDump() {
		//document.body.requestFullscreen();
		//LocalStore.printAll();
		var s = LocalStore.getAllAsString();
		util.$('localStoreDump').innerHTML = s;
	}

	jokePress() {
		if (!this.jokePresses) {
			this.jokePresses = 0;
			util.$("yousuck").innerHTML = "Antisocial personality disorder detected. Adjusting settings."
		}
		if (this.jokePresses++ === 6) {
			util.$("yousuck").innerHTML = "Attention deficit hyperactivity disorder detected. Adjusting settings."
		}
	}

	gotoWelcomeScreen() {
		console.log('gotoWelcomeScreen()');
		app.goPage("welcome");
	}

	render() {
		var pid = ProfileService.userPid;
		if (!pid) pid = '?';
		var uid = UserService.uid;
		if (!uid) uid = '?';
		var buildNumber = generate();
		var buildDisplay = "20";
		buildDisplay += buildNumber.substring(0,2) + "-";
		buildDisplay += buildNumber.substring(2,4) + "-";
		buildDisplay += buildNumber.substring(4,6) + "-";
		buildDisplay += buildNumber.substring(6);
		return (
			<div id='debugloader' className='app-page'>
				<div className='page-banner debug' />
				<div id="page-title">Are you supposed to be here?</div>
				<div className='content-container'>

					<div className='form-container'>

						<span> build: {buildDisplay} </span>

						<div> gateway: {config.service.gateway.host} </div>

						<div> uid: {uid} </div>

						<div> pid: {pid} </div>

						<button
							onClick={(e) => this.clickHomePage(e)}
							type="button"
							className="button long"
							id="buttonHomePage"
						>
							Home Page
						</button>


						<button
							onClick={(e) => {this.gotoWelcomeScreen()}}
							type="button"
							className="button long"
							id="buttonClear"
						>
							Welcome Screen
						</button>

						<button
							onClick={(e) => this.clickLogin(e)}
							type="button"
							className="button long"
							id="buttonLogin"
						>
							Login With Password
						</button>

						<button
							onClick={(e) => this.clickFullScreen(e)}
							type="button"
							className="button long"
							id="buttonTheme"
						>
							Toggle Full Screen
						</button>

						<button
							onClick={(e) => this.clickClear(e)}
							type="button"
							className="button long"
							id="buttonClear"
						>
							rm -r *
						</button>

						<button
							onClick={ (e) => { LocalStore.deleteProfiles() } }
							type="button"
							className="button long"
							id="buttonClear"
						>
							Kill Profile Cache
						</button>

						<button
							onClick={ (e) => { LocalStore.deleteMessages() } }
							type="button"
							className="button long"
							id="buttonClear"
						>
							Kill Message Cache
						</button>

						<button
							onClick={(e) => {this.jokePress()}}
							type="button"
							className="button long"
							id="buttonJoke"
						>
							Do Not Touch
						</button>

						<div id="yousuck" className="m16p info-text"></div>

						<Spacer height={24} />

					</div>
				</div>

				<div id="localStoreDump" className="m4p"></div>

				<Spacer height={102} />

			</div>
		)
	}

	componentDidMount() {
		this.clickedLSDump();
	}
}

var thisPage = new AppPage({
	name: "debugloader",
	component: (<DebugPage />)
});


export default thisPage;
