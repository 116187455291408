import AwesomeButton from './AwesomeButton'
import AwesomeInput from './AwesomeInput'
import FormCheckBox from './FormCheckBox'
import React, { Component } from 'react'
import UserService from '../UserService'
import language from '../Language'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import config from '../AppConfig'
import User from '../User'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import './Login.css'

var debug = true;

class LoginScreen extends Component
{
	change(e) {
		//console.log("change( {...} )");
		//console.log(e.target);
		this.clearError(e.target);
	}

	click() {}

	clickLogin() {
		console.log('clickLogin()');
		if (this.goButton.classList.contains('disabled')) {
			return false;
		}
		const data = {
			username: util.$("username-input").value,
			password: util.$("password-input").value,
			remember: util.$("remember-input").checked
		}
		var vali = util.validatePassword(data.password);
		if (vali.error) {
			this.showError(vali.error, util.$("password-input-container"));
			return false;
		} else {
			UserService.login(data)
			.then( (res) => {
				uu.wait(1200)
				.then( () => {
					//console.warn('page load disabled')
					app.loadFirstPage();
				})
			})
			.catch( (err) => {
				this.onLoginError(err);
			})
			return true;
		}
	}

	onLoginError(res) {
		if (debug) console.log("onLoginError( {...} )");
		if (debug) console.dir(res);
		var hilight = [];
		var errorString = "?";
		if (res) {
			if (typeof res === 'string') {
				errorString = res;
			} else if (res.error) {
				errorString = res.error;
			} else if (res.message) {
				errorString = res.message;
			} else if (res.sqlMessage) {
				errorString = res.sqlMessage;
			}
			if (errorString == language.error.server) {
				errorString = language.login.failedLogin;
				hilight.push(util.$("password-input-container"));
				hilight.push(util.$("username-input-container"));
			}
			login.showError(errorString, hilight);
		}
	}

	showError(s, elements) {
		this.clearError();
		if (debug) console.log("showError( "+s+" )");
		if (debug) console.dir(elements);
		util.$("errorMessage").innerText = s;
		if (elements) {
			if (!Array.isArray(elements)) elements = [elements];
			elements.forEach(function(e) {
				e.classList.add('error');
			});
		}
		this.goButton.classList.add('disabled');
	}

	clearError(element) {
		var elements = [];
		if (element) {
			elements.push(element);
		} else {
			elements = this.inputs;
		}
		util.$("errorMessage").innerText = "\u00a0\n";
		for (var prop in elements) {
			elements[prop].classList.remove('error');
		}
		if (this.goButton) {
			this.goButton.classList.remove('disabled');
		}
	}

	clickJoin() {
		if (debug) console.log('clickJoin()');
		//join.populateNameField( util.$("username-input").value );
		app.goPage("join");
	}

	clickForgot(e) {
		if (debug) console.log("clickForgot() ... does nothing.");
		app.goPage('forgot');
	}

	newUserProcessResult(res) {
		if (debug) console.warn("newUserProcessResult() NONOP");
		if (debug) console.log(res);
	}

	render() {
		return (
			<div
				id={login.name}
				className="app-page"
			>
				<div className='page-banner login' />
				<div id="page-title">Enter Credentials </div>
				<div className="content-container ">
					<div
						className='form-container'
						onClick={(e) => this.click(e)}
					>

						<AwesomeInput
							name='username'
							text='user name'
							value={UserService.username}
						/>

						<AwesomeInput
							type='password'
							name='password'
							text='password'
						/>
						
						<FormCheckBox
							name='remember'
							text='Disable manual login'
						/>

						<span
							id="errorMessage"
							className="error-string"
						>&nbsp;</span>

						<AwesomeButton
							name='login'
							text='Login'
							clickAction={ this.clickLogin.bind(this) }
						/>

						<div
							className='forgot-password'
							onClick={(e) => this.clickForgot(e)}
							id="forgot"
						>
							{language.login.forgot}
						</div>
						
					</div>
				</div>

				<Spacer height={12} />
				<div className='form-container'>
					new here?
					<button
						onClick={(e) => this.clickJoin(e)}
						type="button"
						className="button long"
						id="buttonJoin"
					>
						Create Account
					</button>
					<button
						onClick={(e) => app.goPage('feed')}
						type="button"
						className="button long"
						id="buttonSkip"
					>
						Lurk
					</button>
				</div>
				<Spacer height={50}/>
			</div>
		);
	}

	componentDidMount() {
		if (debug) console.log("populate username");
		this.inputs = {
			name: util.$('username-input'),
			pw: util.$('password-input')
		};
		if (UserService.username !== '') {
			this.inputs.name.value = UserService.username;
		}

		this.goButton = util.$('login-button');
		login.comp = this;
	}
}

var login = new AppPage({
	name: "login",
	component: (<LoginScreen />)
});

login.showError = function(errorString, hilight) {
	this.comp.showError(errorString, hilight);
}

export default login;

