
// import LocalStore from './LocalStore'
// import config from './AppConfig'
// import util from './AppUtil'


var debug = true;

class Message
{

	//set id(i) { this.id = i }
	get saved() { return this.id }
	wasSent() { this.sent = true }
	sendError(s) { this._sendError = s }
//	wasRead() { this.read = true; }
	//wasSaved() { this._saved = true }
	//wasReceived() { this._received = true }
	//wasRead() { this._read = true }
	
	
	//get userName() { return this.data.username }

	constructor({to, from, text, id, tid, created_at}) {
		this.to = to;
		this.from = from;
		this.text = text;
		this.id = id;
		this.tid = tid;
		this.created_at = created_at;
	}

	postData() {
		return({
			from: this.from,
			to: this.to,
			text: this.text,
			tid: this.tid
		})
	}
}

export default Message;