import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import UserPortrait from './UserPortrait'
import ChatService from '../ChatService'
import React, { Component } from 'react'
import { Spacer } from './Common'
import ReactDOM from 'react-dom'
import Profile from '../Profile'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'

import './Conversation.css'
import './Inbox.css'

var debug = true;

class ConversationListItem extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new ConversationListItem. props:");
		if (debug) console.dir(this.props)
		this.myDiv = React.createRef();
		this.convo = this.props.convo;
		this.profile = this.props.convo.profile;
	}

	clickConversation() {
		console.log("clickConversation()");
		app.goPage('conversation', this.convo );
		// hilight it
		const elm = this.myDiv.current;
		util.addClass( elm, 'selected' );
	}

	render() {
		const numUnread = this.convo.unread;
		var unreads = (<div></div>);
		if (numUnread > 0) {
			unreads = (<div className='alert-label unreads-display'>{numUnread}</div>);
		}

		var profileName = this.convo.pid;
		if (this.profile && this.profile.displayName) {
			profileName = this.profile.displayName;
		}

		return (
			<div
				ref={this.myDiv}
				className="conversation-list-item content-shadow"
				onClick={(e) => this.clickConversation(e)}
			>
				<UserPortrait
					profile={this.profile}
					addClass='inbox'
					clickAction={()=>{}}
				/>
				<div className='conversation-list-item-text' >
					<div className='cli-top'>
						<div className='conversation-list-item-name'>
							{profileName}
						</div>
						<div className='conversation-list-item-date'>
							Today
						</div>
					</div>
					<div className='cli-bottom'>
						<div className='conversation-list-item-snippet'>
							{this.convo.snip}
						</div>
						{unreads}
					</div>
				</div>
			</div>
		);		
	}
}

class InboxPage extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new InboxPage. thisPage.convos:");
		if (debug) console.dir(thisPage.convos)
		this.conversations = [];
		if (thisPage.convos.length === 0) {
			this.conversations.push( this.getNoConvoDisplay() );
		} else {
			thisPage.convos.forEach(convo => {
				if (convo.newest) { // don't list empty convos
					this.conversations.push(
						<ConversationListItem
							key={'cli-'+convo.id}
							convo={convo}
						/>
					);
				}
			})
		}
	}

	getNoConvoDisplay() {
		const elm = (
			<div id='no_convos' className='m8p'>
				<h2>No conversations, {ProfileService.me.pid} </h2>
				<p>Why not start one? You can begin a conversation with anybody from their profile page.</p>
			</div>
		);
		return elm;
	}

	render() {
		console.log("InboxPage render");
		return (
			<div id={thisPage.name} className='app-page' >
				<div id='page-title'>Private Conversations</div>
				<div id='conversation-list' >
					{this.conversations}
				</div>
				<Spacer height={100} />
			</div>
		)
	}
}

var thisPage = new AppPage({
	name: 'inbox',
	component: (<InboxPage />)
});

thisPage.initialize = function() {
	if (debug) console.log("inbox initialize() ");
	this.profiles = [];
	this.inbox = {};

	return ChatService.getInbox()
	.then ( (res) => {
		console.log("got the inbox request response: ");
		console.dir(res);
		this.inbox = res;
		// if (!res.convos || res.convos.length === 0) { // empty list
		// 	return Promise.resolve();
		// }
		return ChatService.getConversations()
		.then( (convos) => {
			console.log("got convos");
			console.dir(convos)
			this.convos = convos;

			if (!convos || convos.length === 0) {
				console.log("You don't have any conversations.");
				return Promise.resolve();
			}
			
			var pid = [];
			for (var i = 0; i < convos.length; i++) {
				pid.push(convos[i].you);
			}

			console.log("get profiles: "+pid);

			return ProfileService.getProfile({ pid })
			.then( (profiles) => {
				console.log("got profiles:");
				console.dir(profiles);
				if (!profiles) return Promise.reject("no profiles for convos");
				if (!Array.isArray(profiles)) profiles = [profiles];
				//this.profiles = profiles;
				for (var i = 0; i < convos.length; i++) {
					for (var j = 0; j < profiles.length; j++) {
						if (this.convos[i].you === profiles[j].id) {
							this.convos[i].profile = profiles[j];
							break;
						}
					}
				}
				return Promise.resolve();
			})
		})
	})
}

export default thisPage;

