import ShareService from '../ShareService'
import UserService from '../UserService'
import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import { FeedList } from './FeedList'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import Profile from '../Profile'
import util from '../AppUtil'
import User from '../User'
import app from '../app'

import './Welcome.css'

class WelcomePage extends Component
{
	clickedBegin (e) {
		console.log("clickedBegin()");
		if (!ProfileService.me) {
			console.warn("you didn't log in");
			app.goPage('debugloader');
		} else {
			app.loadFirstPage();
		}
	}

	clickedProfile (e) {
		console.log("clickedProfile()");
		app.goPage('profileEdit');
	}

	clickedPost (e) {
		console.log("clickedProfile()");
		app.goPage('shareEdit');
	}

	render() {
		var uname = LocalStore.getItem('username');
		//var uname = UserService.username;
		return (
			<div id={welcome.name} className='app-page'>
				<div className="page-banner welcome" />
				<div id='page-title'>Welcome {uname}!</div>
				<div className='content-container' >

					<div className='form-container'>
						
						But we can't stop here...

						<button
							onClick={(e) => this.clickedProfile(e)}
							type='button'
							className='button long'
							id='buttonProfile'
						>
							Edit My Profile
						</button>

							<button
							onClick={(e) => this.clickedPost(e)}
							type='button'
							className='button long'
							id='buttonPost'
						>
							Share a Photo
						</button>

					</div>
				</div>
				<div id='page-title'>Recent Activity</div>
				<FeedList sortType={'recent'} />
			</div>
		)
	}
}

var welcome = new AppPage({
	name: "welcome",
	component: (<WelcomePage />)
});
export default welcome;

