import { ProfilePortrait } from './ProfileInfoField'
import PictureService from './PictureService'
import UserService from './UserService'
import LocalStore from './LocalStore'
import config from './AppConfig'
import Service from './Service'
import Profile from './Profile'
import util from './AppUtil'
import User from './User'
import uu from './uu'

var debug = true;

const logg = function(s, o) { debug && console.log(s, o) }

class ProfileService {

	static align(pid) {
		return Service.fpa('profile/align', {
			you: ProfileService.me.pid,
			them: pid
		})
	}

	static unAlign(pid) {
		return ProfileService.align( pid * -1 );
	}

	static get userPid() {
		if (ProfileService.me) return ProfileService.me.pid;
		return 0;
	}

	static get pid() {
		return ProfileService.userPid;
	}

	static get userPortrait() {
		if (ProfileService.me) return ProfileService.me.portrait;
		return new ProfilePortrait({value: PictureService.nobody});
	}

	static loadMineFromCache() {
		logg("loadMineFromCache()");
		if (!ProfileService.me) {
			return Promise.reject('your profile not loaded');
		}
		return ProfileService.loadMine({ useCache: 'only' })
	}

	static editGroup(group) {
		return Service.fpa('profile/editgroup', {
			pid: ProfileService.me.pid, group })
	}

	static quitGroup(gid) {
		return Service.fpa('profile/quitgroup', {
			pid: ProfileService.me.pid, gid })
	}

	static kickGroup(gid, pid) {
		return Service.fpa('profile/kickgroup', {
			gid, pid })
	}

	static groups() {
		return Service.fpa('profile/groups', {
			pid: ProfileService.me.pid
		})
	}
	static getGroups() {return ProfileService.groups()}

	static loadMine({ uid, useCache }) {
		logg("loadMine( "+uid+", "+useCache+")");
		if (!uid) uid = UserService.uid;
		if (uid < 1) return Promise.reject("no uid");

		return ProfileService.getProfile({ uid, useCache })
		.then( (profile) => {
			if (profile && profile.error || !profile) {
				var error = "problem loading profile";
				return Promise.reject({error});
			}
			else {
				logg("got my profile: ", profile);
				if (!Profile.me) {
					LocalStore.saveLoginInfo(uid, profile.data);
				}
				ProfileService.me = profile;
				util.dispatch('myProfileUpdate', profile);
				return Promise.resolve(profile);
			}
		})
	}

	static updateMe (data) {
		logg("updateMe( {...} )", data);
		ProfileService.me.update(data);
		setTimeout(() => {
			ProfileService.loadMine({ uid: null, useCache: false })
		}, 1000);
		return ProfileService.updateMyInfo(data)
	}

	static updateMyInfo (data) {
		logg("updateMyInfo( {...} )", data);
		var profile = ProfileService.me;
		if (!profile) {
			const err = "cannot update my info. No me.";
			console.error( err );
			return Promise.reject( err );
		}

		var data = uu.parse(data);
		data.pid = profile.pid;

		return Service.fpa('profile/update', data)
		.then( (res) => {
			if (res.error) {
				Promise.reject(res.error);
			}
			ProfileService.saveToDevice(profile);
			LocalStore.saveLoginInfo(UserService.uid, data);
			return Promise.resolve(res);
		})
	}

	static getProfile ({pid, uid, useCache=true}) {
		logg("getProfile  pid: "+pid+", uid: "+uid+", useCache: "+useCache);
		logg("TODO: time out on cached profiles")
		if (!pid && !uid) {
			console.warn("No uid or pid supplied.");
			return Promise.resolve( new Profile() );
		}
		if (Array.isArray(pid)) {
			logg("TODO: Request multiple profiles never uses cache. Fix this later?");
			useCache = false;
		}

		var profile = null;

		if (pid && useCache) {

			const localData = LocalStore.getProfile(pid);
			if (localData) {
				profile = new Profile( localData );
				logg(" got local");
				if (profile.stale) {
					logg("  stale");
					return this.getProfile({ pid: profile.pid, useCache: false });
				} else {
					logg("  fresh");
					return Promise.resolve( profile );
				}
			} else {
				logg(" no local");
				if (useCache === "only") {
					var err = "inncorect use of getProfile. ";
					err += "only chache request - no local data";
					console.error(err);
					//return Promise.reject(err);
					// fail gracefully...
				}
			}
		}

		logg("   get from server");

		// make a waiting list to avoid sending multiple
		// identical querries to the server at once
		if (!ProfileService.waitingList) {
			ProfileService.waitingList = [];
		}
		if (Array.isArray(pid)) {
			logg("Request multiple profiles ignores waiting list");
		} else {
			if (pid) {
				if (ProfileService.waitingList.includes(pid)) {
					if (ProfileService.serviceConnected === false) {
						return Promise.reject('service down');
					}
					logg(pid+" on waiting list");
					return uu.wait(42)
					.then( () => {
						// TODO, avoid invinate attempts?
						// detect service down?
						if (ProfileService.serviceConnected) {
							return ProfileService.getProfile({pid})
						}
					})
				} else {
					// add it to the waiting list
					ProfileService.waitingList.push(pid);
				}
			}
		}

		return Service.fpa( 'profile/info', { pid, uid } )
		.then( (obj) => {
			if (obj.error) {
				return Promise.reject(obj.error);
			}
			if (Array.isArray(obj)) {
				var returnArray = [];
				for (var i = 0; i < obj.length; i++) {
					const newProfile = new Profile( obj[i] );
					ProfileService.saveToDevice( newProfile );
					returnArray.push(newProfile);
				}
				return Promise.resolve(returnArray);
			} else { // single profile
				// remove from waiting list
				const i = ProfileService.waitingList.indexOf(pid);
				if (i >= 0) {
					ProfileService.waitingList.splice(i, 1);
				}
				const newProfile = new Profile(obj);
				ProfileService.saveToDevice( newProfile );
				return Promise.resolve( newProfile );
			}
		})
	}

	static getProfiles({sort, offset, amount, search}) {
		logg("getProfiles( "+sort+", "+offset+" )");
		const data = UserService.addAuth({
			pid: '0',
			offset,
			sort,
			amount,
			search
		});
		return Service.fpa('profile/info', data)
		.then( (obj) => {
			logg("getPlayers response: ", obj);
			if (obj.error) {
				return Promise.reject(obj.error);
			}
			if (Array.isArray(obj)) {
				var returnArray = [];
				for (var i = 0; i < obj.length; i++) {
					const newProfile = new Profile( obj[i] );
					ProfileService.saveToDevice( newProfile );
					returnArray.push(newProfile);
				}
				return Promise.resolve(returnArray);
			} else {
				const newProfile = new Profile(obj);
				ProfileService.saveToDevice( newProfile );
				return Promise.resolve([newProfile]);
			}
		})
	}

	static setMyPortrait( image ) {
		logg("setMyPortrait( {} )");
		//if (!ProfileService.me) return;
		ProfileService.me.setMyPortrait( image );
		const data = { portrait: image };
		LocalStore.saveLoginInfo(UserService.uid, data);
		util.dispatch('myProfileUpdate', ProfileService.me);
	}

	static saveToDevice(profile) {
		logg("saveToDevice( {...} )", profile);
		profile.freshNow();
		ProfileService.saveLocalStore( profile );
	}

	static saveLocalStore (profile) {
		logg("saveLocal( {...} )", profile);
		if ( !profile.pid || profile.pid <= 0) {
			console.error("Can't save profile without valid PID");
			return false;
		}
		const data = profile.data;

		if (data.error) {
			console.error("Error in this data! Not saving locally!");
			return false;
		}

		// remove any server messages
		if (data.message) delete data.message;
		if (data.success) delete data.success;
		if (data.error) delete data.error;
		if (data.auth) delete data.auth;
		// TODO: more elegant solution for this. data headers perhaps

		const key = LocalStore.profileKey(data.id);
		try {
			window.localStorage.setItem(key, JSON.stringify(data));
		} catch (e) {
			console.error("can't save to local store");
			console.dir(e);
		}
	}

	static switchUser() {
		ProfileService.me = null;
	}

	static ping() {
		Service.ping(config.service.profile)
		.then( (res) => {
			ProfileService.serviceConnected = res;
		})
		util.wait(config.service.profile.ping)
		.then( () => {
			ProfileService.ping();
		})
	}

	static currentGroup() {
		return LocalStore.get(
			'current-group-'+ProfileService.me.id);
	}
	static setGroup(id) {
		LocalStore.set(
			'current-group-'+ProfileService.me.id, id);
	}
}

ProfileService.serviceConnected = true;
util.wait(5000)
.then( () => {
	ProfileService.ping()
})

ProfileService.me = new Profile({});

export default ProfileService;


