import ProfileService from '../ProfileService'
import PictureService from '../PictureService'
import React, { Component } from 'react'
import Profile from '../Profile'
import util from '../AppUtil'
import app from '../app'

var debug = false;

export class PortraitArray extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new PortraitArray. props:")
		if (debug) console.dir(props);
		this.portraits = [];
		props.pids.forEach( pid => {
			this.portraits.push(<UserPortrait
				key={'pa-'+props.gid+'-'+pid}
				pid={pid} addClass='array' />);
		})
		this.pStyle = { gridTemplateColumns:
			'repeat('+props.pids.length+', minmax(0px, 1fr)) 46px'}
	}

	click () {
		console.log("click() ? "+this.props.gid);
	}

	render() { return (<div
			className='portrait-array' 
			style={this.pStyle}
		>
			{this.portraits}
			<div />
		</div>)
	}
}

export default class UserPortrait extends Component
{
	constructor(props) {
		super(props);
		debug && console.log("new UserPortrait  props:", props);
		if (this.props.isMe) this.isMe = true;
		this.myPortrait = React.createRef();
		this.myContainer  = React.createRef();
		this.addClass = this.addTint = '';
		if (this.props.addClass) {
			this.addClass = this.props.addClass;
		}
		
		if (this.props.pid) {
			debug && console.log("make portrait with a pid");
			ProfileService.getProfile({ pid: this.props.pid })
			.then( (p) => this.gotProfile(p) )
			.catch( (e) => {
				console.warn("no profile "+this.props.pid);
			})
			return;
		}
		if (!this.props.profile) {
			debug && console.log("assume current user:");
			debug && console.dir(ProfileService.me)
			this.profile = ProfileService.me;
			if (debug) console.log("style it!");
			this.styleMe(this.profile.portrait);
		} else {
			if (this.props.profile instanceof Profile ) {
				if (debug) console.log("got profile");
				this.profile = this.props.profile;
				this.styleMe(this.profile.portrait);
			} else {
				console.error("UserPortrait param not profile instance");
			}
		}
	}

	portraitClick() {
		debug && console.log("portraitClick() pid:",this.props.pid);
		if (this.props.clickAction) {
			console.log("custom action");
			return this.props.clickAction();
		}
		if (this.props.pid) {
			app.loadProfilePagePID(this.props.pid);
		}
		if (this.props.profile) {
			app.loadProfilePagePID(this.props.profile.id);
		}
	}

	gotProfile(profile) {
		if (debug) console.log("gotProfile( {...} )", profile);
		if (!profile) {
			console.warn("invalid profile");
			return;
		}
		this.profile = profile;
		this.styleMe(profile.portrait);
	}

	gotoProfile() {
		app.loadProfilePagePID( this.profile.pid );	
	}

	render() {
		this.rendered = true;
		return (
			<div
				ref={this.myContainer}
				className={'user-portrait-container '+ this.addClass}
				>
				<div
					ref={this.myPortrait}
					className={'user-portrait ' + this.addTint }
					ref={this.myPortrait}
					style={this.portriatStyle}
					onClick={(e) => this.portraitClick(e)}
				></div>
			</div>
		)
	}

	styleMe(portrait) {
		if (debug) console.log("styleMe( {...} )");
		if (debug) console.dir(portrait)
		if (!portrait) return;
		const elm = this.myPortrait.current;
		const bg = PictureService.css(portrait.value);
		if (elm) elm.style.backgroundImage = bg;
		this.portriatStyle = {backgroundImage: bg};
		if (portrait.svg) {
			if (debug) console.log(' style portrait svg');
			this.addTint = ' svg tint-main-color';
			util.addClass( elm, 'tint-main-color' );
			util.addClass( elm, 'svg' );
			util.addClass( this.myContainer.current, 'svg');
			util.rmClass( elm, 'tint-photo');
		} else {
			if (debug) console.log(" style portrait photo");
			this.addTint = ' tint-photo';
			util.rmClass( elm, 'tint-main-color' );
			util.rmClass( elm, 'svg' );
			util.rmClass( this.myContainer.current, 'svg');
			util.addClass( elm, 'tint-photo');
		}
	}

	handleProfileUpdate(e) {
		if (this.isMe) {
			if (debug) console.log("handleProfileUpdate()  event.detail:");
			if (debug) console.dir(e.detail);
			var profile = e.detail;
			this.styleMe(profile.portrait);
		} else {
			if (debug) console.log("it's not your portriat");
		}
	}

	componentDidMount () {
		window.addEventListener("myProfileUpdate", this.handleProfileUpdate.bind(this) );
	}

	componentWillUnmount() {
		window.removeEventListener("myProfileUpdate", this.handleProfileUpdate.bind(this) );
	}

}