import React, { Component } from 'react'
import util from '../AppUtil'
import uu from '../uu'

import './Confirmation.css'

var debug = false;

export default class Confirmation extends Component
{
	constructor(props) {
		super(props);
		this.myDiv = React.createRef();
		if (debug) console.log("new Confirmation");
	}

	no() {
		this.close();
		if (debug) console.log("no()");
		if (debug) console.dir(this.actionNo)
		if (this.actionNo) {
			this.actionNo();
		}
	}

	yes() {
		this.close();
		if (debug) console.log("yes()");
		if (debug) console.dir(this.actionYes)
		if (this.actionYes) {
			this.actionYes();
		}
	}

	show() {
		//const div = this.myDiv.current;
		this.myDiv.current.style.display = 'block';
		uu.wait(100)
		.then( () => {
			util.rmClass( this.myDiv.current, 'hidden' );
		})
	}

	close() {
		if (debug) console.log("close()");
		//const div = this.myDiv.current;
		util.addClass( this.myDiv.current, 'hidden' );
		uu.wait(500)
		.then( () => {
			this.myDiv.current.style.display = 'none';
		})
	}

	closeOnPageClick() {
		if (!this.myDiv.current.classList.contains('hidden')) {
			this.close();
		}
	}

	render() {
		return (
			<div
				ref={this.myDiv}
				id='confirmation-container'
				className='confirmation-container hidden'
			>
				<div
					id='confirmation-dialog'
					className='confirmation-dialog top-hidden'
					style={this.portriatStyle}
				>
					<div id='confirmation-top'>
						<div
							id='confirmation-icon'
							className='tint-ui confirmation-icon background-icon icon-question'
						/>
						<div id='confirmation-title' >Are you certain?</div>
					</div>
					<div id='confirmation-message'></div>
					<div id='confirmation-buttons'>
						<button
							id='confirmation-affirm-yes'
							className='button confirm left'
							onClick={(e) => this.yes(e)}
						>
							Ok
						</button>
						<button
							id='confirmation-affirm-no'
							className='button confirm right'
							onClick={(e) => this.no(e)}
						>
							Cancel
						</button>
					</div>
			</div>
		</div>
		)
	}

	componentDidMount() {
		window.addEventListener('pageChange', (e) => {
			this.close();
		});

		window.addEventListener('clickMain', (e) => {
			if (debug) console.log('respond to clickMain');
			this.closeOnPageClick();
		});

		window.addEventListener('showConfirmation', (e) => {
			if (debug) console.log("Confirmation responding to showConfirmation");
			let o = e.detail;
			if (debug) console.dir(o);
			this.defaultIcon = 'question';
			this.currentIcon = this.defaultIcon;
			this.show();
			if (o.textYes) util.$('confirmation-affirm-yes').innerText = o.textYes;
			if (o.textNo) util.$('confirmation-affirm-no').innerText = o.textNo;
			if (o.title) util.$('confirmation-title').innerText = o.title;
			if (o.message) util.$('confirmation-message').innerText = o.message;
			if (e.detail.icon) {
				util.rmClass( util.$('confirmation-icon'), ('icon-'+this.currentIcon) );
				util.addClass( util.$('confirmation-icon'), ('icon-'+e.detail.icon) );
			} else {
				util.rmClass( util.$('confirmation-icon'), ('icon-'+this.currentIcon) );
				util.addClass( util.$('confirmation-icon'), ('icon-'+defaultIcon) );
			}
			this.actionYes = e.detail.actionYes;
			this.actionNo = e.detail.actionNo;
		});
	}
} // Confirmation
// dispatch("showConfirmation", {detail:{textYes:'YAS',textNo:'Naw'}})
