
var debug = false;

var maxLength = 99;
var minLength = 3;

class Tag
{
	static config( config ) {
		maxLength = config.tag.maxLength;
		minLength = config.tag.minLength
	}

	static cleanString(t) {
		return JSON.stringify(Tag.makeSaveable(t));
	}

	static makeSaveable(tags) {
		if (debug) console.log("makeSaveable( "+tags+" )");
		if (typeof tags === 'string') {
			tags = Tag.parseTags(tags);
		}
		return Tag.cleanTags(tags);
	}

	// convert any user typed list or JSON array into simple array
	static parseTags(rawstring) {
		if (debug) console.log("parseTags( "+rawstring+" )");
		if (!rawstring) return [];
		if (Array.isArray(rawstring)) return rawstring;
		if (typeof rawstring !== 'string') {
			console.error("not a string")
			return [];
		}
 		try { // maybe it is valid json?
			var parsed = JSON.parse(rawstring);
			return parsed;
		} catch (e) {}
		// split on commas and spaces
		return rawstring.trim().split(/[\s,]+/);
	}

	// remove duplicates & too short, make lower case, truncate
	// todo: remove some special characters
	static cleanTags(tags) {
		var r = [];
		if (!tags || !Array.isArray(tags)) return r;
		tags.forEach( (s) => {
			if (typeof s === 'string') {
				s = s.replace(/[|&;$%@"<>()+,.]/g, '');
				if (s.length >= minLength) {
					s = s.toLowerCase();
					if (s.length > maxLength) {
						s = s.substring(0, maxLength);
						console.warn("truncate long tag ");
					}
					if (!r.includes(s)) {
						console.log("accept tag '"+s+"'");
						r.push(s);
					}
				}
			}
		})
		return r;
	}

	static readable(tags) {
		var s = '';
		if (!tags || !Array.isArray(tags)) return s;
		s = tags.join(', ');
		return s;
	}
}

export default Tag;