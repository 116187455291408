import React, { Component } from 'react'
import config from '../AppConfig'
import ReactDOM from 'react-dom'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import './EndlessList.css'

var debug = false;

export class LoadingDisplay extends Component
{
	render() {return (
		<div id='el-loading-display' className='info-text' >
			<div
				id='loading-display-spinner'
				className='spinner el'
			></div>
		</div>
	)}
}

class EndlessListGroup extends Component
{
	render() {
		if (debug) console.log("render EndlessListGroup. props:");
		if (debug) console.dir(this.props);

		const data = this.props.data;
		var listItems = [];

		if (data) {
			const TagName = this.props.componentType;
			for (var i = 0; i < data.length; i++) {
				listItems.push(
					<TagName
						key={data[i].id}
						data={data[i]}
						addClass='way-down'
					/>
				);
			}
		} else if (debug) {
			console.log("no data to display at render time");
		}

		return (
			<div className='endless-list-group'>
				{listItems}
			</div>
		);
	}

	componentDidMount() {
		const list = document.getElementsByClassName('endless-list-item');
		// animate up
		// uu.wait(1)
		// .then( () => {
			for (let item of list) {
				util.rmClass( item, 'way-down' )
			}
		// })
	}
};


export class EndlessList extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new EndlessList. props:");
		if (debug) console.dir(props);
		this.autoLoadOnScroll = true;

		this.componentType = this.props.componentType;

		if (!this.props.options) {
			console.warn('EndlessList no options')
			this.querryOptions = {}
		} else {
			this.querryOptions = this.props.options;
		}

		this.querryOptions.offset = -1;

		if (debug) console.log("querryOptions:");
		if (debug) console.dir(this.querryOptions)

		if (!this.props.data || this.props.data.length === 0) {
			if (debug) console.log("No initial data sent to Endless List");
		} else {
			console.log("got initial data");
			console.dir(this.props.data)
		}
	}

	useComponent(c) {
		//console.log("!!! useComponent( "+c+" )");
		this.componentType = c;
	}

	stopTicking() {
		if (debug) console.log("stopTicking()");
		this.keepTicking = false;
		//window.removeEventListener('pageChange', () => { this.cleanUp() }, false);
	}

	startTick() {
		if (debug) console.log("startTick()");
		if (this.keepTicking) {
			if (debug) console.log(" already ticking")
			return;
		}
		this.keepTicking = true;
		this.waitThenTick();
	}

	waitThenTick() {
		uu.wait( config.poll.feed )
		.then(() => { this.tick() })
	}

	tick() {
		if (!this.keepTicking) return;
		if (!this.mounted) {
			console.warn("can't tick, not mounted");
			return this.waitThenTick();
		}
		if (debug) console.log("tick " + this.autoLoadOnScroll);
		if (this.autoLoadOnScroll && app.loadPoint()) {
			if (debug) console.log("load more because scroll");
			this.loadDataAndDisplay();
		}
		this.waitThenTick();
	}

	showLoading(b) {
		if (debug) console.log("showLoading( "+b+" )");
		var elm = util.$('loading-display-spinner');
		if (elm) {
			elm.style.display = (b) ? 'block' : 'none';
			//elm.style.opacity = (b) ? 1 : 0;
		}
		if (b) {
			elm = util.$('el-loading-display');
			if (elm) {
				util.rmClass( elm, 'button-icon');
				util.rmClass( elm, 'icon-onebar');
				util.rmClass( elm, 'end-of-list');
				util.rmClass( elm, 'tint-main-color');
			}
		}
	}

	loadDataAndDisplay() {
		if (debug) console.log("loadDataAndDisplay()");

		if (this.waitingForData) {
			console.warn("Cannot load, waiting.");
			return;
		}

		this.showLoading(true);

		const currentDiv = util.$('endless-list-container');
		if (!currentDiv) {
			console.warn("cannot show loading display");
		} else {
			let newDiv = document.createElement('div');
			currentDiv.appendChild( newDiv );
		}

		this.waitingForData = true;
		this.querryOptions.offset++;

		this.props.dataFetcher( this.querryOptions )
		.then( (data) => {
			if (debug) console.log("got server resopnse");
			this.waitingForData = false;

			if (this.ignoreNextResponse) {
				console.log(" ignoring server response");
				this.ignoreNextResponse = false;
				return;
			}

			if (debug) console.log(" data:");
			if (debug) console.dir(data);

			if (!data) {
				console.warn("no data to display")
				data = [];
			}

			if (data.length === 0) {
				return this.theEnd();
			} else {
				this.data = data;
			}

			if (data.error) {
				this.autoLoadOnScroll = false;
				if (data.error) {
					console.error(data.error);
					this.theEnd();
					return data;
				}
				util.error( data.error );
			}

			this.renderMore(data);
		})
	}

	theEnd() {
		if (debug) console.dir("theEnd()");
		this.keepTicking = false;
		this.showLoading(false);
		if (!this.data || (this.data && this.data.length === 0)) {
			if (debug) console.log("end of list with no data");
			util.dispatch('showEmptyListDisplay');
		} else {
			//if (debug) console.log("dispatch showEndOfListDisplay");
			//util.dispatch('showEndOfListDisplay');
			const elm = util.$('el-loading-display');
			if (elm) {
				util.addClass( elm, 'button-icon');
				util.addClass( elm, 'icon-onebar');
				util.addClass( elm, 'end-of-list');
				util.addClass( elm, 'tint-main-color');
			}
		}
	}

	renderMore(data) {
		if (debug) console.log("renderMore( {...} )");
		if (debug) console.dir(data);

		//this.showLoading(false);
		var currentDiv = document.getElementById('endless-list-container'); 
		if (!currentDiv) {
			console.warn("Cannot render more. no container.");
			// assume fast app page switch caused this and...
			return this.stopTicking();
		}
		var newDiv = document.createElement("div");
		currentDiv.appendChild( newDiv );
		ReactDOM.render(
			<EndlessListGroup
				data={data}
				componentType={this.componentType}
			/>, newDiv
		);
	}

	render() {
		if (debug) console.log("EndlessList render()");
		return (
			<div className='clearfix w100p' >
				<div
					id='endless-list-container'
					className='endless-list-container clearfix'
				>
					<EndlessListGroup
						data={this.props.data}
						componentType={this.props.componentType}
					/>
				</div>
				<LoadingDisplay />
			</div>
		)
	}

	componentDidMount()  {
		if (debug) console.log("mounted");
		this.mounted = true;
		if (this.props.data) {
			if (debug) console.log(" componentDidMount with data:");
			if (debug) console.dir(this.props.data)
			if (!this.props.data.length) {
				console.log("zero data length")
				this.theEnd();
			} else {
				this.showLoading(false);
				//this.querryOptions.offset = 0;
				this.startTick();
			}
		} else {
			if (debug) console.log("componentDidMount without data.");
			this.startTick();
		}
		
		// holla back to the host page
		if (this.props.hostPage) {
			this.props.hostPage.endlessListMounted(this);
		}

		window.addEventListener('pageChange', this.stopTicking.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener('pageChange', this.stopTicking);
	}

	reset(restart) {
		if (debug) console.log("reset( "+restart+" )");
		if (this.waitingForData) {
			if (debug) console.log(" reset now? but I'm waiting for data...");
			this.ignoreNextResponse = true;
		}
		var node = util.$('.endless-list-container');
		if (node) {
			while (node.hasChildNodes()) {
	    	node.removeChild(node.lastChild);
			}
		} else {
			console.warn("no endless-list-container to remove children from")
		}
		this.autoLoadOnScroll = true;
		this.querryOptions.offset = -1;
		if (restart) this.startTick();
	}
}

