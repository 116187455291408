import ProfileService from '../ProfileService'
import React, { Component } from 'react'
import Message from '../Message'
import util from '../AppUtil'

import './Conversation.css'

var debug = false;

export default class MessageDisplay extends Component
{
	click() {
		if (debug) console.log("TODO: Message click()");
	}

	render() {
		var message = this.props.message;
		var convo = this.props.convo;
		if (!(message instanceof Message)) {
			console.error("not a message object");
		}
		const pid = message.from;
		//if (debug) console.log("render a message bubble");
		//if (debug) console.dir(this.props.message)
		var side = (pid === ProfileService.pid) ? 'right':'left';
		if (pid === -1) side = 'center';

		var nub1 = (<div />);
		var nub2 = (<div />);
		var arrow = (<div className={'message-arrow '+side} />);
		if (side === 'right') nub2 = arrow;
		else if (side === 'left') nub1 = arrow;

		var author = '-';
		var authorClass = ' hidden';
		// author = '';
		// if (side === 'center')  author = "";
		if (debug) {
			author = message.id;
			authorClass = '';
		}

		var timePosted = '';
		var date;
		if (side !== 'center') {
			if (message.created_at) {
				date = new Date(message.created_at);
			} else {
				date = new Date();
			}
			timePosted = util.displayTime(date);
		}

		var addClass = '';
		var saved = '';
		if (convo) {
			if (convo.read >= message.id) {
				addClass = ' checked';
			} else if (message.saved) {
				saved = ' checked';
			}
		} else {
			saved = ' checked';
		}
		var checks = (<div />);
		if (side === 'right') {
			checks = [
				(<img src='/img/icon/check.svg'
					id={message.tid+'-check2'}
					key={message.tid+'-check2'}
					className={'message-check read tint-ui'+addClass}
				/>),

				(<img src='/img/icon/check.svg'
					id={message.tid+'-check1'}
					key={message.tid+'-check1'}
					className={'message-check saved tint-ui'+addClass+' '+saved}
				/>)
			]
		}

		return (
			<div id='ChatMessgeContainer'
				className={'message-container '+side}
			>
				<div />
				{nub1}
				<div id='ChatMessge'
					className={'message '+side}
					onClick={(e) => this.click(e)}
				>
					<div
						id={message.tid+'-top'}
						className={'comment-name '+side + authorClass}
					>
						{author}
					</div>
					{message.text}
					<div className={'comment-age '+side}>
						{timePosted}{checks}
					</div>
				</div>
				{nub2}
				<div />
			</div>
		)
	}
}


