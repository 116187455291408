import React, { Component } from 'react'
import UserService from '../UserService'
import config from '../AppConfig'
import AppPage from '../AppPage'
import app from '../app'

import './Settings.css'

class SettingsListItem extends Component
{
	click() {
		console.log("SettingsListItem handleClick()");
		if (this.props.target) {
			app.goPage(this.props.target);
		}
		if (this.props.action) {
			this.props.action();
		}
	}

	render() {
		return (
			<div
				id="SettingsListItem"
				className="settings-list-item"
				onClick={(e) => this.click(e)}
			>
				<div className="button-icon  tint-light icon-gear" />
				<div className="settings-list-item-text">
					{this.props.text}
				</div>
			</div>
		);		
	}
}


class SettingsPage extends Component
{
	render() {
		var i = 0;
		var items = [];
		items.push(<SettingsListItem
			key={i++}
			text={"Change Theme"}
			action={(e) => app.goPage('theme')}
		/>);
		items.push(<SettingsListItem
			key={i++}
			text={"Toggle Full Screen"}
			action={(e) => app.toggleFullScreen()}
		/>);
		items.push(<SettingsListItem
			key={i++}
			text={'"Home Page"'}
			action={(e) => {app.goPage('home')}}
		/>);
		// items.push(<SettingsListItem
		// 	key={i++}
		// 	text={"Password and Security"}
		// 	action={(e) => {alert("Comming soon.")}}
		// />);
		// items.push(<SettingsListItem
		// 	key={i++}
		// 	text={"Notifications"}
		// 	action={(e) => {alert("Comming soon.")}}
		// />);
		items.push(<SettingsListItem
			key={i++}
			text={"Privacy Policy"}
			target={"pp"}
		/>);
		items.push(<SettingsListItem
			key={i++}
			text={"Terms of Service"}
			target={"tos"}
		/>);

		items.push(<SettingsListItem
			key={i++}
			text={"User Information"}
			action={(e) => {app.goPage('userInfo')}}
		/>);
		//if (User.isAdmin()) {
			items.push(<SettingsListItem
				key={i++}
				text={"Debug"}
				target={"debugloader"}
			/>);
		//}
		// items.push(<SettingsListItem
		// 	text={"xxxxxxxxxxxxxxxxxxxxx"}
		// 	target={"item"}
		// />);
		items.push(<SettingsListItem
			key={i++}
			text={"Tracker"}
			target={"tracker"}
		/>);

		return (
			<div id={settings.name} className="app-page">
				<div className='page-banner settings' />
				<div id="page-title">
					Settings
				</div>
				<div className="content-container" >
					<div className="m8p">
						{items}
						&nbsp;
					</div>
				</div>
			</div>
		);

	}
}

var settings = new AppPage({
	name: "settings",
	component: (<SettingsPage />)
});
export default settings;
