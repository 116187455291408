import UserService from './UserService'
import language from './Language'
import config from './AppConfig'
import util from './AppUtil'

var debug = false;

class Service
{
	static ping(s) {
		if (debug) console.log("ping( "+s.host+" )");
		return fetch( (s.host + 'ping'), {method: 'get'})
		.then( (r) => { return (r.status === 200) })
	}

	static fetchPost(call, data, service) {
		if (debug) console.log("fetchPost( )");
		if (!service) service = config.service.gateway;
		var furl = (service.host + call);
		return fetch( furl, {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		})
		.then( (res) => {
			if (debug) console.log("Service fetchPost get response:");
			if (debug) console.dir(res);
			if (res.status == 500) {
				return Promise.reject(res);
			}
			return res.json()
			.then( (obj) => {
				if (obj.error && obj.error === config.session.expireMessage) {
					util.dispatch('staleSession');
					return Promise.reject(obj.error);
				}
				return Promise.resolve(obj);
			})
		})
	}

	static fp(call, data, service) {
		return Service.fetchPost( call, data, service );
	}

	// fetchPost with auth
	static fpa(call, data) {
		return Service.fetchPost(call, UserService.addAuth(data))
	}

	static fetchGet(service, call) {
		return fetch( (service.host + call), {method: 'get'})
		.then( (res) => {
			if (res.status === 500) {
				return Promise.reject("internal server error")
			}
			return Promise.resolve(res);
		})
	}

	static fg(service, call) { // alias for fetchGet
		return Service.fetchGet( service, call );
	}

}

export default Service