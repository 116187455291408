import Profile from './Profile'


var debug = false;

export class ProfileInfoField
{
	constructor (value) {
		if (debug) console.log("new ProfileInfoField: " + value );
		this.value = value;
	}

	save (newValue) {
		if (debug) console.log("set "+this.dbk+" to " + newValue);
		if (this.validate(newValue)) {
			this.value = newValue;
			var data = {};
			data[this.dbk] = this.value;
			return Promise.resolve( data );
		} else {
			return Promise.reject('value invalid');
		}
	}

	get displayName () {
		return this.dbk.split('_').map(s => {
			return s.charAt(0).toUpperCase() + s.slice(1)
		}).join(' ');
	}

	validate (s) {
		return true;
	}
}

export class ProfilePortrait extends ProfileInfoField
{
	get dbk() { return "portrait" };

	isSvg() {
		const arr = this.value.split(".");
		const last = arr[arr.length - 1];
		return (last === "svg");
	}
	get svg() { return this.isSvg() }
}

export class EmailAddress extends ProfileInfoField
{
	get dbk() { return "email" };

	validate(s) {
		const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (s.match(mailformat)) {
			if (debug) console.log("email validated " + s);
			return true
		} else {
			console.error("email invalid " + s);
			return false
		}
	}
}

export class PhoneNumber extends ProfileInfoField
{
	get dbk() { return "phone" };

	validate(s)
	{
		const telformat =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
		if( s.match(telformat) ) {
			if (debug) console.log("phone number validated");
			return {success: true};
		}
		else {
			console.warn(s+ " invalid phone number");
			return {success: false, error: "!"};
		}
	}
}

export class FirstName extends ProfileInfoField {
	get dbk() { return "first_name" };
}

export class LastName extends ProfileInfoField {
	get dbk() { return "last_name" };
}

export class AboutText extends ProfileInfoField {
	get dbk() { return "about" };
}

export class City extends ProfileInfoField {
	get dbk() { return "city" };
}

export class State extends ProfileInfoField
{
	get dbk() { return "state" };

	get fullName() {
		const states = {
		    "AL": "Alabama",
		    "AK": "Alaska",
		    "AS": "American Samoa",
		    "AZ": "Arizona",
		    "AR": "Arkansas",
		    "CA": "California",
		    "CO": "Colorado",
		    "CT": "Connecticut",
		    "DE": "Delaware",
		    "DC": "District Of Columbia",
		    "FM": "Federated States Of Micronesia",
		    "FL": "Florida",
		    "GA": "Georgia",
		    "GU": "Guam",
		    "HI": "Hawaii",
		    "ID": "Idaho",
		    "IL": "Illinois",
		    "IN": "Indiana",
		    "IA": "Iowa",
		    "KS": "Kansas",
		    "KY": "Kentucky",
		    "LA": "Louisiana",
		    "ME": "Maine",
		    "MH": "Marshall Islands",
		    "MD": "Maryland",
		    "MA": "Massachusetts",
		    "MI": "Michigan",
		    "MN": "Minnesota",
		    "MS": "Mississippi",
		    "MO": "Missouri",
		    "MT": "Montana",
		    "NE": "Nebraska",
		    "NV": "Nevada",
		    "NH": "New Hampshire",
		    "NJ": "New Jersey",
		    "NM": "New Mexico",
		    "NY": "New York",
		    "NC": "North Carolina",
		    "ND": "North Dakota",
		    "MP": "Northern Mariana Islands",
		    "OH": "Ohio",
		    "OK": "Oklahoma",
		    "OR": "Oregon",
		    "PW": "Palau",
		    "PA": "Pennsylvania",
		    "PR": "Puerto Rico",
		    "RI": "Rhode Island",
		    "SC": "South Carolina",
		    "SD": "South Dakota",
		    "TN": "Tennessee",
		    "TX": "Texas",
		    "UT": "Utah",
		    "VT": "Vermont",
		    "VI": "Virgin Islands",
		    "VA": "Virginia",
		    "WA": "Washington",
		    "WV": "West Virginia",
		    "WI": "Wisconsin",
		    "WY": "Wyoming"
		}
		return states[this.value];
	}
}

export class ProfileFollowing extends ProfileInfoField
{
	get dbk() { return "following" };

	isFollowing(pid) {
		if (this.value.includes) {
			return (this.value.includes(pid))
		} else {
			console.log("I follow nobody");
			return false;
		}
	}
}

