import { GoogleLogin } from 'react-google-login'
import ProfileService from '../ProfileService'
import AwesomeButton from './AwesomeButton'
import FormCheckBox from './FormCheckBox'
import AwesomeInput from './AwesomeInput'
import UserService from '../UserService'
import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import language from '../Language'
import { Spacer } from './Common'
import config from '../AppConfig'
import AppPage from '../AppPage'
import util from '../AppUtil'
import Agree from './Agree'
import User from '../User'
import app from '../app'
import uu from '../uu'
import './JoinPassword.css'

var debug = true;

class GoogleImportScreen extends Component
{
	profileObj = null;

	clickSubmit = () => {
		//console.log('clickSubmit()');
		app.goPage('welcome');

		if (util.$('name-input').checked) {
			ProfileService.updateMe({
				first_name: thisPage.profileObj.givenName,
				last_name: thisPage.profileObj.familyName
			})
		}
		if (util.$('photo-input').checked) {
			console.warn("todo import photo")
		}
	}

	render () {
		const { email } = thisPage.profileObj;
		console.log("--->",thisPage.profileObj);
		const hello = email ? `Hello, ${email}!` : '';

		return (
			<div id={thisPage.name} className="app-page">
				<div className='page-banner join-google' />
				<div id="page-title">Embrace Anonymity?</div>
				<div className="content-container" >
					<div className='form-container'>

						<div
							id='emailWelcome'
							className='info-display'
						>{ hello }</div>

						<FormCheckBox
							name='name'
							text={ language.import.name }
							checked={false}
						/>

						<FormCheckBox
							name='photo'
							text={ language.import.photo }
							checked={false}
						/>

						<AwesomeButton
							name='ok'
							text='OK'
							clickAction={ this.clickSubmit }
						/>

					</div>
				</div>
				<Spacer height={42} />
			</div>
		);
	}
}

var thisPage = new AppPage({
	name: "googleImport",
	component: (<GoogleImportScreen />)
});

thisPage.initialize = function(data) {
	console.log("init google import", data);
	this.profileObj = data;
	return Promise.resolve();
}


export default thisPage;

