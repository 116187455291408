import ProfileService from '../ProfileService'
import AwesomeButton from './AwesomeButton'
import React, { Component } from 'react'
import GroupSelect from './GroupSelect'
import { FeedList } from './FeedList'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'

import './Group.css'

var debug = true;


class GroupPage extends Component
{
	clickTopButton() {
		if (debug) console.log("clickTopButton");
		if (thisPage.owner()) {
			app.go('groupEdit', thisPage.group);
		} else {
			this.showMemberOptions(!this.optionsVisible);
		}
	}

	showMemberOptions(b) {
		console.log("showMemberOptions( "+b+" )")
		this.optionsVisible = b;
		let func = b ? util.rmClass : util.addClass;
		console.dir(func);
		func(util.$('group-options'), 'hidden');
	}

	// hideMemberOptions() {
	// 	util.addClass(util.$('group-options'), 'hidden');
	// }

	clickLeave() {
		if (debug) console.log("clickLeave()");
		let message = 
		util.dispatch('showConfirmation', {
			message: "Quit Group?",
			title: "Leaveing "+thisPage.group.name+" group cannot be undone.",
			textYes: "Leave", textNo: "Stay",
			icon: 'group',
			actionYes: ( () => { this.quit() } )
		})
	}

	quit() {
		console.warn("quit()")
		return ProfileService.quitGroup(thisPage.group.id)
		.then(() => {
			console.log("you have left the group.")
			app.go('profile');
		})
	}

	clickMessage() {
		if (debug) console.log("clickMessage()")
		app.go('conversation', thisPage.group.pid)
	}

	render() {
		let buttonClass = 'button-icon tint-light ';
		if (thisPage.owner()) {
			buttonClass += 'icon-pencil';
		} else {
			buttonClass += 'icon-dots';
		}
		if (debug) console.log("Render GroupPage");
		return (
			<div id={thisPage.name} className='app-page'>
				<div className='group-page-top'>
					<div />
					<GroupSelect
						groups={thisPage.groups}
						changeAction={thisPage.changeGroup}
					/>
					<div
						className={buttonClass}
						onClick={(e) => {this.clickTopButton(e)}}
					/>
				</div>
				<div id='group-options' className='content hidden'>
					<button
						id='leave-group-button'
						className='button group-options'
						onClick={(e) => this.clickLeave(e)}
					>
						Leave Group
					</button>
					<button
						id='message-owner-button'
						className='button group-options'
						onClick={(e) => this.clickMessage(e)}
					>
						Message Group Owner
					</button>
				</div>
				<div id='list-container' >
					<FeedList
						sortType={this.sortType}
						group={thisPage.group.id}
					/>
				</div>
			</div>
		);
	}

	componentDidMount() { }
}

var thisPage = new AppPage({
	name: 'group',
	component: (<GroupPage />)
});


thisPage.initialize = function(data) {
	if (debug) console.log("Group Page initialize( {...} ) ");
	if (debug) console.dir(data);
	let dataWasJusAnId;
	if (!isNaN(data)) {
		dataWasJusAnId = true;
	} else {
		this.group = data;
	}
	this.sortType = 'group';
	return ProfileService.groups()
	.then( groups => {
		this.groups = groups;
		if (dataWasJusAnId) {
			this.groups.forEach( group => {
				if (group.id === data) {
					this.group = group;
				}
			})
		}
		return Promise.resolve();
	})
	console.log("this.group:");
	console.dir(this.group);
	if (this.groups.length === 0) {
		this.groups = null;
	}
}

thisPage.changeGroup = function(gid) {
	if (debug) console.log("changeGroup( "+gid+" )");
	app.go('group', gid);
}

thisPage.owner = function() {
	return this.group.pid === ProfileService.me.pid;
}

export default thisPage;



