import { GoogleLogin } from 'react-google-login'
import React, { Component } from 'react'
import language from '../Language'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'

const googleClientId = '961308379775-aq9d75g572039vtbq3ovvlbojsum4hg1.apps.googleusercontent.com';

import './Join.css'

class JoinScreen extends React.Component
{
	clickPassword () {
		console.log('clickPassword()');
		app.goPage("joinPassword");
	}

	clickEmail () {
		console.log('clickEmail() TODO');
		//alert("Sign up via electronic mail code is coming soon.")
		app.goPage("joinEmail");
	}

	clickPhone () {
		console.log('clickPhone() TODO');
		alert("Sign up via text message is coming soon.")
		//app.goPage("joinPassword");
	}

	clickLogin () {
		console.log('clickLogin()');
		app.goPage("login");
	}

	clickGoogle () {
		console.log("clickGoogle");
		app.goPage("joinGoogle");
	}

	onGoogleLoginSuccess = (res) => {
		console.log("login success user: ", res.profileObj);
	};

	onGoogleLoginFailure = (res) => {
		//alert("Login with google failed.");
		console.warn("google login fail");
		console.dir(res);
	};


	onSignIn (googleUser) {
		console.log("onSignIn");
	  var profile = googleUser.getBasicProfile();
	  console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
	  console.log('Name: ' + profile.getName());
	  console.log('Image URL: ' + profile.getImageUrl());
	  console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
	}

	// componentDidMount() {
	// 	const buttonStyle = getComputedStyle(util.$('buttonPassword'));
	// 	const googleButton = util.$('googleButton');

	// 	const stylesToTransfer = [ 'borderRadius', 'fontSize', 'padding', 'backgroundColor',
	// 		'color', 'borderColor', 'borderWidth', 'borderStyle', 'width', 'margin'];

	// 	stylesToTransfer.forEach((prop) => googleButton.style[prop] = buttonStyle[prop]);
	// }

	render() {
		const username = join.prePopName ? join.prePopName : "";
		join.prePopName = "";
		return (
			<div id={join.name} className="app-page">
				<div className='page-banner join' />
				<div id="page-title">New Here?</div>

				<div className="content-container" >

				<div className='form-container'>
					<div>Confirm your uniqueness...</div>

					<button
						onClick={ (e) => this.clickGoogle(e) }
						type="button"
						className="button long"
						id="buttonGoogle"
					>
						<img src='img/google.png' className="glogo" width="38" height="38"/>With Google
					</button>

					<button
						onClick={(e) => this.clickPassword(e)}
						type="button"
						className="button long"
						id="buttonPassword"
					>
						With a Password
					</button>

					<Spacer />

					<div
						onClick={(e) => this.clickLogin(e)}
						className="info-display"
					>
						already have an account?
					</div>
					<button
						onClick={(e) => this.clickLogin(e)}
						type="button"
						className="button long"
						id="buttonLogin"
					>
						Login
					</button>

				</div>
				</div>
			</div>
		);
	}
}

// auto google sign in
// <div className="g-signin2" data-onsuccess="this.onSignIn"></div>

var join = new AppPage({
	name: "join",
	component: (<JoinScreen />)
});
join.populateNameField = function(s) {
	this.prePopName = s;
}
export default join;


					// <GoogleLogin
					// 	clientId={ googleClientId }
					// 	butonText="Login"
					// 	onSuccess={ this.onGoogleLoginSuccess }
					// 	onFailure={ this.onGoogleLoginFailure }
					// 	cookiePolicy={ 'single_host_origin' }
					// 	isSgnedIn={ true }
					// 	render={(renderProps) => (
					// 		<button id="googleButton" onClick={renderProps.onClick}>
					// 			<img src='img/google.png' className="glogo" width="38" height="38"/>With Google
					// 		</button>
					// 	)}
					// >
					// </GoogleLogin>


// <button
// 	onClick={(e) => this.clickEmail(e)}
// 	type="button"
// 	className="button long disabled"
// 	id="buttonEmail"
// >
// 	With Electronic Mail
// </button>

// <button
// 	onClick={(e) => this.clickPhone(e)}
// 	type="button"
// 	className="button long disabled"
// 	id="buttonPhone"
// >
// 	With a Telephone
// </button>
