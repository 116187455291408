import React, { Component } from 'react'
import language from '../Language'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'


class ErrorPage extends Component
{
	clickGoToDebug() {
		console.log('clickGoToDebug()');
		//app.goPage('debugloader');
		app.goBack();
	}

	render() {
		return (
			<div id={thisPage.name} className="app-page" >
				<div id="page-title">
					an error occurred
				</div>
				<div className="m8p">
					<Spacer lines={2} />
					<div id="errorText" >
						{thisPage.errorText}
					</div>
				</div>
				<Spacer lines={8} />
				<button
					onClick={(e) => this.clickGoToDebug(e)}
					type="button"
					className="button long"
				>
					Go Back
				</button>
			</div>
		);
	}

	componentDidMount() {
		if (thisPage.htmlError) {
			console.log("oh, an html error. nice.");
			util.$("errorText").innerHTML = thisPage.htmlError;
		}
	}
}

var debug = true;

var thisPage = new AppPage({
	name: "error",
	component: (<ErrorPage />),
});
thisPage.errorText = '?';

thisPage.initialize = function(err) {
	console.log(this.name+" initializing. ");
	console.dir(err);

	console.log(typeof err);
	this.htmlError = null;

	// detect server errors
	if (err.statusText) {
		console.log("Internal server error detected");
		var msg;
		try { // to extract an html message
			msg = err.responseText.split("<body>")[1];
			msg = msg.split("</body>")[0];
			msg = msg.split("<pre>")[1];
			msg = msg.split("</pre>")[0];
			this.htmlError = (err.responseURL + "<br><br>"
				+ err.statusText + "<br><br>"
				+ msg
			);
		} catch (e) {
			console.log("oops");
			console.dir(e);
		}

	} else {
		console.log("other kind of error");
		if (!err) {
			err = "Error"
		}
		if (err.error) {
			err = err.error;
		}
		else if (err.response) {
			err = err.response;
		}
		this.errorText = err.toString();
	}
	return Promise.resolve();
};

export default thisPage;
