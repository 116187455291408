import ProfileService from '../ProfileService'
import React, { Component } from 'react'
import UserService from '../UserService'
import LocalStore from '../LocalStore'
import config from '../AppConfig'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import Service from '../Service'
import util from '../AppUtil'
import User from '../User'
import app from '../app'

import './UserInfo.css'

var debug = true;

class UserInfoPage extends Component
{
	clickDelete() {
		alert("Proceed with caution...");
		const detail = {
			message: 'Permanently Delete Yourself?',
			textYes: 'Goodbye',
			textNo:'Cancel',
			icon: 'trash',
			actionYes: ( () => { this.deleteMe() } ),
		}
		const evt = new CustomEvent('showConfirmation', {detail});
		window.dispatchEvent( evt );
	}

	deleteMe() {
		console.warn("deleteMe()")
		LocalStore.deleteUser();
		window.location = ('/');
	}

	clickSwitch() {
		console.warn("clickSwitch()")
		app.goPage('users')
	}

	render() {
		var pid = ProfileService.userPid;
		if (!pid) pid = '?';
		var uid = UserService.uid;
		if (!uid) uid = '?';
		var userName = UserService.username;
		var deviceId = UserService.deviceIdentifier;
		return (
			<div id={thisPage.name} className='app-page'>
				<div className='page-banner userinfo' />
				<div id="page-title">Who Are You?</div>
				<div className="content-container">

					<div className='form-container'>
						<div >user name: {userName}</div>
						<div >uid: {uid}</div>
						<div >pid: {pid}</div>
						<div >device: {deviceId}</div>

						<div />
						<div />

						<button
							onClick={(e) => this.clickDelete(e)}
							type="button"
							className="button long"
							id="buttonDelete"
						>
							Delete Me
						</button>

						<button
							onClick={(e) => this.clickSwitch(e)}
							type="button"
							className="button long"
							id="buttonDelete"
						>
							Switch User
						</button>

						<Spacer height={24} />

					</div>
				</div>

				<Spacer height={102} />

			</div>
		)
	}
}

var thisPage = new AppPage({
	name: "userInfo",
	component: (<UserInfoPage />)
});


export default thisPage;
