import AwesomeButton from './AwesomeButton'
import React, { Component } from 'react'
import { FeedList } from './FeedList'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'

var debug = false;

class FeedPage extends Component
{
	render() {
		if (debug) console.log("Render FeedPage");
		return (
			<div id={thisPage.name} className='app-page'>
				<div id='page-title'>Recent Activity</div>
				<div id='list-container' >
					<FeedList sortType={this.sortType} />
				</div>
			</div>
		);
	}
}

var thisPage = new AppPage({
	name: 'feed',
	component: (<FeedPage />)
});


thisPage.initialize = function(data) {
	if (debug) console.log("Feed Page initialize( {...} ) ");
	if (debug) console.dir(data);
	//this.initialData = data;
	this.sortType = 'recent';
	return Promise.resolve();
}

export default thisPage;



