import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
//import store from './store';
import LocalStore from './LocalStore';
import config from './AppConfig';
import User from './User';
import util from './AppUtil';
import Service from './Service';
import PictureService from './PictureService';
import UserService from './UserService';
import ProfileService from './ProfileService';
import ChatService from './ChatService';
import Profile from './Profile';
import ios from './ios';
import Tag from './Tag';
import uu from './uu';
// Components
import buttonbar from './components/ButtonBar';
import {PageFooter} from './components/Common';
import {PageHeader} from './components/Header';
// Components: App Pages
import homePage from './components/Home';
import privacyPolicy from './components/PrivacyPolicy';
import tosPage from './components/Terms';
import debugPage from './components/Debug';
import loginPage from './components/Login';
import joinPage from './components/Join';
import joinPassword from './components/JoinPassword';
import joinEmail from './components/JoinEmail';
import JoinGoogle from './components/JoinGoogle';
import GoogleImport from './components/GoogleImport';
import welcomePage from './components/Welcome';
import profilePage from './components/ProfilePage';
import errorPage from './components/ErrorPage';
import profileEditPage from './components/ProfileEdit';
import settingsPage from './components/Settings';
import shareEditPage from './components/ShareEdit';
import feedPage from './components/Feed';
import feedFocusPage from './components/FeedFocus';
import searchPage from './components/Search';
import camera from './components/Camera';
import themePage from './components/Theme';
import inbox from './components/Inbox';
import conversationPage from './components/Conversation';
import TermsPage from './components/Terms';
import ForgotPage from './components/Forgot';
import UserInfoPage from './components/UserInfo';
import UsersPage from './components/Users';
import GroupPage from './components/Group';
import GroupsPage from './components/Groups';
import GroupEditPage from './components/GroupEdit';
import TrackerPage from './components/Tracker';


var debug = true;

function $(id) { return document.getElementById( id ) };

var app =
{
	cordova: false,
	defaultPage: "feed",
	android: navigator.userAgent.toLowerCase().indexOf('android') > -1,
	ios: (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)),

	pages: Array(),
	currentPage: null,
	user: null,

	pageLoading: {
		waiting: false,
		cancel: false,
	},

	startApp: function() {
		if (debug) console.log("startApp() "+location.href);

		// load one of two configs local or production
		if (location && location.href) {
			var loc = location.href.split('://')[1].split(':')[0];
			config.setLocal( loc === "localhost" );
		}

		const msg = "connecting to " +config.getLocation();
		$('loading-mess').innerText = msg;
		this.pageContainer = $('app-page-container');
		UserService.deviceIdentifier =  this.deviceIdentifier();
		this.loadTheme(); // faster theme display

		config.load( )
		.then( (success) => {
			if (success) {
				if (debug) console.log("config loaded");
				Tag.config(config);
				this.startAppPage();
				this.addEventListeners();
				
				const uid = LocalStore.getItem('uid');
				if (uid) {
					console.log("attempt auto login");
					$('loading-mess').innerText = "logging in";
					UserService.autoLogin()
					.then( () => {
						console.log("auto login success");
						$('loading-mess').innerText = "loading profile";
						ProfileService.loadMine({ uid })
						.then( () => {
							console.log("got my profile");
							this.loadFirstPage()
						})
					})
					.catch( (err) => {
						console.warn("cannot auto login", err);
						this.loadFirstPage();
					})
				} else {
					console.warn("no previous login data in local store");
					return this.loadFirstPage();
				}
			}
			else {
				this.hideLoadingSpinner();
				$("loading-mess").innerText = "Gateway Unreachable";
			}
		})
	},

	addEventListeners: function() {
		window.addEventListener('popstate', this.onPopState);
		window.addEventListener('resize', function() {
			app.sizePage();
		});
		window.addEventListener('staleSession', function() {
			app.staleSession();
		});
	},

	showLoadingSpinner: function(s) {
		$('loading-mess').innerHTML = s || '';
		$('main-spinner').style.display = 'block';
	},

	hideLoadingSpinner: function() {
		$('loading-mess').innerHTML = '';
		$('main-spinner').style.display = 'none';
	},

	startAppPage: function() {
		console.log("startAppPage()");
		if (!this.pageContainer) {
			console.log("no main container. Wait and try again.");
			return uu.wait(100)
			.then( () => {
				this.startAppPage();
			})
		}
		this.pageContainer.onclick = function() {
			//console.log("pageContainer touched");
			window.dispatchEvent(new Event("clickMain"));
		}
		this.makePageHeader({});
		this.sizePage();
	},

	makePageHeader: function() {
		console.log("makePageHeader()");
		const e = $('header-container');
		if (e) ReactDOM.render(<PageHeader back={true} />, e);
	},

	makeMenu: function() {
		ReactDOM.render(
			<WebMenu />,
			$('menu-container')
		);
		ReactDOM.render(
			<WebMenuButton />,
			$('menu-button-container')
		);
	},

	onPopState: function(e) {
		if (debug) console.trace("onPopState( {...} )");
		if (debug) console.dir(e);
		if (e && e.state && e.state.page) {
			console.log(" -> "+e.state.page);
			app.goPage(e.state.page, e.state.data, false);
		}
	},

	goBack: function() {
		if (debug) console.log("goBack()");
		this.currentPage.cleanUp();
		window.history.back();
	},

	redirect(page) {
		if (!UserService.loginSuccess) {
			console.warn('user has not successfully logged in.');
			switch (page) {
				case 'shareEdit':
				case 'profileEdit':
				case 'inbox':
					console.warn("redirecting to join");
					this.goPage('join');
					return true;
					break;
			}
		}
		return false;
	},

	go: function(page, data, addHistory) {
		return app.goPage(page, data, addHistory);
	},

	goPage: function(page, data, addHistory=true) {
		console.log("goPage( "+page+", "+data+", "+addHistory+" )");

		if (page !== 'join' && this.redirect(page)) return;

		if (this.pageLoading.waiting) Promise.reject("waiting");
		if (!config) Promise.reject("no config");

		if (!this.pages[page]) {
			const msg = "page "+page+" not loaded.";
			return uu.wait(200).then( () => {
				if (debug) console.log(" try again");
				this.goPage(page, data, addHistory)
				.catch( (e) => {
					console.error("load retry error");
					console.error(e);
				})
				return;
			})
		}
		page = this.pages[page];

		if (!page) {
			return Promise.reject("cannot load page " + page);
		}

		const evt = new CustomEvent('pageChange', {detail: page});
		window.dispatchEvent( evt );
		this.showLoadingSpinner("Loading " + page.name);

		if (this.currentPage) {
			if (this.currentPage.cleanUp) {
				this.currentPage.cleanUp();
			}
			util.addClass( util.$(".app-page"), 'fade-out' );
		}

		this.pageLoading.waiting = true;

		if (page.initialize) {
			return page.initialize(data).then( () => {
				if (addHistory) {
					app.addHistory(page, data);
				} else {
					if (debug) console.log("no add history");
				}
				return app.showAppPage(page);
			})
		} else {
			return app.showAppPage(page);
		}
	}, /* goPage */

	addHistory: function(page, data) {
		if (debug) console.log("addHistory( {}, {} )");
		var stateData = Object.assign( {}, data );
		if (stateData && stateData.callBack) delete stateData.callBack;
		//if (debug) console.trace("addHistory stateData:");
		//if (debug) console.dir(stateData);
		window.history.pushState(
			{ page: page.name, data: stateData }, // state
			( "MyGonzo "+ page.name ), // page title
			( page.getPathName() ) // document location 
		)
	},

	showAppPage: function(page) {
		console.log("showAppPage( "+page.name+" )");

		this.hideLoadingSpinner();
		this.pageLoading.waiting = false;
		const container = this.pageContainer
		ReactDOM.render( [], container ); // for same page loads force re-render
		container.scrollTop = 0;
		this.scrollToTop();
		app.currentPage = page;

		try {
			ReactDOM.render( page.renderElement, container );
		} catch (err) {
			console.error("Problem rendering page");
			console.error(err);
			this.showError(err);
			return Promise.reject("Problem rendering page")
		}

		return Promise.resolve(page);
	},

	showError: function(err) {
		console.warn("showError( {...} )");
		console.dir(err);
		if (config.loaded) {
			this.goPage("error", err);
		}
	},

	fadeOutAppPage: function(page) {
		const el = $(page.name);
		if (el && el.classList) {
			el.classList.add('fade-out');
		}
	},

	// loadui showui
	loadButtonBar: function() {
		if (!this.buttonBarLoaded) {
			if (debug) console.log("app Load UI");
			buttonbar.screenShow('buttonbar-container');
			this.buttonBarLoaded = true;
		}
	},

	loadMyProfilePage: function() {
		if (debug) console.log("loadMyProfilePage()");
		this.goPage("profile", ProfileService.me);
	},

	loadProfilePagePID: function(pid) {
		if (debug) console.log("loadProfilePagePID( "+pid+" )");
		ProfileService.getProfile({pid})
		.then( (p) => {
			this.goPage('profile', p);
		})
	},

	loadTheme: function() {
		var currentTheme = LocalStore.getItem("theme");
		if (!currentTheme) {
			currentTheme = config.defaultTheme;
		}
		if (debug) console.log("loadTheme() ... currentTheme: " + currentTheme);
		document.documentElement.setAttribute('data-theme', currentTheme);
		this.currentTheme = currentTheme;
	},

	useTheme: function(s) {
		if (debug) console.log("useTheme( "+s+" )");
		if (this.currentTheme !== s) {
			LocalStore.setItem('theme', s);
			LocalStore.saveLoginInfo( UserService.uid, {theme: s} )
			this.loadTheme();
		}
	},

	toggleTheme: function() {
		if (debug) console.log("toggleTheme()");
		var currentTheme = LocalStore.getItem("theme");
		(currentTheme == 'light') ? currentTheme = 'dark' : currentTheme = 'light';
		// document.documentElement.setAttribute('data-theme', currentTheme);
		LocalStore.setItem('theme', currentTheme);
		this.loadTheme();
	},

	hasGetUserMedia: function() {
		if (debug) console.log("hasGetUserMedia()");
		if (navigator.mediaDevices) {
			if (debug) console.log(" navigator yes");
			if (debug) console.log(" navigator.mediaDevices:");
			if (debug) console.dir(navigator.mediaDevices);
			if (debug) console.log();
			if (debug) console.log("webkit: " + navigator.webkitGetUserMedia +", moz: "+ navigator.mozGetUserMedia)
			if (navigator.mediaDevices.getUserMedia) {
				if (debug) console.log(" navigator.mediaDevices.getUserMedia:");
				if (debug) console.dir(navigator.mediaDevices.getUserMedia);
				return true;
			} else {
				console.warn("no navigator.mediaDevices.getUserMedia")
			}
		} else {
			console.warn("no navigator.mediaDevices")
		}
		return false;
	},

	takePhoto: function( cbf ) {
		if (debug) console.log("takePhoto()");
		if (this.hasGetUserMedia()) {
			if (debug) this.listAllMediaDevices();
			const options = { callBack: cbf };
			app.goPage("camera", options);
		} else {
			alert('getUserMedia() is not supported by your lame browser');
		}
	},

	takePhotoCordova: function(onPhoto,	onError) {
		navigator.camera.getPicture( onPhoto, onError, {
				quality: 90,
				correctOrientation: true,
				destinationType: Camera.DestinationType.DATA_URL
		});
	},

	listAllMediaDevices: function() {
		navigator.mediaDevices.enumerateDevices()
		.then( (mediaDevices) => {
			if (debug) console.log("media devices:");
			if (debug) console.dir(mediaDevices);
		});
	},

	selectPhoto: function(onPhoto, onError) {
		if (debug) console.log("selectPhoto()");
		navigator.camera.getPicture(
			onPhoto,
			onError,
			{
				destinationType: Camera.DestinationType.FILE_URI,
				sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
			}
		);
	},

	getScreenWidth: function() {
		//return $('app').offsetWidth;
		//console.log("getScreenWidth: " + document.body.clientWidth);
		return document.body.clientWidth;
	},

	getMainContentWidth: function() {
		const elm = util.$('.app-page');
		if (!elm) return;
		const cs = window.getComputedStyle( elm, null );
		return parseFloat( cs.getPropertyValue('width') );
	},

	getContentAreaHeight: function() {
		// if (debug) console.warn("HACKED to 500 getContentAreaHeight()");
		// return 500;
		const header = $('header-container');
		const bb = $('buttonbar-container');
		if (!header || !bb) return document.body.clientHeight;

		var h = document.body.clientHeight - header.offsetHeight;
		if (this.wideMode() < 2) {
			h -= bb.offsetHeight;
		}
		return h
	},

	scrollToBottom: function(e) {
		// console.log("document.body.scrollTop:            " +document.body.scrollTop);
		// console.log("document.documentElement.scrollTop: " +document.documentElement.scrollTop);
		// console.log("this.pageContainer.scrollTop:       " +this.pageContainer.scrollTop);
		// console.log("document.body.scrollTop:            " +document.body.scrollTop);
		window.scrollTo(0, document.body.scrollHeight);
		this.pageContainer.scrollTo(0, this.pageContainer.scrollHeight);
	},

	reloadApp: function() {
		window.location = ('/');
	},

	scrollToTop: function(e) {
		window.scrollTo(0, 0);
		this.pageContainer.scrollTo(0, 0);
	},

	staleSession: function() {
		console.warn("staleSession()");

		var uid = LocalStore.getItem('uid');
		if (uid) {
			console.log('previously successful login '+uid);
			if (!this.timeSinceLastStaleAlert) {
				this.timeSinceLastStaleAlert = 1;
			}
			if (Date.now() - this.timeSinceLastStaleAlert > 5000) {
				this.timeSinceLastStaleAlert = Date.now();
				alert("Your session has expired.");
				UserService.autoLogin(app.user)
				.then( () => {
					app.loadFirstPage();
				})
				.catch( (err) => {
					console.error("cannot get new session");
					this.goPage('login');
				})
			}
		} else {
			console.log('no successful log-in. doing nothing');
		}
	},

	loadFirstPage: function() {
		if (debug) console.log("loadFirstPage()");
		const pathname = window.location.pathname;

		app.loadButtonBar();

		// load a static page?
		const page = pathname.slice(1).toLowerCase();
		console.log("PAGE: " + page);
		switch(page) {

			case 'pp':
			case 'privacy':
			case 'privacypolicy':
				return app.goPage('pp');
				break;

			case 'join':
			case 'joingoogle':
			case 'joinemail':
				return app.goPage('join');
				break;

			case 'home':
				return app.goPage('home');
				break;

			case 'tracker':
				return app.goPage('tracker');
				break;

			case 'tos':
			case 'terms':
			case 'termsofservice':
				return app.goPage('tos');
				break;
		}

		// load a dynamic page?
		var params = pathname.split('/')[1].split('-');
		if (debug) console.log("params: " + params);
		if ( params[1] && !isNaN(params[1]) ) {
			switch(params[0]) {

				case 'p':
				case 'profile':
					return app.goPage('profile', params[1]);
					break;

				case 's':
				case 'share':
					return app.goPage('feedfocus', params[1]);
					break;
			}
		}

		app.goPage(this.defaultPage);
	},

	login: function(data) {
		return UserService.login(data)
		// .then( (res) => {
		// 	app.onLoginSuccess(res);
		// 	return Promise.resolve(res);
		// })
	},

	isMobileDevice: function() {
		if (this.cordova) {
			try {
				console.log("isMobileDevice() " + device.platform);
				switch (device.platform) {
					case "browser": return false;
					default: return true;
				}
			} catch (e) {
				// ignore
			}
		}
		return false;
	},

	deviceIdentifier: function() {
		var deviceId = LocalStore.getItem('deviceId');
		if (!deviceId) {
			console.warn("First run, setting device ID");
			deviceId = (navigator.appName
					+ '-' + navigator.appCodeName 
					+ '-' + navigator.platform
					+ '-' + Date.now()
				);
			localStorage.setItem('deviceId', deviceId);
		}
		return deviceId;
	},

	registerAppPages: function(pages) {
		this.pages.forEach( (page) => {
			this.registerAppPage(page);
		});
	},

	registerAppPage: function(page) {
		if (!page.name ||
			!page.renderElement ||
			!page.initialize
		){
			var s = (page.name) ? page.name : "?";
			console.error("improper page " + s);
			console.dir(page);
			return null;
		}
		this.pages[page.name] = page;
		if (debug) console.log("registered app page: "+page.name);
	},

	hideKeyboard: function() {
		console.warn("TODO app hideKeyboard()");
		if (this.cordova) {
			cordova.plugins.Keyboard.hide();
		}
	},

	toggleFullScreen: function() {
		console.log("toggleFullScreen()");
		var doc = window.document;
		var docEl = doc.documentElement;

		var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
		var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

		try {
			if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
			  requestFullScreen.call(docEl);
			}
			else {
			  cancelFullScreen.call(doc);
			}
		} catch (e) {
			alert("Full screen not supported by your lame browser");
		}
	},

	scrollTop: function() {
		const de = document.documentElement.scrollTop;
		if (de > 0) return de;
		const pc = this.pageContainer.scrollTop;
		if (pc > 0)  return pc;
		const b = document.body.scrollTop;
		if(b > 0)  return b;
		return 0;
	},

	scrollBotton: function() {
		return app.scrollTop() + this.pageContainer.offsetHeight;
	},

	loadPoint: function() {
		const extraMargin = 288;
		const scrollHeight = app.pageContainer.scrollHeight;
		const loadAtThisPoint = scrollHeight - extraMargin;
		const r = (app.scrollBotton() >= loadAtThisPoint);
		//if (debug) console.log("loadPoint() ? "+r);
		return r;
	},

	wideMode: function() {
		var size = 0;
		if (app.getScreenWidth() >= 600) size++;
		if (app.getScreenWidth() >= 642) size++;
		if (app.getScreenWidth() >= 740) size++;
		return size;
	},

	sizePage: function() {
		if (debug) console.log("sizePage()");
		var size = this.wideMode();
		buttonbar.transform( size );

		if (!this.pageContainer) return;
		
		var h = document.body.scrollHeight - 50;
		if (size >= 2) {
			util.addClass(this.pageContainer, "wide2");
			console.log(" button bar top");
		}
		else {
			util.rmClass(this.pageContainer, "wide2");
			console.log(" button bar bottom");
		}
		this.pageContainer.style.height = h+'px';
	},

	notifyNewMessage: function(profile, convo) {
		if (debug) console.log("notify( "+profile+", "+convo+" )");

		if (Notification.permission === 'granted') {

			const notification = new Notification(
				('Message from '+profile.name), {
					body: convo.snip,
					// icon: '/img/icon/mg.svg'
					icon: '/img/favicon.png'
				}
			)

			convo.profile = profile;
			notification.onclick = (e) => {
				//app.goPage('inbox')
				app.goPage('conversation', convo );
			};

		} else if (Notification.permission !== 'denied') {
			Notification.requestPermission()
			.then( (permission) => {
				console.log("notification permission request "+permission);
				if (permission === 'granted') {
					return app.notify(profile, convo);
				}
			})
		} else if (Notification.permission === 'denied') {
			console.log("notifications denied");
		}
	}
};


document.addEventListener("DOMContentLoaded", function() {
	console.log("page ready");
	app.startApp();
});


export default app;
