import AwesomeButton from './AwesomeButton'
import FormCheckBox from './FormCheckBox'
import AwesomeInput from './AwesomeInput'
import UserService from '../UserService'
import React, { Component } from 'react'
import LocalStore from '../LocalStore'
import language from '../Language'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import config from '../AppConfig'
import Agree from './Agree'
import util from '../AppUtil'
import User from '../User'
import app from '../app'
import uu from '../uu'

import './JoinPassword.css'

var debug = true;

class PasswordGenerator extends Component
{
	constructor(props) {
		super(props);
		this.password = this.randomPassword();
	}

	randomPassword(length) {
		if (!length) length = config.password.autoLength;
		const chars =
			"abcdefghijklmnopqrstuvwxyz!@#$%^*()-+ABCDEFGHIJKLMNOP1234567890";

		var pass;
		var vali = {error: true};
		while (vali.error) {
			pass = "";
			for (var x = length; x > 0; --x) {
				var i = Math.floor(Math.random() * chars.length);
				pass += chars.charAt(i);
			}
			//console.log("ok, try " + pass);
			var vali = util.validatePassword(pass);
			//console.log(vali);
		}
		return pass;
	}

	clickCopy() {
		console.log("clickCopy()");
		const copyText = document.getElementById("pwgen");
		copyText.select();
		document.execCommand("copy");
		console.log("Copied the text: " + copyText.value);
	}

	clickGenerate() {
		console.log("clickGenerate()");
		this.password = this.randomPassword();
		document.getElementById("pwgen").value = this.password;
	}

	render() {
		return (
			<div className='passgen-container'>
				Secure Password Generator
				<div className='passgen'>
					<button
						onClick={(e) => this.clickGenerate(e)}
						type="button"
						className="button"
						id="buttonGenerate"
					>
						New
					</button>

					<div className="passgen-input-container" >
						<input
							type="text"
							className="textinput generated-pw-display"
							id="pwgen"
							value={this.password}
							//readOnly // cannot copy to clipboard on ios if readOnly
						></input>
					</div>

					<button
						onClick={(e) => this.clickCopy(e)}
						type="button"
						className="button"
						id="buttonCopy"
					>
						copy
					</button>
				</div>
			</div>
		)
	}
}

class JoinPasswordScreen extends Component
{
	clickSubmit() {
		console.log('clickSubmit()');
		this.submitClicked = true;
		if (!this.validateInputs()) return false;

		const data = {
			username: this.inputs.name.value,
			password: this.inputs.pw1.value,
			remember: util.$('remember-input').checked,
		}
		
		UserService.create(data).then( (res) => {
			this.createSuccess(res);
		}).catch( (err) => {
			this.createFail(err);
		})

		return true;
	}

	validateInputs() {
		this.setInputs();
		if (!this.checkName()) return false;
		if (!this.checkPassword()) return false;
		if (!this.matchPasswords()) return false;
		if (!this.checkAgree()) return false;
		return true;
	}

	onChange() {
		if (debug) console.log('onChange() ' + this.submitClicked);
		if (!this.submitClicked) return;
		if (this.validateInputs()) {
			util.rmClass( this.goButton, 'disabled' );
			util.$("errorMessage").innerText = ' ';
		}
	}

	createSuccess(res) {
		console.log("createSuccess()");
		uu.wait(2000)
		.then( () => {
			//console.warn("page load disabled")
			app.goPage('welcome');
		})
	}

	createFail(error) {
		console.warn("createFail( "+error+" )");
		util.dispatch('awesomeButtonReset');
		if (error.substring && error.substring('exists') > 0) {
			this.showError(error, this.inputs.nameContainer);
		} else {
			if (error.error) error = error.error;
			this.showError(error);
		}
	}

	checkName() {
		const valiName = util.validateUserName(this.inputs.name.value);
		if (valiName.error) {
			this.showError( valiName.error, this.inputs.nameContainer );
			return false;
		} else {
			console.log("name validated");
			util.rmClass( this.inputs.nameContainer, 'error' );
			return true;
		}
	}

	matchPasswords() {
		if (this.inputs.pw1.value === this.inputs.pw2.value) {
			console.log("password match");
			util.rmClass( this.inputs.pw1c, 'error' );
			util.rmClass( this.inputs.pw2c, 'error' );
			return true;
		} else {
			this.showError("Passwords do not match.",
				[this.inputs.pw1c, this.inputs.pw2c] );
			return false;
		}
	}

	checkPassword() {
		var valPass = util.validatePassword(this.inputs.pw1.value);
		if (valPass.error) {
			this.showError(valPass.error, [this.inputs.pw1c]);
			return false;
		} else {
			console.log('password ok');
			util.rmClass( this.inputs.pw1c, 'error' );
			util.rmClass( this.inputs.pw2c, 'error' );
			return true;
		}
	}

	checkAgree() {
		const input = util.$("agree-input");
		if (!input) return;
		if (input.checked) {
			util.rmClass(util.$('agree-container'), 'error');
			return true;
		}
		this.showError(language.login.youMustAgree, util.$('agree-container') );
	}

	showError(s, elements) {
		console.log("showError( "+s+" )");
		const em = util.$('errorMessage');
		if (!em) return;
		em.innerText = s;
		if (elements) {
			if (!Array.isArray(elements)) elements = [elements];
			elements.forEach(function(e) {
				e.classList.add('error');
			});		
		}
		util.addClass( this.goButton, 'disabled' );
	}

	render() {
		var debugDiv = (<div />);
		//if (debug) debugDiv = (<PasswordGenerator />)
		const username = thisPage.prePopName ? thisPage.prePopName : "";
		thisPage.prePopName = "";
		return (
			<div id={thisPage.name} className="app-page">
				<div className='page-banner join-password' />
				<div id="page-title">The Old Way</div>
				<div className="content-container" >
					<div className='form-container'>

						<AwesomeInput
							name='username'
							text='user name'
						/>
						<AwesomeInput
							type='password'
							name='password'
							text='password'
						/>
						<AwesomeInput
							type='password'
							name='password2'
							text='confirm password'
						/>

						<FormCheckBox
							name='remember'
							text={language.login.rememberMe}
							checked={true}
						/>

						<Agree />

						<div
							id='errorMessage'
							className='error-string'
						></div>

						<AwesomeButton
							name='join'
							text='Join'
							clickAction={ this.clickSubmit.bind(this) }
						/>

					</div>
				</div>
				<Spacer height={42} />
				{debugDiv}

			</div>
		);
	}

	setInputs() {
		if (this.inputs) return;
		this.inputs = {
			name: util.$('username-input'),
			nameContainer: util.$('username-input-container'),
			pw1: util.$('password-input'),
			pw1c: util.$('password-input-container'),
			pw2: util.$('password2-input'),
			pw2c: util.$('password2-input-container')
		}
	}

	hasErrors() {
		this.setInputs();
		return (this.inputs.pw1c.classList.contains('error')
			|| this.inputs.nameContainer.classList.contains('error')
		)
	}

	componentDidMount() {
		this.goButton = util.$("join-button");
		console.log('this.goButton:');
		console.dir(this.goButton)
		window.addEventListener('formInputChanged', this.onChange.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener('formInputChanged', this.onChange.bind(this));
	}
}

var thisPage = new AppPage({
	name: "joinPassword",
	component: (<JoinPasswordScreen />)
});

thisPage.initialize = function(data) {
	//this.myComp = null;
	return Promise.resolve();
}


export default thisPage;

