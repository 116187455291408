import AwesomeInput from './AwesomeInput'
import React, { Component } from 'react'
import language from '../Language'
import config from '../AppConfig'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import util from '../AppUtil'
import User from '../User'
import app from '../app'

import './Forgot.css'

var debug = true;

class ForgotPage extends Component
{

	render() {
		return (
			<div
				id={thisPage.name}
				className="app-page"
			>
				<div className='page-banner forgot' />
				<div id="page-title">
					oh shit
				</div>
				<div className="content-container ">
					<div className='form-container'>
						<a href="mailto:russ.duckworth@gmail.com">Email Russ</a> he will help you recover your account.
					</div>
				</div>
			</div>
		);
	}
}

var thisPage = new AppPage({
	name: "forgot",
	component: (<ForgotPage />)
});


export default thisPage;

