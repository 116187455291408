import ProfileService from '../ProfileService'
import PictureService from '../PictureService'
import { EndlessList } from './EndlessList'
import ShareService from '../ShareService'
import UserPortrait from './UserPortrait'
import React, { Component } from 'react'
import { ShareDetails } from './Common'
import ShareImage from './ShareImage'
import { Spacer } from './Common'
import Profile from '../Profile'
import Share from '../Share'
import util from '../AppUtil'
import app from '../app'

import './FeedList.css'
import './Tags.css'

var debug = true;


export class FeedListItemFull extends Component
{
	constructor(props) {
		super(props);
		this.myAuthor = React.createRef();
		if (debug) console.log("FeedListItemFull props:");
		if (debug) console.dir(this.props);
		if (this.props.share instanceof Share ) {
			this.share = this.props.share;
		} else {
			if (this.props.share.image) {
				if (debug) console.log('raw data share');
				this.share = new Share(this.props.share);
			} else {
				if (debug) console.log("no image. fetching data.");
				this.share = new Share(this.props.share)
				ShareService.getShare(this.props.share.sid)
				.then( (res) => {
					this.gotShare(res);
				})
			}
		}

		this.authorName = '';
		if (this.share.name) {
			this.authorName = this.share.name;
		}

		if (this.share.pid) {
			this.pid = this.share.pid;
			ProfileService.getProfile({ pid: this.pid })
			.then( (p) => this.gotProfile(p) )
			.catch( (e) =>{
				console.warn("no profile");
			})
		} else {
			console.error("No pid for this share.")
		}

		if (!this.share.image) {
			if (debug) console.log("no image. fetching data.");
			ShareService.getShare(this.share.id)
		}
	}

	gotShare(share) {
		if (debug) console.log("gotShare()");
		if (debug) console.dir(share);
		this.share = share;
		if (this.rendered) {
			if (debug) console.log("already rendered "+this.share.id);
			const pic = util.$('share-image-'+this.share.id);
			if (!pic) return;
			util.rmClass(pic, 'tint-main-color')
			const img = PictureService.css(this.share.image);
			pic.style.backgroundImage = img;
		}
	}

	gotProfile(profile) {
		if (profile) {
			this.profile = profile;
			this.authorName = profile.name;
			if (this.myAuthor.current) {
				this.myAuthor.current.innerText = this.authorName;
			}
		}
	}

	gotoProfile() {
		app.loadProfilePagePID( this.share.pid );	
	}

	optionsClicked() {
		console.log("optionsClicked() TODO");
		this.gotoFocus();
	}

	gotoFocus() {
		console.log("gotoFocus() this.share:");
		console.dir(this.share)
		app.goPage('feedfocus', this.share);
	}
	
	render() {
		if (debug) console.log("FeedListItemFull render. props:");
		if (debug) console.dir(this.props);

		this.rendered = true;
		
		if (this.share.text) {
			var textDisplayClass = "feed-item-text";
			if (!this.share.image && this.share.text.length < 42) {
				console.warn("TODO: word post comp")
				textDisplayClass += " large";
			}
		}

		var className = 'feed-item-full';
		className += ' endless-list-item';
		className += ' content-shadow';
		if (app.wideMode() > 0) {
			className += ' wide1'
		}

		if (this.props.addClass) {
			className += ' ' + this.props.addClass
		}

		let sidDiv = (<div />);
		if (debug) sidDiv = (<div className='fl-sid-div'>
			{this.share.id}</div>);

		return (
			<div className={className} >
				<div className='feed-item-full-top' >
					<UserPortrait
						pid={this.share.pid}
						addClass='fl-full'
					/>
					<div
						className='feed-top-center-container'
						onClick={(e) => this.gotoFocus(e)}
					>
						<div
							ref={this.myAuthor}
							className='feed-item-top-name dotdotdot'
						>
							{this.authorName}
						</div>
						<ShareDetails share={this.share} />
					</div>
					<div
						className={'feed-item-top-comments'
							+' icon-comment tint-light'}
						onClick={(e) => this.optionsClicked(e)}
					>
						{this.share.numCommentsShort}
					</div>
				</div>
				<ShareImage share={this.share} />
				{sidDiv}
			</div>
		)
	}
}

// three dots...
//		<div
//			className={"feed-item-top-options "
//						+" icon-dots tint-main-color invisible"}
//			onClick={(e) => this.optionsClicked(e)}
//		></div>

export class FeedListItem extends Component
{
	render() {
		switch (this.props.displayType) {
			case "tile": return (
				<FeedListItemTile
					share={this.props.data}
				/>);
			case "full":
			default: return (
				<FeedListItemFull
					share={this.props.data}
					addClass={this.props.addClass}
					// userImageStyle={userImageStyle}
				/>);
		}
	}
}

export class FeedList extends Component
{
	constructor(props) {
		super(props);
		if (debug) console.log("new FeedList. props:");
		if (debug) console.dir(props);

		this.endlessList = null;
		this.initialData = props.data;
		this.sortType = props.sortType;
		this.search = props.search;
		this.author = props.author;

		this.querryOptions = {
			limit: 4,
			sortType: this.sortType,
			search: this.search,
			author: this.author,
			group: props.group
		}
	}

	getData(options) {
		if (debug) console.log("getData( {...} )");
		if (debug) console.dir(options);
		return ShareService.getLatest( options )
		.then( (data) => { return data })
	}
	
	endlessListMounted(comp) {
		this.endlessList = comp;
	}

	render() {
		return (
			<div id="feed-list">
				<EndlessList
					hostPage={this}
					data={this.initialData}
					dataFetcher={this.getData}
					componentType={FeedListItem}
					options={this.querryOptions}
				/>
				<Spacer height={124} />
			</div>
		);
	}

	//componentDidMount() {}
};
