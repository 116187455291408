import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import { CommentList } from './CommentList'
import ShareService from '../ShareService'
import UserPortrait from './UserPortrait'
import React, { Component } from 'react'
import UserService from '../UserService'
import GroupSelect from './GroupSelect'
import { ShareDetails } from './Common'
import { TagsContained } from './Tags'
import ShareImage from './ShareImage'
import config from '../AppConfig'
import AppPage from '../AppPage'
import Profile from '../Profile'
import util from '../AppUtil'
import Share from '../Share'
import app from '../app'
import uu from '../uu'

import './FeedFocus.css'
import './Tags.css'

var debug = false;


class EditButton extends Component
{
	clicked() {
		console.log("EditButton clicked() feedfocus.share:");
		console.dir(feedfocus.share);
		app.goPage( 'shareEdit', {share: feedfocus.share} )
	}

	render() {
		if (!feedfocus.myPost()) return (<div />);
		return( <div
			id='ff-edit-button'
			className='ff-top-button background-icon icon-pencil tint-light'
			onClick={(e) => this.clicked(e)}
		/>)
	}
}

export class AddComment extends Component
{
	clickedPortrait() {
		this.submitComment();
	}

	submitComment(e) {
		if (debug) console.log("submitComment( )");
		const elm = util.$("comment-input");
		var comment = elm.value.trim();
		if (comment.length >= config.message.minchr) {
			feedfocus.addComment( comment );
			elm.value = "";
			this.showPlusButton(false);
		} else {
			console.warn("comment not long enough.")
		}
	}

	click(e) {
		if (!UserService.loginSuccess) {
			const detail = {
				message: 'Gotta log in first.',
				textNo: "No", icon: 'mg',
				actionYes: ( () => { app.go('join') } )
				//actionNo: ( () => { this.doNot() } )
			}
			const evt = new CustomEvent('showConfirmation', {detail});
			window.dispatchEvent( evt );
		}
	}

	change(e) {
		if (debug) console.log("change( {...} )");
		const elm = util.$('comment-input');
		var comment = elm.value;
		this.showPlusButton((comment.length >= config.message.minchr));

		const margin = (util.computedProp(elm, 'padding-top') +
			util.computedProp(elm, 'padding-bottom'));
		var newHeight = elm.scrollHeight - margin;
		newHeight = Math.min( newHeight, 200 );
		elm.style.height = (newHeight)+'px';
	}

	showPlusButton(b) {
		if (debug) console.log("showPlusButton( "+b+" )");
		var elm = util.$('add-comment-submit-button');
		elm.style.transform = b ? 'scale(1)' : 'scale(0)';
	}

	render() {
		if (debug) console.log("AddComment render. props:");
		if (debug) console.dir(this.props);

		return ( <div
			id='add-comment-container'
			className='comment-container right'
		>
			<div className='comment-bubble new clearfix' >
				<div className='comment right new' >
					<textarea
						ref={this.myInput}
						id='comment-input'
						className='comment-input'
						onChange={(e) => {this.change(e)}}
						onClick={(e) => {this.click(e)}}
						type="text"
						placeholder="new coment..."
						maxLength={config.message.maxchr}
					/>
				</div>
			</div>
			<div className='comment-arrow right' />
			<div className='comment-portrait-container right'>
				<UserPortrait addClass='comment-portrait' />
				<div
					id='add-comment-submit-button'
					onClick={(e) => this.clickedPortrait(e)}
				>
					<div className='add-comment-icon icon-add tint-ui' />
				</div>
			</div>
		</div>)
	}
}

class LocationDisplay extends Component
{
	click() {
		console.warn("todo click LocationDisplay");
	}

	render() {
		if (this.props.location) {
			return ( <div
				id='location-display'
				className='location-display'
			>
				<div
					className={'background-icon location'
						+ ' ff-info-icon icon-location'
						+ ' tint-main-color'
					}
					onClick={(e) =>{this.click()}}
				></div>
				<div className='ff-location'>
					{this.props.location}
				</div>
			</div> )
		} else {
			return (<div className='ff-no-location'></div>)
		}
	}
}

class TagDisplay extends Component
{
	click() {
		const elm = util.$('tag-container-scroll-x')
		if (!elm) return;
		elm.style.display = (this.expanded) ? 'flex' : 'block';
		this.expanded = !this.expanded;
	}

	render() {
		console.log("TagDisplay  this.props.tags:");
		console.dir(this.props.tags);
		var renderElm = (<div />);
		if (this.props.tags) {
			if (this.props.tags.length > 0) {
				renderElm = (
					<div id='ff-tag-display'>
						<div
							className='ff-info-icon icon-tag tint-main-color'
							onClick={(e) =>{this.click()}}
						/>
						<TagsContained tags={this.props.tags} />
					</div>
				)
			}
		}
		return renderElm;
	}
}

class LoaderImage extends Component
{
	render() {return(<div id='loader-image-container'>
			<img id='loader-image'
				src={ PictureService.url(this.props.image) }
				onLoad={() => {feedfocus.onImageLoad(util.$('loader-image'))} }
			/>
		</div>)	
	}
}


class FeedFocusTop extends Component
{
	clickName() {
		app.goPage('profile', this.props.profile)
	}

	optionsClicked() {
		console.warn("todo click options");
		alert("options coming soon.")
	}

	clickTopCenter() {
		console.warn("clickTopCenter()");
		app.loadProfilePagePID( this.props.share.pid );
	}

	render() {
		return( <div id='feed-focus-header'>
			<UserPortrait
				profile={this.props.profile}
				addClass='feed-focus'
			/>
			<div
				className='ff-top-center-container'
				onClick={(e) => this.clickName(e)}
			>
				<div className='ff-top-name'>
					{this.props.profile.name}
				</div>
				<ShareDetails share={this.props.share} />
			</div>
			<div><EditButton /></div>
		</div> )
	}
}

class PhotoDate extends Component
{
	render() {
		const date = this.props.share.date;
		return date ? (<div> Photo Date: { date }</div>) : (<div/>);
	}
}

class FeedFocusPage extends Component
{
	render() {
		if (debug) console.log("FeedFocusPage render.");
		if (debug) console.dir(feedfocus.share);

		return (
			<div id="feedfocus" className="app-page">
				<FeedFocusTop
					share={feedfocus.share}
					profile={feedfocus.profile}
				/>
				<ShareImage share={feedfocus.share} />
				<PhotoDate share={feedfocus.share}/>
				<LocationDisplay location={feedfocus.share.location} />
				<TagDisplay tags={feedfocus.share.tags} />
				<AddComment />
				<CommentList
					hostPage={feedfocus}
					sid={feedfocus.share.id}
				/>
				<LoaderImage image={feedfocus.share.image} />
			</div>
		);
	}
}


var feedfocus = new AppPage({
	name: 'feedfocus', component: (<FeedFocusPage />)
});

feedfocus.initialize = function(share) {
	if (debug) console.log("feedfocus.initialize( {...} ) ");
	if (debug) console.dir(share);
	this.wordPost = false;

	if (!isNaN(share)) {
		console.log("share is a number " + share);
		return ShareService.getShare( share )
		.then( (res) => {
			return this.initialize( res );
		})
		.catch( (err) => {
			return Promise.reject(err);
		})
	}
	
	if (share instanceof Share) {
		this.share = share;
	} else {
		if (this.share) {
			if (debug) console.warn("use previous share.");
		} else {
			return Promise.reject("no share");
		}
	}

	if (this.share.pid) {
		if (debug) console.log("get the profile");
		return ProfileService.getProfile(
			{pid: this.share.pid, useCache: "only"}
		)
		.then( (p) => {
			this.profile = p;
			if (debug) console.log("got everything we need.");
			return Promise.resolve();
		})
		.catch( (e) => {
			console.error("no cached profile");
			this.profile = new Profile({});
		})
	} else {
		console.error("no pid in share");
		this.profile = new Profile({});
	}

	return Promise.resolve();
}

feedfocus.myPost = function () {
	return (this.share.pid == ProfileService.me.pid)
}

feedfocus.setCommentList = function(cl) {
	this.commentList = cl;
}

feedfocus.addComment = function( comment ) {
	feedfocus.submitting = true;
	comment = comment.trim();
	ShareService.addComment(
		feedfocus.share.id,
		comment
	)
	.then( () => {
		console.log("refresh the list after pause");
		uu.wait(200)
		.then( () => {
			this.commentList.checkForNew()
			uu.wait(500) // replace with event
			.then( () => {
				feedfocus.submitting = false;
				var e = util.$(".app-page");
				var scrollToHere = (feedfocus.picHeight) + 112;
				if (e.scrollTop > scrollToHere) {
					console.log(" scrollToHere: " + scrollToHere);
					e.scrollTop = scrollToHere;
				}
			})
		})
	})
}

feedfocus.onImageLoad = function(img) {
	console.log("onImageLoad( {} )");
	const picHeight = util.computedProp( img, 'height' );
	const picWidth = util.computedProp( img, 'width' );
	const areaWidth = app.getMainContentWidth();
	const ratio = areaWidth / picWidth;
	var adjustedHeight = picHeight * ratio;
	adjustedHeight = Math.min( adjustedHeight, app.getContentAreaHeight() );
	console.log("adjustedHeight: " + adjustedHeight);
	img.style.display = "none";
	var elm = util.$('.feed-list-item-pic-container');
	if (elm) {
		elm.style.height = adjustedHeight+"px";
		feedfocus.picHeight = adjustedHeight;
	}
}

feedfocus.getPathName = function() {
	return "s-" + this.share.id;
}

feedfocus.cleanUp = function() {
	console.log("cleanUp()");
	try {
		this.commentList.cleanUp();
	} catch (err) {
		console.error("problem cleaning up feed focus");
		console.error(err)
	}
}

export default feedfocus


