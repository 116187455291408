import React, { Component } from 'react'
import util from '../AppUtil'

import './Common.css'

var debug = false;

export default class AwesomeButton extends Component
{
	constructor(props) {
		super(props);
		this.myInput = React.createRef();
		this.myBouncer = React.createRef();
		this.myContainer = React.createRef();
		this.addClass = '';
		if (this.props.addClass) {
			this.addClass = this.props.addClass;
		}
		this.text = "Press Me";
		if (this.props.text) {
			this.text = this.props.text;
		}
		this.textActive = "Processing";
		if (this.props.textActive) {
			this.textActive = this.props.textActive;
		}
	}

	click() {
		if (debug) console.log("click()");
		if (debug) console.dir(this.props.clickAction)
		//if (this.dead) return;
		if (this.props.clickAction) {
			var accepted = this.props.clickAction();
			if (debug) console.log("accepted: " + accepted);
			if (accepted) {
				this.activate();
			}
		}
		else {
			this.activate();
		}
	}

	activate() {
		if (debug) console.log("activate()");
		this.myInput.current.innerText = this.textActive;
		util.addClass(this.myInput.current, 'disabled');
		util.rmClass(this.myBouncer.current, 'hide-bouncer');
	}

	reset() {
		if (debug) console.log("reset()");
		if (debug) console.dir(this.myInput.current);
		util.addClass(this.myBouncer.current, 'hide-bouncer');
		util.rmClass(this.myInput.current, 'disabled');
		this.myInput.current.innerText = this.text;
	}

	render() {
		return(
			<div
				id={this.props.name+'-button-container'}
				className='awesome-button-container'
			>
				<button
					ref={this.myInput}
					id={this.props.name+'-button'}
					type='button'
					className={'button long '+this.addClass }
					onClick={(e) => this.click(e)}
				>
					{this.text}
				</button>
				<div
					ref={this.myBouncer}
					id='progress-indicator'
					className='bouncer-container hide-bouncer'
				>
					<div id='bouncer' />
				</div>
			</div>
		)
	}

	componentDidMount() {
		if (this.props.value) {
			this.myInput.current.value = this.props.value;
			this.change();
		}
		window.addEventListener('awesomeButtonReset', this.reset.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener('awesomeButtonReset', this.reset.bind(this));
	}

}