import ProfileService from '../ProfileService'
import PictureService from '../PictureService'
import ShareService from  '../ShareService'
import UserPortrait from './UserPortrait'
import UserService from '../UserService'
import React, { Component } from 'react'
import { FeedList } from './FeedList'
import { Spacer } from './Common'
import AppPage from '../AppPage'
import Profile from '../Profile'
import util from '../AppUtil'
import User from '../User'
import app from '../app'

import './Profile.css'

var debug = false;

class NameDisplay extends React.Component
{
	clicked() {
		console.log("handleClick()");
		if (thisPage.isMe()) app.goPage('profileEdit')
	}
	render() {
		return (
			<div
				className='dotdotdot'
				id='myname'
				onClick={(e) => this.clicked(e)}
			>
				<h1>{this.props.name}</h1>
			</div>
		);
	}
}

class LocationDisplay extends React.Component
{
	clicked() {
		console.log("LocationDisplay clicked()");
		if (thisPage.isMe()) app.goPage('profileEdit')
	}

	render() {
		this.locationIconSize = "16pt";

		this.location = "gypsy";
		if (this.props.city) {
			this.location = this.props.city;
			if (this.props.state) {
				this.location += ", " + this.props.state;
			}
		} else if (this.props.state) {
			this.location = this.props.state;
		}

		return (
			<div
				className="location-container"
				onClick={(e) => this.clicked(e)}
			>
				<div
					className="location-icon icon-location tint-main-color"
				/>
				{"\u00a0"}{this.location}
			</div>
		);
	}
}

class AboutDisplay extends React.Component
{
	clicked() {
		console.log("AboutDisplay clicked()");
		if (thisPage.isMe()) app.goPage('profileEdit')
	}

	render() {
		if (debug) console.log("render AboutDisplay. props:");
		if (debug) console.dir(this.props)
		if (!this.props.text || this.props.text === '') {
			if (thisPage.isMe()) {
				this.text = "About you? About who. Describe yourself here."
			} else {
				this.text = "No bigraphical information given."
			}
		} else {
			this.text = this.props.text;
		}
		return (
			<div
				id="myabout"
				onClick={(e) => this.clicked(e)}
			>
				<p>{this.text}</p>
			</div>
		);
	}
}


class ProfileEditButton extends React.Component
{
	clicked() {
		console.log("ProfileEditButton clicked()");
		app.goPage("profileEdit");
	}

	render() {
		if (!thisPage.isMe()) {
			return(<div />);
		}
		return (
			<div
				id="pe-edit-button"
				onClick={(e) => this.clicked(e)}
				className="background-icon icon-pencil tint-light">
			</div>
		)
	}
}

class MessageButton extends React.Component
{
	click() {
		console.log("MessageButton clicked()");
		app.goPage("conversation", thisPage.current );
	}

	render() {
		if (thisPage.isMe()) {
			return(<div />);
		}
		return (
			<button
				onClick={(e) => this.click(e)}
				type="button"
				className="button long"
				id="buttonTheme"
			>
				{"Send "+thisPage.current.displayName+" a message"}
			</button>
		)
	}
}

class AlignButton extends React.Component
{
	clicked() {
		console.log("AlignButton clicked()");
		if (this.aligned) {
			ProfileService.unAlign(
				thisPage.current.pid,
				this.onUnAlign.bind(this)
			);
			util.$("align-button").classList.remove('icon-heart-full');
			util.$("align-button").classList.add('icon-heart');
		} else {
			ProfileService.align(
				thisPage.current.pid,
				this.onAlign.bind(this)
			);
			util.$("align-button").classList.add('icon-heart-full');
			util.$("align-button").classList.remove('icon-heart');
		}
	}

	onAlign() {
		console.log("onAlign()");
		this.aligned = true;
		var button = document.getElementById("align-button");
	}

	onUnAlign() {
		console.log("onUnAlign()");
		this.aligned = false;
		var button = document.getElementById("align-button");
	}

	render() {
		var buttonClassName = "flyout-button icon-heart tint-ui";
		this.aligned = null;
		if ( ProfileService.me.follows( thisPage.current.pid ) ){
			if (debug) console.log("I'm following this user");
			this.aligned = true;
			buttonClassName = "flyout-button icon-heart-full tint-ui";
		} else {
			if (debug) console.log("I'm not following this user");
		}
		return (
			<div
				id="align-button"
				onClick={(e) => this.clicked(e)}
				className={buttonClassName}
			/>
		)
	}
}

class DebugStuff extends Component {
	render() {
		if (!debug) {
			return (<div />)
		}
		return (
			<div>
				<p>{"pid: " + thisPage.current.pid }</p>
				<p>{"uid: " + UserService.uid }</p>
				<p>{"username: " + UserService.username }</p>
			</div>
		)
	}
}

class PostYourFirstThing extends Component {

	clicked() {
		console.log("PostYourFirstThing clicked()");
		app.goPage('shareEdit');
	}

	render() {
		return (
			<div
				id="first-post-encourage"
				onClick={(e) => this.clicked(e)}
			>
				<button
					type="button"
					className="button long"
				>
					Share a Photo
				</button>
			</div>
		)
	}

	onEmptyList() {
		if (thisPage.isMe()) {
			var elm = util.$('first-post-encourage');
			if (elm) elm.style.display = 'grid';
		}
		var title = util.$('page-title');
		if (title) title.innerHTML = "Nothing Shared";
	}
	
	componentDidMount() {
		var elm = util.$('first-post-encourage');
		if (!UserService.loginSuccess) {
			elm.style.display = 'grid';
		}

		window.addEventListener(
			'showEmptyListDisplay', this.onEmptyList );
	}

	componentWillUnmount() {
		window.removeEventListener(
			'showEmptyListDisplay', this.onEmptyList );
	}
}

class ProfilePage extends Component
{
	clickMessage() {
		console.log("clickMessage()");
		app.goPage("conversation", thisPage.current );
	}

	clickedPortrait() {
		if (debug) console.log('clickedPortrait()');
		if (thisPage.isMe()) {
			app.goPage('profileEdit');
		} else {
			this.expandPortrait(!this.portraitExpanded);
		}
	}

	expandPortrait(b) {
		if (debug) console.log("TODO! expandPortrait( "+b+" )");
	}


	render() {
		if (debug) console.log("ProfilePage render()");
		if (debug) console.dir(thisPage.current);
		if (!thisPage.current) {
			return(<div id="profile" className="app-page">No data</div>)
		}

		this.titleText = "";
		if (thisPage.isMe()) {
			this.titleText += "Your";
		} else {
			this.titleText += thisPage.current.displayName;
			this.titleText += "'s";
		}
		this.titleText += " Public Posts";

		var feedList = (<div />);
		if (ProfileService.me.pid) {
			feedList = (<FeedList author={thisPage.current.pid} />);
		}

		return (
			<div id='profile' className='app-page'>
				<div className='content-container'>
					<UserPortrait
						addClass='profile'
						profile={thisPage.current}
						clickAction={(e) => {this.clickedPortrait()}}
					/>
					<NameDisplay 
						name={thisPage.current.displayName}
					/>
					<LocationDisplay
						city={thisPage.current.city.value}
						state={thisPage.current.state.fullName}
					/>
					<AboutDisplay
						text={thisPage.current.aboutText.value}
					/>
					<DebugStuff />
					<MessageButton />
					<ProfileEditButton />
				</div>
				<div id='page-title' className='dotdotdot'>
					{this.titleText}</div>
				<PostYourFirstThing />
				
				<div id='feed-container'>
					{feedList}
				</div>
			</div>
		);
	}

	componentDidMount() {
		// shrink my profile portrait in the header
		if (thisPage.isMe()) {
			if (debug) console.log("hide profile portrait on my profile page");
		}
	}
}


var thisPage = new AppPage({
	name: "profile",
	component: (<ProfilePage />)
});


thisPage.initialize = function(p) {
	if (debug) console.log("initialize( {...} )");
	if (debug) console.dir(p);
	this.current = null;

	if (!p) {
		if (debug) console.log("no data. Do user profile.");
		p = ProfileService.me;
	}

	if (!isNaN(p)) {
		if (debug) console.log("init with number");
		return ProfileService.getProfile({ pid: p })
		.then( (profile) => {
			return this.initialize( profile );
		})
	} else {
		if (debug) console.log("init with object");
		if (p instanceof Profile) {
			this.current = p;
			return Promise.resolve();
		} else {
			if (debug) console.log("data object:");
			if (debug) console.dir(p);
			return ProfileService.getProfile(
				{ pid: p.id, useCache: false }
			)
			.then( (res) => {
				this.current = res;
				return Promise.resolve();
			})
			.catch( (err) => {
				return Promise.reject("no cached profile.");
			})
		}
	}
}

thisPage.isMe = function() {
	return (thisPage.current.pid === ProfileService.me.pid);
}

thisPage.getPathName = function() {
	return "p-" + this.current.pid;
}

export default thisPage;

