import { Spacer, ProgressIndicatorButton } from './Common'
import { ProfileListItemMe,
				ProfileListItemAdd,
				ProfileListItemRm } from './ProfileList'
import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import { EndlessList } from './EndlessList'
import AwesomeButton from './AwesomeButton'
import ShareService from '../ShareService'
import React, { Component } from 'react'
import config from '../AppConfig'
import ReactDOM from 'react-dom'
import AppPage from '../AppPage'
import Profile from '../Profile'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import './Group.css'

var debug = true;

class GroupName extends Component
{
	render() { return(
		<div
			id='group-name-container'
			className='input-container'
		>
			<div
				className='button-icon icon-group tint-main-color'
			/>
			<input
				id='group-name-input'
				className='text-input'
				type='text'
				placeholder='Group Name'
				onChange={() => {thisPage.clearError()}}
			/>
		</div>)
	}

	componentDidMount() {
		if (thisPage.group.name) {
			util.$('group-name-input').value = thisPage.group.name;
		}
	}
}

class Members extends Component
{
	render() { return ( <div>
			<div id='page-title'>Members</div>
			<ProfileListItemMe />
			<div id='group-member-list'></div>
		</div>)
	}
}


class SearchBar extends Component
{
	render() { return (
		<div id='group-search-bar'><div />
			<div
				name='search-page-search-button'
				className='button-icon icon-search tint-light'
			/>
			<SearchQuerryInput />
		</div>)
	}
}

class SearchQuerryInput extends Component
{
	changed (e) {
		this.submit();
	}

	keydown (e) {
		var keyCode = ('which' in e) ? e.which : e.keyCode;
		if (keyCode == 13) this.submit(e); // Return Key
	}

	submit() {
		if (debug) console.log("submit");
		thisPage.search(
			util.$('search-input').value.trim()
		)
	}

	render() { return (
		<div
			id='search-query'
			//onClick={(e) => this.clicked(e)}
		>
			<input
				id='search-input'
				className='text-input search fill-avail'
				type='search'
				placeholder='Search profiles'
				onChange={(e) => {this.changed(e)}}
				onKeyDown={(e) => {this.keydown(e)}}
			/>
		</div>)
	}
}


class GroupEditPage extends Component
{
	render() {
		console.log("render GroupEditPage");
		return (
			<div
				id={thisPage.name}
				className='app-page'
			>
				<GroupName />
				<Members />
				<div id='page-title'>Add People</div>
				<SearchBar />
				<EndlessList
					hostPage={thisPage}
					//data={thisPage.initialData}
					dataFetcher={thisPage.getData}
					options={{}}
					componentType={ProfileListItemAdd}
				/>
				<div
					id='ge-error-display'
					className='bb-error'
				/>
				<div
					id='group-save'
					className='content'
				>
					<AwesomeButton
						key={'group-edit-button-save'} 
						name='save'
						text={thisPage.editing?"Save Changes":"Save"}
						textActive='Saving'
						clickAction={thisPage.submit.bind(thisPage)}
					/>
				</div>
			</div>
		);
	}

	componentDidMount() {
		console.log("GroupEditPage componentDidMount");
		thisPage.displayMembers();
	}
}

var thisPage = new AppPage({
	name: 'groupEdit',
	component: (<GroupEditPage />)
});

thisPage.initialize = function(data) {
	if (debug) console.log("initialize( {...} )");
	if (debug) console.dir( data );
	this.members = [];
	this.query = '';
	this.group = {};
	this.addEventListiners();
	if (data) {
		console.log("Edit group");
		thisPage.editing = true;
		this.group = data;
		this.oldMembers = data.members;
		return ProfileService.getProfile({ pid: data.members })
		.then( (profiles) => {
			if (Array.isArray(profiles)) this.members = profiles;
		})
	} else {
		return Promise.resolve();
	}
}

thisPage.onClickAddProfile = function(e) {
	console.warn("onClickAddProfile( {} )");
	console.dir(e.detail);
	for (let i = this.members.length-1; i >= 0; i--) {
		if (e.detail.pid === this.members[i].pid) {
			return console.log("member already added.");
		}
	}
	this.members.push(e.detail);
	this.displayMembers();
	this.search();
	app.scrollToTop();
	thisPage.clearError();
}

thisPage.onClickRemoveProfile = function(e) {
	console.warn("onClickRemoveProfile( {} )");
	console.dir(e.detail);
	let message = "Revoking "+e.detail.name+"'s access to group ";
	message += " is a pretty harsh move.";
	message += " Alternativley, you can make a new group that does not include them."
	message += " Are you sure you wanna start drama? This action cannot be undone."
	if (thisPage.editing) {
		util.dispatch('showConfirmation', {
			title: "Remove Member?", message,
			textYes: "Fuck 'em!", icon: 'person',
			actionYes: ( () => { this.kickMember(e.detail.pid) } )
		})
	} else {
		this.removeMember( e.detail.pid );
	}
}

thisPage.kickMember = function(pid) {
	ProfileService.kickGroup(this.group.id, pid);
	this.removeMember( pid );
}
thisPage.removeMember = function(pid) {
	for (let i = this.members.length-1; i >= 0; i--) {
		if (this.members[i].pid === pid) {
			this.members.splice(i, 1);
		}
	}
	// refresh list
	this.search();
	this.displayMembers();
}

thisPage.displayMembers = function() {
	console.log("displayMembers()");
	console.dir(this.members)
	const container = util.$('group-member-list');
	if (!container) return;
	let a = [];
	this.members.forEach( p => {
		if (p.pid === ProfileService.pid) return;
		a.push(<ProfileListItemRm data={p} key={'gem-'+p.pid} />);
	})
	ReactDOM.render(a, container);
}

thisPage.validateName = function() {
	let name = util.$('group-name-input').value.trim();
	if (!name || !name.length) return false;
	if ( name.length < config.group.name.minchr
		|| name.length > config.group.name.maxchr)
	{ return false }
	return name;
}

thisPage.submit = function() {
	console.log("submit "+this.members.length);
	if (this.members.length === 0) {
		this.showError("no members");
		return false;
	}
	let name = thisPage.validateName();
	if (!name) {
		this.showError("unacceptable group name");
		return false;
	}
	let a = [];
	this.members.forEach( m => { a.push(m.pid) })
	let editData = { name, members: a };
	if (this.editing) {
		console.error("new members:")
		editData.id = this.group.id;
		editData.newMembers = this.newMembers(a);
		console.dir(editData)
	} else {
		console.error("wtf bbq")
	}
	ProfileService.editGroup( editData )
	.then(res => {
		console.log("response to new group");
		util.wait(1000).then(() => { app.go('groups') })
	})
	.catch(err => { thisPage.showError(err) })
	return true;
}

thisPage.newMembers = function(members) {
	let newMembers = [];
	members.forEach( pid => {
		if (!this.oldMembers.includes(pid)) {
			newMembers.push(pid);
		}
	})
	return newMembers;
}

thisPage.showError = function( s ) {
	let elm = util.$('ge-error-display');
	if (!elm) return;
	if (!s) {
		elm.innerText = "";
		util.rmClass(elm, 'display-block');
		return;
	}
	util.addClass(elm, 'display-block');
	elm.innerText = s;

	elm = util.$('save-button');
	if (!elm) return;
	util.addClass( elm, 'disabled' );
}

thisPage.clearError = function() {
	let elm = util.$('ge-error-display');
	if (!elm) return;
	util.rmClass(elm, 'display-block');
	elm = util.$('save-button');
	if (!elm) return;
	util.rmClass( elm, 'disabled' );
}

thisPage.search = function(query) {
	console.log("search()");
	if (query) {
		thisPage.query = query;
	}
	if (this.endlessList) {
		this.endlessList.reset(true);
	}
}

thisPage.getData = function(options) {
	console.log("getData query: "+thisPage.query);
	return ProfileService.getProfiles({
		sort: 'search',
		offset: options.offset,
		amount: 20,
		search: thisPage.query
	})
	.then( (res) => {
		let r = [];
		for (let i = 0; i < res.length; i++) {
			let alreadyMember = false;
			for (let j = 0; j < thisPage.members.length; j++) {
				if (thisPage.members[j].pid === res[i].pid) {
					alreadyMember = true; break;
				}
			}
			if (!alreadyMember) r.push(res[i]);
		}
		console.dir(r)
		return Promise.resolve(r);
	})
}

thisPage.endlessListMounted = function(comp) {
	if (debug) console.log("endlessListMounted( {...} )");
	this.endlessList = comp;
}

thisPage.addEventListiners = function() {
	window.addEventListener('profileListItemAddClick',
		this.onClickAddProfile.bind(this));
	window.addEventListener('ProfileListItemRmClick',
		this.onClickRemoveProfile.bind(this));
}

thisPage.cleanUp = function() {
	window.removeEventListener('profileListItemAddClick',
		this.onClickAddProfile.bind(this));
	window.removeEventListener('ProfileListItemRmClick',
		this.onClickRemoveProfile.bind(this));
}

export default thisPage;
