import ProfileService from '../ProfileService'
import MessageDisplay from './MessageDisplay'
import UserPortrait from './UserPortrait'
import React, { Component } from 'react'
import ChatService from '../ChatService'
import { Spacer } from './Common'
import config from '../AppConfig'
import ReactDOM from 'react-dom'
import AppPage from '../AppPage'
import Message from '../Message'
import Profile from '../Profile'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import './Conversation.css'

var debug = false;

class MessageInput extends React.Component
{
	clickSend() {
		if (debug) console.log("MessageInput slickSend()");
		this.submit();
	}

	submit() {
		var input = util.$('message-input-text');
		if (!input) return;
		var text = input.value.trim();
		if (text.length === 0) return;
		input.value = '';
		const defaultHeight = 24; // from .message-input-text
		//console.log("TODO: get css value remove hard code");
		input.style.height = defaultHeight+'px';
		thisPage.sendMessage(text)
	}

	keydown (e) {
		var keyCode = ('which' in e) ? e.which : e.keyCode;
		if (keyCode == 13) { // Return Key
			util.wait(100) // keystroke update input before submit
			.then( () => { this.submit() })
		}
	}

	change(e) {
		const element = util.$('message-input-text');
		var pt = util.gcs(element,'padding-top');
		var pb = util.gcs(element,'padding-bottom');
		var newHeight = element.scrollHeight - (pt+pb);
		newHeight = Math.min(newHeight, 250);
		element.style.height = newHeight+'px';
	}

	render() {
		return (
			<div id='message-input-container'>
				<div id='text-input-container'>
					<textarea
						ref={this.myInput}
						maxLength={config.message.maxchr}
						onKeyDown={(e) => {this.keydown(e)}}
						onChange={(e) => {this.change(e)}}
						id='message-input-text'
						className='message-input-text'
						placeholder="compose a message"
					/>
				</div>
				<div className='conversation-send-button'>
					<div
						onClick={(e) => this.clickSend(e)}
						type='button'
						className={'background-icon '
							+ ' conversation-send-icon'
							+ ' tint-ui icon-add'
						}
						id='sendMessageButton'
					/>
				</div>
			</div>
		);
	}
}


class ConversationPage extends React.Component
{
	constructor(props) {
		super(props);
		this.messages = [];
	}

	render() {
		this.numMessages = thisPage.messages.length;
		for (var i = 0; i < this.numMessages; i++) {
			const newMess = new Message(thisPage.messages[i]);
			this.messages.push(
				<MessageDisplay
					key={'mess-'+newMess.id}
					message={newMess}
					convo={thisPage.convo}
				/>
			);
		}

		var pageTitle = thisPage.profile.name;
		if (debug) {
			pageTitle = "convo " + thisPage.convo.id;
			pageTitle += " with "+thisPage.profile.id;
		}

		return (
			<div
				id={thisPage.name}
				className="app-page"
			>
				<div id='convo-top' className='dotdotdot'>
					<div id='convo-title' className='dotdotdot'>
						{pageTitle}
					</div>
				</div>
				<MessageInput />
				<UserPortrait
					profile={thisPage.profile}
					addClass='conversation'
				/>
				<div
					id='message-scroll-area'
					className='conversation-container clearfix'
				>
					<Spacer height={102} />
					{this.messages}
				</div>
				<Spacer height={92} />
			</div>
		)
	}

	componentDidMount() {
		app.sizePage(); // force the page container to adjust.
		thisPage.scrollToBottom();

		util.wait(1000)
		.then( () => {
			thisPage.readIt();
		})
	}
}


var thisPage = new AppPage({
	name: 'conversation',
	component: (<ConversationPage />)
});


thisPage.initialize = function( data ) {
	if (debug) console.log("initialize( {...} ) ");
	if (debug) console.dir(data);
	const badInput = "cannot init conversation page. bad input.";
	if (!data) Promise.reject(badInput);

	this.convo = null;
	this.profile = null;
	this.sending = false;
	var justProfile = false;

	if (!isNaN(data)) {
		console.log("init with a number");
		return ProfileService.getProfile({pid: data})
		.then( (profile) => {
			return thisPage.initialize(profile);
		})
	}

	if (data instanceof Profile) {
		console.log("init with just profile");
		this.profile = data;
		justProfile = true; 
	} else {
		if (data.profile) {
			console.log("init with convo profile combo");
			this.profile = data.profile;
			this.convo = data;
		} else {
			console.error("no profile in that convo")
		}
	}
	if (!this.profile) Promise.reject(badInput);

	this.pid = this.profile.pid;
	this.messages = [];
	this.stopPolling = false;

	console.log("now get all messages");
	return this.getMessages()
	.then( (messages) => {
		// console.log("response:");
		// console.dir(messages);
		if (!messages || messages.length === 0) {
			if (debug) console.log("zero messages");
			this.addBeginMessage();
		} else {
			//if (debug) console.log("mark them all saved");
			for (var i = 0; i < messages.length; i++) {
				this.messages[i] = new Message(messages[i]);
			}
			thisPage.addDatesToMessages();
			console.dir(this.messages)
		}
		if (justProfile) {
			if (debug) console.log("now get the convo");
			return ChatService.getConversationWith(this.profile.id)
			.then( (convo) => {
				console.log("got convo. init complete.");
				console.dir(convo);
				this.convo = convo;
				return Promise.resolve();
			})
		} else {
			console.log("init complete.");
			return Promise.resolve();
		}
	})
	.catch( (err) => {
		console.error("proglem getting messages");
		console.error(err);
	})
	return Promise.reject();
}

thisPage.addBeginMessage = function() {
	this.messages.push({
		to: -1, from: -1,
		text: "Begining of chat history with "+this.profile.displayName
	})
}

thisPage.getMessages = function() {
	if (debug) console.log("getMessages()");
	return ChatService.getMessagesWith({
		pid: this.pid,
		id: this.getNewestMsgId()
	})
	.then( (messages) => {
		if (debug) console.log("got messages:");
		if (debug) console.dir(messages);
		if (messages.length !== 0) {
			// messages.forEach(msg => {

			// })
			return messages;
		} else {
			if (debug) console.log("No messages with");
			return null;
		}
	})
}

thisPage.getNewestMsgId = function() {
	if (debug) console.log("getNewestMsgId()")
	if (debug) console.dir(this.messages)
	if (this.messages.length === 0) return 0;
	return this.messages[this.messages.length-1].id;
}

thisPage.addNewMessages = function(messages) {
	if (debug) console.log("addNewMessages( {...} )");
	if (debug) console.dir(messages);
	if (!messages || messages.length === 0) return;
	const numNewMessages = messages.length;
	var newMessages = [];
	if (debug) console.log("show "+numNewMessages+" new message(s)");
	for (var i = 0; i < messages.length; i++) {
		var msg = new Message(messages[i]);
		newMessages.push( msg );
		this.messages.push( msg );
	}
	thisPage.addDatesToMessages();
	this.renderMore( newMessages );
}

// add dates in between messages 
thisPage.addDatesToMessages = function() {
	if (debug) console.log("addDatesToMessages()");
	var i = this.messages.length - 1;
	var date; var prevDate;

	while (i >= 0) {
		console.log(i)
		date = new Date(this.messages[i].created_at);

		if (!prevDate) {
			prevDate = date;
			console.log("first message.");
		} else {
			if (!(date.getDate() === prevDate.getDate()
				&& date.getMonth() === prevDate.getMonth()
				&& date.getFullYear() === prevDate.getFullYear()
			)){
				thisPage.spliceInDate(i, prevDate)
			}
			prevDate = date;
		}
		i--;
	}
	// add the date of the first message on top
	console.log("do first date");
	thisPage.spliceInDate(-1,
		new Date(this.messages[0].created_at));
}

thisPage.spliceInDate = function(index, date) {
	if (debug) console.log("spliceInDate "+index)
	var text = (util.months[ date.getMonth() ]+" "
		+util.zpad(2, date.getDate())+" "+date.getFullYear()
	);
	this.messages.splice( (index + 1), 0,
		new Message({
			to: -1, from: -1, text, id: ( index * -1 )
		})
	);
}

thisPage.sendMessage = function(text) {
	if (debug) console.log("send the message: " + text);
	if (this.sending) {
		const msg = "send one at a time";
		console.warn(msg);
		Promise.reject(msg);
	}
	this.sending = true;
	var newMess = new Message({
		from: ProfileService.me.pid,
		to: this.pid,
		text: text,
		tid: Date.now()
	});

	if (debug) console.log("draw my new message");
	this.messages.push(newMess);
	this.renderMore( [newMess] );

	return ChatService.sendMessage(newMess)
	.then( (res) => {
		this.sending = false;
		return Promise.resolve(res);
	})
	.catch( (err) => {
		console.error("error sending message");
		newMess.sendError(err)
		console.error(err);
		console.log("TODO: show unsent with resend button");
		this.sending = false;
	})
}

// TODO: don't load all messages, scroll up to load history
thisPage.scrollToBottom = function() {
	//if (debug) console.log("scrollToBottom()");
	app.scrollToBottom();
}

thisPage.renderMore = function(messages) {
	if (debug) console.log("renderMore( [...] )");
	if (debug) console.dir(messages);
	var currentDiv = document.getElementById('message-scroll-area');
	if (!currentDiv) return;
	var newDiv = document.createElement('div');
	var arr = [];
	for (var i = 0; i < messages.length; i++) {
		arr.push(
			<MessageDisplay
				key={'msg-'+messages[i].id}
				message={messages[i]}
				convo={thisPage.convo}
			/>)
	}
	currentDiv.appendChild( newDiv );
	ReactDOM.render( arr, newDiv );
	try {
		document.getElementById("loading").style.display = "none";
	} catch (e) {}
	return uu.wait(100).then( () => { this.scrollToBottom() })
}

thisPage.readIt = function() {
	if (debug) console.log("readIt()  this.convo:");
	if (debug) console.dir(this.convo);
	if (!this.convo) { // cleanUp was called
		console.log("do not mark read");
		return;
	}
	ChatService.readConversation(
		this.convo.id, this.pid, this.getNewestMsgId()
	);
}

thisPage.cleanUp = function() {
	if (debug) console.log("cleanUp()");
	thisPage.pid = null;
	thisPage.convo = null;
	thisPage.profile = null;
}

thisPage.convoUpdate = function(convo) {
	if (debug) console.log("convoUpdate()");
	if (debug) console.dir(convo);
	if (convo.you === thisPage.pid) {
		console.log("captured update for current conversation");
		console.warn("TODO: they are typing update");
		
		// mark any newly read messages as such
		for (var i = this.messages.length-1; i >= 0; i--) {
			if (this.messages[i].id <= convo.read) {
				if (this.messages[i].read) break;
				console.log("----> mark "+this.messages[i].id+" read!");
				this.messages[i].read = true;
				var check2 = util.$(this.messages[i].tid+'-check2');
				if (check2) {
					util.addClass(check2, 'checked')
				}
			}
		}

		// get any new messages
		thisPage.getMessages()
		.then( (res) => {
			if (res) {
				this.addNewMessages(res);
				this.readIt();
			}
		})
		.catch( (err) => {
			console.error("problem getting messages on convo update")
			console.error(err);
		})
	}
}

window.addEventListener('convoUpdate', (e) => {
	console.log('responding to convoUpdate');
	thisPage.convoUpdate(e.detail)
});

thisPage.messageSaved = function(msg) {
	if (debug) console.log("messageSaved()");
	if (debug) console.dir(msg);
	if (msg.to === thisPage.pid) {
		var i = this.messages.length;
		while (i > 0) {
			if (this.messages[--i].tid === msg.tid) {
				console.log("  found newly sent message at "+i);
				this.messages[i].id = msg.id;
				const check1 = util.$(msg.tid+'-check1');
				if (check1) {
					util.addClass(check1, 'checked');
				}
				const top = util.$(msg.tid+'-top');
				if (top) {
					top.innerText = msg.id;
				}
			}
		}
	} else {
		console.log("not open conversation");
	}
}

window.addEventListener('messageSaved', (e) => {
	console.log('responding to messageSaved');
	thisPage.messageSaved(e.detail)
});


export default thisPage;

