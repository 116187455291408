import { Spacer, ProgressIndicatorButton } from './Common'
import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import { KalmanFilter } from 'kalman-filter'
import AwesomeButton from './AwesomeButton'
import ShareService from '../ShareService'
import React, { Component } from 'react'
import GroupSelect from './GroupSelect'
import datepicker from 'js-datepicker'
import LocalStore from '../LocalStore'
import ShareImage from './ShareImage'
import config from '../AppConfig'
import AppPage from '../AppPage'
import Profile from '../Profile'
import util from '../AppUtil'
import Share from '../Share'
import Tag from '../Tag'
import geo from '../geo'
import app from '../app'
import uu from '../uu'
import './ShareEdit.css'


const observations = [[0, 1], [0.1, 0.5], [0.2, 3], [4, 2], [1, 2]];
const kFilter = new KalmanFilter({observation: 2});
const res = kFilter.filterAll(observations);
console.dir(res);

const TRACK_TIME = 2000;
const FEED_IN_MILE = 5280;
const MIN_DIST_FEET = 5;
const MIN_DIST = MIN_DIST_FEET / FEED_IN_MILE;

var debug = true;
const logg = function(s, o) { debug && o ? console.log(s, o) : console.log(s) }

let journey = [];
let positionData = [];

class TrackerPage extends Component {

	state = {
		tracking: false,
		location: null,
		totalDistance : 0,
		tickDistance: 0,
		tickColor: null,
		debugText: ''
	}

	trackerTimeout = null;

	startTracking = () => {
		logg("startTracking() ");
		if (this.trackerTimeout) {
			logg(" already tracking");
			return;
		}
		logg(" begin tracking");
		journey = [];
		positionData = [];
		this.setState({ tracking: true, totalDistance: 0, tickColor: 0 });
		this.locate();
	};

	stopTracking = () => {
		this.setState({ tracking: false });
		this.trackerTimeout = null;
	};

  locate = () => {
  	logg("locate() " + this.locating);
  	if(!navigator.geolocation) {
  		this.showError('Device and/or browser does not support geo location, or geo location is turned off.');
  		return;
  	}
  	if (this.locating) {
  		logg("  already locating");
  		return;
  	}
  	this.locating = true;
    navigator.geolocation.getCurrentPosition(
    	this.onLocation, this.onLocationError
    );
  };

	onLocation = (location) => {
		logg("onLocation( {...} )", location);
		this.locating = false;

		if (!this.state.tracking) {
			logg(" forget this last one, we stopped tracking");
			return;
		}
		
		this.setState({ location });

		journey.push(location);
		positionData.push([location.coords.longitude, location.coords.latitude]);
		this.calculateDistance();
		this.delayThenLocateAgain();
	};

	onLocationError = (error) => {
		logg("onLocationError( {...} )", error);
		this.showError('Problem getting location');
		this.locating = false;
		journey.push({ error, now: Date.now() });
		this.delayThenLocateAgain();
	};

	delayThenLocateAgain () {
		this.trackerTimeout = setTimeout(() => this.locate(), TRACK_TIME);
	}

	calculateDistance () {
		logg("calculateDistance()");
		if (positionData.length < 2) {
			this.setState({ totalDistance : -1 })
			return;
		}

		//console.dir(positionData);
		const kFilter = new KalmanFilter({observation: 2});
		const res = kFilter.filterAll(positionData);
		//console.dir(res);

		let lat1 = -1;
		let lon1 = -1;
		let lat2 = -1;
		let lon2 = -1;
		let dist = 0;
		let newTotalDistance = 0;

		for (let i = 0; i < res.length - 2; i++) {
			lat1 = res[i][0];
			lon1 = res[i][1];
			lat2 = res[i+1][0];
			lon2 = res[i+1][1];
			dist = thisPage.distance(lat1, lon1, lat2, lon2);
			newTotalDistance += dist;
			//console.log(dist);
		}

		console.log("newTotalDistance", newTotalDistance);
		
		this.setState({ totalDistance : newTotalDistance, tickDistance: dist });
	}

	render () {
		const buttonText = "Begin";

		const { totalDistance } = this.state;

		let debug = [(<br />),(<br />)];
		if (this.state.location) {
			const loc = this.state.location.coords;
			for (const prop in loc) {
			  debug.push(<span key={prop}> { prop } : { loc[prop] } </span>);
			  debug.push(<br />);
			}
		}

		return (
			<div
				id={thisPage.name}
				className='app-page'
			>
				Total miles: { totalDistance } miles
				<br />
				Total  feet: { totalDistance * FEED_IN_MILE } feet
				<br />
				<span style={{ color: this.state.tickColor }}>
					{ this.state.tickDistance } miles
					<br />
					{ this.state.tickDistance * FEED_IN_MILE } feet
				</span>

				<div
					id='tracker-error-display'
					className='bb-error'
				/>
				<div
					id='tracker-bottom-buttons'
					className='content'
				>
					<button
						key={'tracker-button-save'} 
						className="button long"
						onClick={ this.startTracking }
					>
						{ buttonText }
					</button>

					<button
						key={'tracker-button-stop'} 
						className="button long"
						onClick={ this.stopTracking }
					>
						Stop
					</button>
					{ debug }
				</div>
			</div>
		);
	}
}

var thisPage = new AppPage({
	name: 'tracker',
	component: (<TrackerPage />)
});

thisPage.initialize = function(data) {
	logg("initialize( {...} )", data);
	if (!data) data = {};
	return Promise.resolve();
}

thisPage.startTracking = function() {

}

thisPage.showError = function( s ) {
	let elm = util.$('tracker-error-display');
	if (!elm) return;
	if ( s === false) {
		util.rmClass(elm, 'display-block');
		elm.innerText = ""; return;
	}
	util.addClass(elm, 'display-block');
	elm.innerText = s;
}



thisPage.distance = function (lat1, lon1, lat2, lon2, unit) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
  }
  else {
    const radlat1 = Math.PI * lat1/180;
    const radlat2 = Math.PI * lat2/180;
    const theta = lon1-lon2;
    const radtheta = Math.PI * theta/180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist;
  }
}

thisPage.cleanUp = function() {
	// window.removeEventListener('postTextChanged', thisPage.changeCaption );
}


export default thisPage;
