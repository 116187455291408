import { Spacer, ProgressIndicatorCircle } from './Common'
import PictureService from '../PictureService'
import ProfileService from '../ProfileService'
import AwesomeButton from './AwesomeButton'
import AwesomeInput from './AwesomeInput'
import UserPortrait from './UserPortrait'
import React, { Component } from 'react'
import config from '../AppConfig'
import Profile from '../Profile'
import AppPage from '../AppPage'
import util from '../AppUtil'
import app from '../app'
import uu from '../uu'

import './ProfileEdit.css'
import './Avatar.css'

var debug = true;

class ProfilePictureEdit extends Component
{
	clicked() {
		this.hideError();
	}

	clickCamera() {
		//console.log("onClickCamera()");
		this.hideError();
		app.takePhoto( (data) => {
			PictureService.savePortrait( data.element )
			.then( (res) => {
				ProfileService.setMyPortrait( res.image );
				app.goPage(thisPage.name);
			})
			.catch( (error) => {
				console.log("problem saving that selfie");
				this.showError(error.message);
			})
		});
	}

	clickFile() {
		//console.log("clickFile()");
		util.$('pe-file-input').click();
		if (debug) util.dispatch("showProgressIndicator");
	}

	showError(err) {
		//console.warn("showError:", err);
		window.dispatchEvent(new Event("hideProgressIndicator"));
		var elm = util.$("portrait-error-display");
		if (!elm) return;
		elm.style.height = 'auto';
		elm.innerHTML = err.message ? err.message : err;
	}

	hideError() {
		var elm = util.$('portrait-error-display');
		elm.style.height = '0px';
		elm.innerHTML = null;
	}

	uploadFileFromInput(e) {
		//console.warn("uploadFileFromInput( {} )");
		this.hideError();
		util.dispatch('showProgressIndicator');
		const files = Array.from(e.target.files);
		const file = files[0];
		if (!file) {
			this.showError("No file selected");
			return;
		}
		PictureService.savePortrait(file)
		.then( (res) => {
			ProfileService.setMyPortrait( res.image );
			uu.wait(250)
			.then( () => {
				util.dispatch('hideProgressIndicator');
			})
		})
		.catch( (error) => {
			this.showError(error.message);
		})
	}

	render() {
		var imageStyle = {}
		if (this.props.image) {
			imageStyle = {backgroundImage: this.props.image}
		}
		var editPortraitButons = [
			<div
				key={'pe-pb-file'}
				className="edit-portriat-button"
				onClick={(e) => this.clickFile(e)}
			>
				<div className={'edit-portrait-button-icon '
									+ 'icon-image tint-ui'} />
			</div>
		];

		if (app.hasGetUserMedia() && !app.ios) {
			editPortraitButons.push(
				<div
					key={'pe-pb-camera'}
					className='edit-portriat-button'
					onClick={(e) => this.clickCamera(e)}
				>
					<div className={'edit-portrait-button-icon'
								+' icon-shutter tint-ui'} />
				</div>
			)
		}

		return (
			<div>
				<div
					id='pe-portrait-container'
					className='pe-portrait-container'
				>
					<UserPortrait addClass='profile-edit' isMe={true} />
					<ProgressIndicatorCircle />
				</div>
				<div
					id='edit-portrait-buttons-container'
					onClick={(e) => this.clicked(e)}
				>
					{editPortraitButons}
				</div>
				<div id="portrait-error-display"></div>
				<div style={{height:"0px", overflow:"hidden"}} >
					<input
						type="file"
						id="pe-file-input"
						onChange={(e) => this.uploadFileFromInput(e)}
					/>
				</div>
			</div>
		);
	}
}

class EditProfileField extends Component {

	constructor(props) {
		super(props);
		this.myInput = React.createRef();
		this.myDiv = React.createRef();
		this.myPrivacyButton = React.createRef();
		this.profileInfoField = this.props.profileInfoField;
	}

	keydown (e) {
		var keyCode = ('which' in e) ? e.which : e.keyCode;
		if (keyCode == 13) { // Return Key
			this.submit(e);
		}
	}

	setValueToLocalUserData() {
		if (this.profileInfoField.value) {
			this.myInput.current.value = this.profileInfoField.value;
		}
	}

	submit (e) {
		this.myInput.current = util.$(this.profileInfoField.dbk+'-input');
		if (this.waiting) {
			this.warn("no submit, waiting");
			return;
		}
		const newValue = this.myInput.current.value.trim();
		this.log("submit() "+this.profileInfoField.dbk+": "+newValue);

		if (this.profileInfoField.value === newValue) {
			return;
		}
		if (!this.profileInfoField.validate(newValue)) {
			console.warn('failed validation');
			this.setValueToLocalUserData();
			return;
		}

		this.waiting = true;
		this.profileInfoField.save( newValue )
		.then( (data) => {
			return ProfileService.updateMe(data)
			.then( (res) => {
				this.waiting = false;
				if (res.error)  this.onError(res);
			})
		})
		.catch( (err) => {
			this.onError(err);
		})
	}

	onError(err) {
		this.waiting = false;
		console.warn("onError( {...} )");
		console.warn(err);
		const detail = {
			message: 'Error Saving Profile '+this.profileInfoField.dbk,
			textYes: "Ok",
			textNo:'Not Ok',
			icon: 'mg'
		}
		const evt = new CustomEvent('showConfirmation', {detail});
		window.dispatchEvent( evt );
	}

	log(s) {
		if (debug) console.log(this.profileInfoField.dbk + ": " +s)
	}

	render() {
		this.log("render " + this.props.inputType);
		console.dir(this.profileInfoField);
		var placeHolder = "";
		const myVal = this.profileInfoField.value;
		if (myVal && myVal.length > 0) {
			placeHolder = myVal;
		}
		else {
			placeHolder = this.props.placeHolderText;
		}

		return (
			<div
				ref={this.myDiv}
				className="edit-user-field-container"
			>
				<div
					//ref={this.mySpan}
					className="error-string inside-input"
				></div>

				<AwesomeInput
					type={this.props.inputType}
					name={this.profileInfoField.dbk}
					text={this.profileInfoField.displayName}
					value={this.profileInfoField.value}
					// maxLength={this.props.maxChars}
					//changeAction={(e) => {this.changed(e)}}
					blurAction={(e) => {this.submit()}}
				/>
			</div>
		);
	}

	componentDidMount() {
		this.myInput.current = util.$(this.profileInfoField.dbk+'-input');
	}
}


class USAStateSelector extends EditProfileField
{
	changed (e) {
		console.log("USAStateSelector changed()");
		this.submit();
	}

	render() {
		return (
			<div
				ref={this.myDiv}
				className="edit-user-field-container"
			>
				<div className='text-input-container'>
					<select
						className='select-state '
						onChange={(e) => {this.changed(e)}}
						ref={this.myInput}
						id={this.profileInfoField.dbk+'-input'}
					>
						<option value="">State</option>
						<option value="AL">Alabama</option>
						<option value="AK">Alaska</option>
						<option value="AZ">Arizona</option>
						<option value="AR">Arkansas</option>
						<option value="CA">California</option>
						<option value="CO">Colorado</option>
						<option value="CT">Connecticut</option>
						<option value="DE">Delaware</option>
						<option value="DC">District Of Columbia</option>
						<option value="FL">Florida</option>
						<option value="GA">Georgia</option>
						<option value="HI">Hawaii</option>
						<option value="ID">Idaho</option>
						<option value="IL">Illinois</option>
						<option value="IN">Indiana</option>
						<option value="IA">Iowa</option>
						<option value="KS">Kansas</option>
						<option value="KY">Kentucky</option>
						<option value="LA">Louisiana</option>
						<option value="ME">Maine</option>
						<option value="MD">Maryland</option>
						<option value="MA">Massachusetts</option>
						<option value="MI">Michigan</option>
						<option value="MN">Minnesota</option>
						<option value="MS">Mississippi</option>
						<option value="MO">Missouri</option>
						<option value="MT">Montana</option>
						<option value="NE">Nebraska</option>
						<option value="NV">Nevada</option>
						<option value="NH">New Hampshire</option>
						<option value="NJ">New Jersey</option>
						<option value="NM">New Mexico</option>
						<option value="NY">New York</option>
						<option value="NC">North Carolina</option>
						<option value="ND">North Dakota</option>
						<option value="OH">Ohio</option>
						<option value="OK">Oklahoma</option>
						<option value="OR">Oregon</option>
						<option value="PA">Pennsylvania</option>
						<option value="RI">Rhode Island</option>
						<option value="SC">South Carolina</option>
						<option value="SD">South Dakota</option>
						<option value="TN">Tennessee</option>
						<option value="TX">Texas</option>
						<option value="UT">Utah</option>
						<option value="VT">Vermont</option>
						<option value="VA">Virginia</option>
						<option value="WA">Washington</option>
						<option value="WV">West Virginia</option>
						<option value="WI">Wisconsin</option>
						<option value="WY">Wyoming</option>
					</select>
				</div>
			</div>
		)
	}

	componentDidMount() {
		this.setSelectedToLocalData();
	}

	setSelectedToLocalData() {
		const options = this.myInput.current.options;
		for (var i = 0; i < options.length; i++) {
			var op = options[i];
			if ( op.value == this.profileInfoField.value ) {
				op.selected = true;
				return;
			}
		}
	}
}


class EditProfilePage extends React.Component
{
	clickDone(e) {
		app.loadMyProfilePage();
	}

	render() {
		return (
			<div
				id={thisPage.name}
				className="app-page"
			>

				<ProfilePictureEdit
					image={ProfileService.me.portrait.urlCss}
					profile={ProfileService.me}
					clickToPick={true}
				/>
				
				<div
					className="content-container form-container"
				>

					<EditProfileField
						inputType="text"
						placeHolderText="First Name"
						maxChars={config.names.first.maxLength}
						profileInfoField={ProfileService.me.firstName}
					/>

					<EditProfileField
						inputType="text"
						placeHolderText="Last Name"
						maxChars={config.names.last.maxLength}
						profileInfoField={ProfileService.me.lastName}
					/>

					<EditProfileField
						inputType="text"
						placeHolderText="City"
						maxChars={config.city.maxLength}
						profileInfoField={ProfileService.me.city}
					/>
				
					<USAStateSelector
						fieldKey="state"
						placeHolderText="State"
						profileInfoField={ProfileService.me.state}
					/>

					<EditProfileField
						inputType="text"
						placeHolderText="About You"
						maxChars={config.about.maxLength}
						profileInfoField={ProfileService.me.aboutText}
					/>

					<button
						onClick={(e) => this.clickDone(e)}
						type="button"
						className="button long"
						id="done"
					>
						OK
					</button>
			
				</div>

				<Spacer height={100} />
			</div>
		);
	}
}

var thisPage = new AppPage({
	name: "profileEdit",
	component: (<EditProfilePage />)
});



export default thisPage;


